import * as React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { areaContext } from '../../data/areaContext';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  // padding: theme.spacing(1),
  padding: '2px',
  fontSize: 3,
}));

function setBackgroundColor(direction: string) {
  if(direction === '出店厳格化') return '#FF0000';
  if(direction === '新規＋活性化') return '#FFFF00';
  return '#00B0F0'
}

function setFontColor(direction: string) {
  if(direction === '出店厳格化') return '#FFFFFF';
  if(direction === '新規＋活性化') return '#000000';
  return '#000000'
}

export default function AreaTable() {
  const areaCtx = React.useContext(areaContext);

  return (
    <Paper sx={{width: '100%',overflow: 'hidden'}}>
      <TableContainer sx={{minHeight:200,maxHeight:200}}>
        <Table stickyHeader aria-label="AreaTable">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{paddingY:0, fontSize: "10px"}}>番号</TableCell>
              <TableCell align="center" sx={{paddingY:0, fontSize: "10px", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>エリア名</TableCell>
              <TableCell align="center" sx={{paddingY:0, fontSize: "10px", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>方向性</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areaCtx.smallAreaList.map((row,idx) => (
              <TableRow
                key={row.code}
              >
                <TableCell align="center" component="th" scope="row" sx={{fontSize: "10px"}}>
                  {idx + 1}
                </TableCell>
                <TableCell align="center" sx={{fontSize: "10px", borderLeft: "1px solid rgba(224, 224, 224, 1)"}}>{row.name}</TableCell>
                <TableCell align="center" sx={{fontSize: "10px", backgroundColor:`${setBackgroundColor(row.attribution.direction_name)}`,
                color:`${setFontColor(row.attribution.direction_name)}`,
                borderLeft: "1px solid rgba(224, 224, 224, 1)"
                }}>{row.attribution.direction_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}