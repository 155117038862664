import { FullMetadata, getMetadata, listAll, ref } from 'firebase/storage';
import { storage } from './firebase';

/**
 * 
 * @param bigAreaUuid 
 * @param smallAreaUuid 
 * @returns 
 */
export const getPdfList = async (bigAreaUuid: string,smallAreaUuid?: string | undefined) => {
  const path = smallAreaUuid ? 'PDF/' + bigAreaUuid + '/' + smallAreaUuid : 'PDF/' + bigAreaUuid;
  const listRef = ref(storage,path);

  const response = await listAll(listRef)
  const pdfList: FullMetadata[] = []; 
  for(let i = 0;i < response.items.length; i++){
    const metaData = await getMetadata(response.items[i]);
    pdfList.push(metaData);
  }
  return pdfList;
}

/**
 * マニュアルダウンロード
 * @returns 
 */
export const getManual = async () => {
  const path = 'manual/';
  const listRef = ref(storage,path);

  const response = await listAll(listRef)
  const manualList: FullMetadata[] = []; 
  for(let i = 0;i < response.items.length; i++){
    const metaData = await getMetadata(response.items[i]);
    manualList.push(metaData);
  }
  return manualList;
}
