import React from "react";

import DownloadIcon from '@mui/icons-material/Download';
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import { getDownloadURL, ref } from 'firebase/storage';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { storage } from '../../utility/firebase';
import { getPdfList } from '../../utility/storage';
import { graphqlRequest } from "../api/apiCall";
import { areaContext } from './areaContext';
import { AreaType } from './areaInterface';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  minWidth: 860,
  maxWidth: 960,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface pdfDownLoadModalProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    mode:string,
  }
}

const PdfDownLoadModal:React.FC<pdfDownLoadModalProps> = ({props}) => {
  const areaCtx = React.useContext(areaContext);
  const utilityCtx = useUtilityContext();
  const user = useUserContext();

  const [pdfOpen, setPdfOpen] = React.useState(props.open);
  const [count, setCount] = React.useState(0);
  const [paused, setPaused] = React.useState(false);

  const handleClose = () => {
    setInitCheck(true);
    setBigAreaCode("")
    setSmallAreaCode("")
    setDownloadList([]);
    setIsArea("");
    setBigAreaList([]);
    setSmallAreaList([]);
    setPdfOpen(false)
    props.handleModal(false)
    setPaused(false)
  };

  const [initCheck, setInitCheck] = React.useState<boolean>(true)
  const [bigAreaCode, setBigAreaCode] = React.useState<string>("")
  const [smallAreaCode, setSmallAreaCode] = React.useState<string>("")
  const [downloadList, setDownloadList] = React.useState<any[]>([])
  const [downloadUrl, setDownloadUrl] = React.useState<string>("")
  const [isArea, setIsArea] = React.useState<string>("")
  const [bigAreaList, setBigAreaList] = React.useState<AreaType[]>([])
  const [smallAreaList, setSmallAreaList] = React.useState<AreaType[]>([])

  const selectPDF = () => {
    let bigAreaRegistCode = ""
    let smallAreaRegistCode = ""
    setDownloadList([])
    if (bigAreaCode !== '') {
      bigAreaList?.forEach((item, key) => {
        if (String(key) === bigAreaCode) {
          bigAreaRegistCode = item.code
        }
      })
      smallAreaList?.forEach((item, key) => {
        if (String(key) === smallAreaCode) {
          smallAreaRegistCode = item.code
        }
      })
      if (isArea === "0") {
        smallAreaRegistCode = ""
      }
      (async () => {
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
        }
        try {
          const result = await getPdfList(bigAreaRegistCode, smallAreaRegistCode)
          result.reverse();
          setDownloadList(result)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e)
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        }
      })();
    }
  };

  const checkSelectPDF = () => {
    let bigAreaRegistCode = ""
    let smallAreaRegistCode = ""
    if (bigAreaCode !== '') {
      bigAreaList?.forEach((item, key) => {
        if (String(key) === bigAreaCode) {
          bigAreaRegistCode = item.code
        }
      })
      smallAreaList?.forEach((item, key) => {
        if (String(key) === smallAreaCode) {
          smallAreaRegistCode = item.code
        }
      })
      if (isArea === "0") {
        smallAreaRegistCode = ""
      }
      (async () => {
        try {
          const result = await getPdfList(bigAreaRegistCode, smallAreaRegistCode)
          if (result.length > 0) {
            if (downloadList.length !== result.length) {
              result.reverse();
              setDownloadList([])
              setDownloadList(result)
            }
          }
        } catch (e) {
          console.log(e)
        }
      })();
    }
  };

  const requestBigArea = () => {
    let params: any = {
      mode: "getBigAreaData",
      doCode: areaCtx.doArea.code,
      uuid: "",
    };
    (async () => {
      try {
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
        }
        const result: any = await graphqlRequest(params);
        if (result.data.data !== null) {
          setBigAreaList(result.data.data.getBigAreaData)
        }
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const requestSmallArea = () => {
    let bigAreaRegistCode = ""
    bigAreaList?.forEach((item, key) => {
      if (String(key) === bigAreaCode) {
        bigAreaRegistCode = item.code
      }
    })
    let params: any = {
      mode: "getSmallAreaData",
      big_area_uuid: bigAreaRegistCode,
      uuid: "",
    };
  
    (async () => {
      try {
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
        }
        const result: any = await graphqlRequest(params);
        if (result.data.data !== null) {
          setSmallAreaList(result.data.data.getSmallAreaData)
        }
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const changeBigArea = (event: any) => {
    setCount(0)
    bigAreaList?.forEach((item, key) => {
      if (String(key) === String(event.target.value)) {
        setBigAreaCode(event.target.value)
        setSmallAreaCode("")
      }
    })
    setIsArea("0");
  };

  const changeSmallArea = (event: any) => {
    setCount(0)
    setSmallAreaCode(event.target.value)
    setIsArea("1");
  };

  React.useEffect(() => {
    setSmallAreaCode("")
    requestSmallArea()
    if (!initCheck) {
      selectPDF();
    }
  }, [bigAreaCode])
  
  React.useEffect(() => {
    if (pdfOpen) {
      selectPDF();
    }
    if (initCheck && bigAreaCode) {
      setInitCheck(false);
    }
  }, [smallAreaCode])

  const pdfDownload = (event: any, data: any) => {
    const storageRef = ref(storage, data.fullPath);

    getDownloadURL(storageRef).then(function(url) {
      setDownloadUrl(url)
    })
  }

  /**
   * yyyy/mm/dd H:i:s形式に変換
   * @param date 
   * @returns 
   */
  const setDate = (date: string) => {
    const yyyymmdd = new Intl.DateTimeFormat(
      undefined,
      {
        year:   'numeric',
        month:  '2-digit',
        day:    '2-digit',
        hour:   '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }
    )
    const displayDate = yyyymmdd.format(new Date(date))

    return displayDate
  }

  /**
   * PDFを別ウインドウに表示
   */
  React.useEffect(() => {
    if (downloadUrl !== '') {
      // userLogging
      userLogging('PDFダウンロード', {}, user);
      window.opener = null
      window.open(downloadUrl, '_blank')
      setDownloadUrl("")
    }
  }, [downloadUrl])

  const handleToggleClick:any = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsArea(event.target.value);
  }

  React.useEffect(() => {
    if (!initCheck) {
      selectPDF();
    }
  }, [isArea])

  React.useEffect(() =>{
    if(props.open){
      setPdfOpen(props.open);
      requestBigArea()
      setCount(0)
      setPaused(true);
    }
  },[props.open])

  React.useEffect(() =>{
    if (initCheck && bigAreaList.length > 0) {
      bigAreaList.forEach((area, index) => {
        if (area.code === areaCtx.bigArea.code) {
          setBigAreaCode(String(index))
        }
      });  
    }
    requestSmallArea()
  },[bigAreaList])

  React.useEffect(() =>{
    // 初期処理
    if (initCheck && smallAreaList.length > 0) {
      if (areaCtx.initialCheck) {
        smallAreaList.forEach((area, index) => {
          if (area.code === areaCtx.smallArea.code) {
            setSmallAreaCode(String(index));
          }
        });
      }
      if (!areaCtx.initialCheck) {
        setIsArea("0");
        selectPDF();
      } else {
        setIsArea("1");
      }  
    }
  },[smallAreaList])

  React.useEffect(() => {
    if (!paused) {
      return;
    }
    const interval = setInterval(() => {
      setCount(c => c + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [paused]);

  React.useEffect(() => {
    if (count % 5 === 0 && pdfOpen && paused) {
      checkSelectPDF()
    }
  }, [count]);

    return (
      <div>
        <Modal
          open={pdfOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  ＰＤＦダウンロード
                </Typography>
              </Grid>
              <Grid xs={6}>
                <FormControl fullWidth>
                    <Select
                      sx={{}}
                      labelId="big-area-regist-direction-select"
                      id="big-area-list"
                      onChange={changeBigArea}
                      value={bigAreaCode}
                    >
                      {
                        Object.entries(bigAreaList).map(([key,item]) => (
                          <MenuItem value={String(key)} key={String(key)}>{item.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl fullWidth>
                  <Select
                    sx={{}}
                    labelId="big-area-regist-direction-select"
                    id="small-area-list"
                    onChange={changeSmallArea}
                    value={smallAreaCode}
                  >
                    {
                      Object.entries(smallAreaList).map(([key,item]) => (
                        <MenuItem value={String(key)} key={String(key)}>{item.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl>
                  <RadioGroup
                    row
                    value={isArea}
                    onChange={handleToggleClick}
                  >
                    <FormControlLabel value="0" disabled={bigAreaList.length > 0 ? false : true} control={<Radio />} label="行政区" />
                    <FormControlLabel value="1" disabled={smallAreaList.length > 0 ? false : true} control={<Radio />} label="エリア" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid xs={12}>
                <Box
                    sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper', overflowY: 'auto' }}
                  >
                  <List
                    sx={{
                    height: '100%',
                    width: '100%',
                    }}
                  >
                  {
                    Object.entries(downloadList).map(([key,item]) => (
                      <ListItem sx={{padding: 0, overflowY: 'auto' }} key={key}>
                        <ListItemButton component="a"
                        onClick={(event) => pdfDownload(event, item)}
                        sx={{paddingLeft: 0, paddingRight: 0}}
                        >
                          <ListItemText 
                          sx={{'.css-10hburv-MuiTypography-root': {fontSize: '14px'}}}
                          primary={item.name + '（' + setDate(item.timeCreated) + '）'} 
                          />
                          <ListItemIcon><DownloadIcon /></ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))
                  }
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  
}

export default PdfDownLoadModal;


