import * as React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { areaContext } from '../../data/areaContext';

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: '2px',
  fontSize: '0.25em',
}));

const TableCellPopulation = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  fontSize: 1,
  backgroundColor: '#b1c5de',
}));

export default function MajorFacility() {
  const areaCtx = React.useContext(areaContext);

  return (
    <Paper sx={{width: '100%',overflow: 'hidden'}}>
      <TableContainer sx={{minHeight:200,maxHeight:200}}>
        <Table stickyHeader aria-label="MajorFacilityTable">
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={3} sx={{paddingY:0,whiteSpace:'nowrap',fontSize: "10px"}}>主要施設（駅・病院・学校等）</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areaCtx.majorFacilityList.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row" sx={{fontSize: "10px"}}>
                  {row.name}
                </TableCell>
                <TableCellPopulation align="right" sx={{fontSize: "10px"}}>{Number(row.attribution.value).toLocaleString()}</TableCellPopulation>
                <TableCell align="left" sx={{fontSize: "10px"}}>{row.attribution.unit}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}