// 競合店舗のコード管理
interface cvsBrandCodeMasterType {
  [key: number]: cvsBrandCodeType; // <-この行を追加!
}

interface cvsBrandCodeType {
  blandName:      string;
  blandJapaneseName:     string;
  iconPathBase:     string;
}

const cvsBrandCodeMaster = {
  2: {
    blandName: "familymart",
    blandJapaneseName: "ファミリーマート",
    iconPathBase: "/image/icons/conflict_familymart",
  },
  3: {
    blandName: "lawson",
    blandJapaneseName: "ローソン",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  4: {
    blandName: "lawson100",
    blandJapaneseName: "ローソンストア100",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  5: {
    blandName: "familymartExclamation",
    blandJapaneseName: "ファミマ！！",
    iconPathBase: "/image/icons/conflict_familymart",
  },
  6: {
    blandName: "CircleK",
    blandJapaneseName: "サークルK",
    iconPathBase: "/image/icons/conflict",
  },
  7: {
    blandName: "sunkus",
    blandJapaneseName: "サンクス",
    iconPathBase: "/image/icons/conflict",
  },
  9: {
    blandName: "ministop",
    blandJapaneseName: "ミニストップ",
    iconPathBase: "/image/icons/conflict_ministop",
  },
  10: {
    blandName: "saveOn",
    blandJapaneseName: "セーブオン",
    iconPathBase: "/image/icons/conflict",
  },
  11: {
    blandName: "seicomart",
    blandJapaneseName: "セイコーマート",
    iconPathBase: "/image/icons/conflict_seicomart",
  },
  13: {
    blandName: "poplar",
    blandJapaneseName: "ポプラ",
    iconPathBase: "/image/icons/conflict_poplar",
  },
  14: {
    blandName: "c-store",
    blandJapaneseName: "コミュニティ・ストア",
    iconPathBase: "/image/icons/conflict_c-store",
  },
  15: {
    blandName: "dailyYamazaki",
    blandJapaneseName: "デイリーヤマザキ",
    iconPathBase: "/image/icons/conflict_dailyYamazaki",
  },
  16: {
    blandName: "threeF",
    blandJapaneseName: "スリーエフ",
    iconPathBase: "/image/icons/conflict",
  },
  18: {
    blandName: "piago",
    blandJapaneseName: "ｍｉｎｉピアゴ",
    iconPathBase: "/image/icons/conflict_piago",
  },
  20: {
    blandName: "poplar-cvs",
    blandJapaneseName: "生活彩家",
    iconPathBase: "/image/icons/conflict_poplarcvs",
  },
  120: {
    blandName: "naturallawson",
    blandJapaneseName: "ナチュラルローソン",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  1002: {
    blandName: "familymart",
    blandJapaneseName: "ファミリーマート",
    iconPathBase: "/image/icons/conflict_familymart",
  },
  1003: {
    blandName: "lawson",
    blandJapaneseName: "ローソン",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  1004: {
    blandName: "lawson100",
    blandJapaneseName: "ローソンストア100",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  1005: {
    blandName: "familymartExclamation",
    blandJapaneseName: "ファミマ！！",
    iconPathBase: "/image/icons/conflict_familymart",
  },
  1006: {
    blandName: "CircleK",
    blandJapaneseName: "サークルK",
    iconPathBase: "/image/icons/conflict",
  },
  1007: {
    blandName: "sunkus",
    blandJapaneseName: "サンクス",
    iconPathBase: "/image/icons/conflict",
  },
  1008: {
    blandName: "am-pm",
    blandJapaneseName: "am / pm",
    iconPathBase: "/image/icons/conflict",
  },
  1009: {
    blandName: "ministop",
    blandJapaneseName: "ミニストップ",
    iconPathBase: "/image/icons/conflict_ministop",
  },
  1010: {
    blandName: "saveOn",
    blandJapaneseName: "セーブオン",
    iconPathBase: "/image/icons/conflict",
  },
  1011: {
    blandName: "seicomart",
    blandJapaneseName: "セイコーマート",
    iconPathBase: "/image/icons/conflict_seicomart",
  },
  1012: {
    blandName: "cocostore",
    blandJapaneseName: "ココストア",
    iconPathBase: "/image/icons/conflict",
  },
  1013: {
    blandName: "poplar",
    blandJapaneseName: "ポプラ",
    iconPathBase: "/image/icons/conflict_poplar",
  },
  1014: {
    blandName: "c-store",
    blandJapaneseName: "コミュニティ・ストア",
    iconPathBase: "/image/icons/conflict_c-store",
  },
  1015: {
    blandName: "dailyYamazaki",
    blandJapaneseName: "デイリーヤマザキ",
    iconPathBase: "/image/icons/conflict_dailyYamazaki",
  },
  1016: {
    blandName: "threeF",
    blandJapaneseName: "スリーエフ",
    iconPathBase: "/image/icons/conflict",
  },
  1017: {
    blandName: "everyone",
    blandJapaneseName: "エブリワン",
    iconPathBase: "/image/icons/conflict",
  },
  1018: {
    blandName: "piago",
    blandJapaneseName: "ｍｉｎｉピアゴ",
    iconPathBase: "/image/icons/conflict_piago",
  },
  1020: {
    blandName: "poplar-cvs",
    blandJapaneseName: "生活彩家",
    iconPathBase: "/image/icons/conflict_poplarcvs",
  },
  1120: {
    blandName: "naturallawson",
    blandJapaneseName: "ナチュラルローソン",
    iconPathBase: "/image/icons/conflict_lawson",
  },
  0: {
    blandName: "",
    blandJapaneseName: "",
    iconPathBase: "/image/icons/conflict",
  },
} as cvsBrandCodeMasterType;

// 競合店舗のコードの値を取得する
export const getCvsBrandCodeValue = (blandId: number) => {
  return blandId in cvsBrandCodeMaster
    ? cvsBrandCodeMaster[blandId]
    : cvsBrandCodeMaster[0];
};