import { initializeApp } from 'firebase/app';
import { doc, getFirestore, serverTimestamp, setDoc } from 'firebase/firestore/lite';
import { v4 as uuidv4 } from 'uuid';
import { db } from './firebase';
import { getBrowser, getDeviceAngle, getUnixTime, setDeviceType } from './util';

export const userLogging = (functionName: String, content:  Object, user: any) => {
  const jsonUserData = JSON.parse(user.userData);
  const screen = `縦:${window.innerHeight}px 横:${window.innerWidth}px`;
  const data: any = {
    access_time_unixtime: getUnixTime(),
    application_name: 'strategy',
    user_name: jsonUserData.email,
    function_name: functionName,
    content: content,
    device: setDeviceType(),
    screen,
    browser: getBrowser(),
    update_date: {
      update_date: serverTimestamp(),
      update_user: jsonUserData.email,
    },
  }

  writeFirestoreDoc("users_log", uuidv4(), data);
};

/**
 * firestoreに書き込みを行う
 */
const writeFirestoreDoc = (collectionName: string, docId: string, data: any) => {
  try {
    setDoc(doc(db, collectionName, docId), data);
  } catch (error) {
    console.log(error);
  }
}
