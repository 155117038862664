import * as React from "react";

import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RectangleTwoToneIcon from '@mui/icons-material/RectangleTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useUserContext } from '../../user-provider';
import { userLogging } from '../../utility/firestore';
import { areaContext } from '../data/areaContext';

interface OptionsProps {
  props:{
    open: boolean
  }
}

export const Options:React.FC<OptionsProps> = ({props}) => {
  const areaCtx = React.useContext(areaContext);
  const user = useUserContext();

  const hasExistingSej = () => {
    if (areaCtx.hasExistingSej === true) {
      // userLogging
      userLogging('既存店 OFF', {}, user);
    }
    areaCtx.setHasExistingSej(!areaCtx.hasExistingSej)
  }

  const hasClosedSej = () => {
    if (areaCtx.hasClosedSej === true) {
      // userLogging
      userLogging('閉店 OFF', {}, user);
    }
    areaCtx.setHasClosedSej(!areaCtx.hasClosedSej)
  }

  const hasZone = () => {
    if (areaCtx.hasZone === true) {
      // userLogging
      userLogging('ZONE OFF', {}, user);
    }
    areaCtx.setHasZone(!areaCtx.hasZone)
  }

  const hasPlanArea = () => {
    if (areaCtx.hasPlanArea === true) {
      // userLogging
      userLogging('候補地 OFF', {}, user);
    }
    areaCtx.setHasPlanArea(!areaCtx.hasPlanArea)
  }

  const hasConflictStores = () => {
    if (areaCtx.hasConflictStores === true) {
      // userLogging
      userLogging('競合店 OFF', {}, user);
    }
    areaCtx.setHasConflictStores(!areaCtx.hasConflictStores)
  }

  const [existingSejDisabled, setExistingSejDisabled] = React.useState(false);
  const [closedSejDisabled, setClosedSejDisabled] = React.useState(false);
  const [zoneDisabled, setZoneDisabled] = React.useState(false);
  const [planAreaDisabled, setPlanAreaDisabled] = React.useState(false);
  const [conflictStoresDisabled, setConflictStoresDisabled] = React.useState(false);

  React.useEffect(() => {
    setExistingSejDisabled(true)
    setClosedSejDisabled(true)
    setZoneDisabled(true)
    setPlanAreaDisabled(true)
    setConflictStoresDisabled(true)
    if (areaCtx.mapZoom >= 12) {
      setExistingSejDisabled(false)
      setClosedSejDisabled(false)
      setZoneDisabled(false)
      setPlanAreaDisabled(false)
      setConflictStoresDisabled(false)
    }
  }, [areaCtx.mapZoom])

  const onStyle = {
    background: "#00a54f",
    padding: 0,
    margin: 0,
    color: "#FFFFFF",
    paddingLeft: '14px',
    "&:hover": {
      background: "#00a54f",
    },
  };

  const offStyle = {
    background: "#FFFFFF",
    padding: 0,
    margin: 0,
    paddingLeft: '14px',
    "&:hover": {
      background: "#FFFFFF",
    },
  };

  const onTextStyle = {
    background: "#00a54f",
    margin: 0,
    color: "#FFFFFF",
    display: props.open === true ? "none": "block",
    '& .MuiTypography-root': {
      fontSize: "10px",
      marginTop: 0,
      textAlign: "center",
    },
    "&:hover": {
      background: "#00a54f",
    },
  };

  const offTextStyle = {
    background: "#FFFFFF",
    margin: 0,
    display: props.open === true ? "none": "block",
    '& .MuiTypography-root': {
      fontSize: "10px",
      marginTop: 0,
      textAlign: "center",
    },
    "&:hover": {
      background: "#FFFFFF",
    },
  };

  return (
    <div>
        <ListItemButton
          disabled={existingSejDisabled}
          onClick={(event) => hasExistingSej()}
          sx={areaCtx.hasExistingSej ? onStyle : offStyle}
        >
          <ListItemIcon>
            <StoreOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="既存店" />
        </ListItemButton>
        <ListItemText sx={areaCtx.hasExistingSej ? onTextStyle : offTextStyle} 
          onClick={(event) => hasExistingSej()} primary="既存店" />
        <ListItemButton
            disabled={closedSejDisabled}
            onClick={(event) => hasClosedSej()}
            sx={areaCtx.hasClosedSej ? onStyle : offStyle}
          >
            <ListItemIcon>
              <StoreTwoToneIcon />
            </ListItemIcon>
            <ListItemText primary="閉店" />
          </ListItemButton>
        <ListItemText sx={areaCtx.hasClosedSej ? onTextStyle : offTextStyle}
          onClick={(event) => hasClosedSej()} primary="閉店" />
        <ListItemButton
          disabled={zoneDisabled}
          onClick={(event) => hasZone()}
          sx={areaCtx.hasZone ? onStyle : offStyle}
        >
          <ListItemIcon>
            <RectangleTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="ZONE" />
        </ListItemButton>
        <ListItemText sx={areaCtx.hasZone ? onTextStyle : offTextStyle}
          onClick={(event) => hasZone()} primary="ZONE" />
        <ListItemButton
          disabled={planAreaDisabled}
          onClick={(event) => hasPlanArea()}
          sx={areaCtx.hasPlanArea ? onStyle : offStyle}
        >
          <ListItemIcon>
            <RadioButtonCheckedOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="候補地" />
        </ListItemButton>
        <ListItemText sx={areaCtx.hasPlanArea ? onTextStyle : offTextStyle}
          onClick={(event) => hasPlanArea()} primary="候補地" />
        <ListItemButton
          disabled={conflictStoresDisabled}
          onClick={(event) => hasConflictStores()}
          sx={areaCtx.hasConflictStores ? onStyle : offStyle}
        >
          <ListItemIcon>
            <StorefrontTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="競合店" />
        </ListItemButton>
        <ListItemText sx={areaCtx.hasConflictStores ? onTextStyle : offTextStyle}
          onClick={(event) => hasConflictStores()} primary="競合店" />
    </div>
  )
}