import React from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface resultDialogProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    onClose:any,
    body:string,
    resultButton:string,
  }
}
const style = {
  'justify-content': 'center',
};

const ResultDialog:React.FC<resultDialogProps> = ({props}) => {
  const [open, setOpen] = React.useState(false);
  const [bodyText, setBodyText] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    props.onClose()
  };

  React.useEffect(() =>{
    if(props.open){
      setOpen(props.open);
      setBodyText(props.body)
    }
  },[props.open])

  const MultiLineBody = (body: string) => {
    const breakedText = props.body.split("\n").map((line, key) => <span key={key}>{line}<br /></span>);
    return breakedText;
  };

  return (
    <div>
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{MultiLineBody(bodyText)}</DialogContentText>
      </DialogContent>
      <DialogActions sx={style}>
        <Button 
          variant="contained" 
          color="success"
          size="small"
          onClick={handleClose}>{props.resultButton}</Button>
      </DialogActions>
    </Dialog>
    </div>
  );
}

export default ResultDialog;