import * as React from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Done from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { apiRequest } from "../api/apiCall";
import { areaContext } from '../data/areaContext';
import { getMajorFacilityGraphQl } from '../data/areaDataManage';
import ConfirmDialog from '../data/ConfirmDialog';
import MajorFacilityUpdateModal from '../data/MajorFacilityUpdateModal';

interface MajorFacilityProps {
  handleMapActionChange?: any,
  mapAction?: boolean,
}

export const MajorFacility: React.FC<MajorFacilityProps> = ({
  handleMapActionChange,
  mapAction,
}) => {

  const areaCtx = React.useContext(areaContext);
  const user = useUserContext();
  const [majorFacility, setMajorFacility] = React.useState<google.maps.Marker[]>();
  const [infowindow, setInfowindow] = React.useState<any[]>([]);
  const [markerList, setMarkerList] = React.useState<any[]>([]);
  const utilityCtx = useUtilityContext();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

  function setContentStr(data: any) {
    const html = '<div id=infoWindow-'+data.code+'>' +
    '<p style="margin: 0"><label>名称：' + data.name + '</label></p>' +
    '<p style="margin: 0"><label>利用者：' + data.attribution.value + '' + data.attribution.unit + '</label></p>' +
    '<p><Button id="move-' + data.code + '">位置移動</Button></p>' + 
    '<p><Button id="update-' + data.code + '">編集</Button></p>' + 
    '<p><Button id="delete-' + data.code + '">削除</Button></p>' + 
    '</div>';
    return html;
  }

  function resetInfowindow(infowindow: any) {
    infowindow.forEach((info: any) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        info[key]["info"].close();
      })
    })
  }

  const hasEdit = () => {
    let hasEdit:boolean = false;
    if (mapAction) {
      hasEdit = true;
    } else {
      markerList.forEach((item) => {
        if (item.isEdit) {
          hasEdit = true;
        }
      })  
    }
    return hasEdit;
  }

  React.useEffect(() => {
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        info[key]["info"].close();
      })
    })
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        infowindow.splice(index, 1);
      })
    })
  }, [mapAction, areaCtx.bigArea]);

  React.useEffect(() => {
    // init
    majorFacility?.forEach((marker) => {
      marker.setMap(null);
    })

    if (areaCtx.majorFacilityList && areaCtx.majorFacilityList.length !== 0) {
      const tmpMarker:google.maps.Marker[] = [];
      const tmpMarkerList:any[] = [];
      areaCtx.majorFacilityList.forEach((majorFacility, index) => {
        var marker = new google.maps.Marker({
          position: majorFacility.latlng,
        });
        marker.setMap(areaCtx.googleMap!);
        tmpMarker.push(marker);
        tmpMarkerList.push({
          "code": majorFacility.code,
          "marker": marker,
          "majorFacility": majorFacility,
          "info": new google.maps.InfoWindow({}),
          "visibility": 'hidden',
          "isEdit": false,
        })
        marker.addListener("click", function (e: google.maps.MapMouseEvent) {
          onClick(e, majorFacility);
        });
      })
      setMajorFacility(tmpMarker);
      setMarkerList(tmpMarkerList);
    }
  }, [areaCtx.majorFacilityList]);

  
const onClick = (e: google.maps.MapMouseEvent, majorFacility: any) => {
    if (hasEdit()) {
      if (utilityCtx.showSnackbar) {
        utilityCtx.showSnackbar('warning','編集中です')
      }
      return
    }

    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        areaCtx.setMajorFacility(majorFacility)
        areaCtx.setTargetLatlng(majorFacility.latlng)
        const contentStr = setContentStr(majorFacility);
        var editLayer = new google.maps.Data({
          map: areaCtx.googleMap!, 
          style: {
            visible: false,
          }
        });
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -50)
        });
        info.open(editLayer.getMap());
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[majorFacility.code]: {
          "info" : info,
        }});
  
        info.addListener('domready', () => {
          onClickInfo(majorFacility);
        });
      }
    })
  }

  const onClickInfo = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        document.getElementById('infoWindow-' + majorFacility.code)?.addEventListener('click', () => {
          // zindex調整
          onInfoWindow(majorFacility)
        });
        document.getElementById('update-' + majorFacility.code)?.addEventListener('click', () => {
          onUpdate(majorFacility)
        });
        document.getElementById('delete-' + majorFacility.code)?.addEventListener('click', () => {
          onDelete(majorFacility)
        });
        document.getElementById('move-' + majorFacility.code)?.addEventListener('click', () => {
          // userLogging
          userLogging('主要施設位置移動 START', {}, user);
          onMoveStart(majorFacility)
        });
        document.getElementById('moveCancel-' + majorFacility.code)?.addEventListener('click', () => {
          onMoveCancel(majorFacility)
        });
        document.getElementById('moveUpdate-' + majorFacility.code)?.addEventListener('click', () => {
          onMoveUpdate(majorFacility)
        });
      }
    });
  }

  const onInfoWindow = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  const onUpdate = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        resetInfowindow(infowindow);
        areaCtx.setMajorFacility(majorFacility);
        // userLogging
        userLogging('主要施設編集 モーダルOPEN', {}, user);
        setModalOpen(true);
      }
    })
  }

  const onDelete = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        item.info.close();
        resetInfowindow(infowindow);
        // userLogging
        userLogging('主要施設削除 モーダルOPEN', {}, user);
        setConfirmDialogOpen(true)
      }
    })
  }

  const onDeleteCancel = () => {
    setConfirmDialogOpen(false)
  }

  const onDeleteExecution = () => {
    setConfirmDialogOpen(false)
    if (areaCtx.majorFacility) {
      
      const params = {
        mode: "smallarea",
        endPoint: "/strategy/majorfacility/v1/regist",
        query: {
          uuid: areaCtx.majorFacility.code,
          centerlat: areaCtx.majorFacility.latlng.lat.toString(),
          centerlon: areaCtx.majorFacility.latlng.lng.toString(),
          big_area_uuid: areaCtx.majorFacility.parent_code,
          major_facility_name: areaCtx.majorFacility.name,
          value: areaCtx.majorFacility.attribution.value,
          unit: areaCtx.majorFacility.attribution.unit,
          delete_flag: '1',
          comment: !areaCtx.majorFacility.attribution.comment 
          ? "" : areaCtx.majorFacility.attribution.comment
        }
      };
      // userLogging
      userLogging('主要施設削除 完了', params, user);
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      (async () => {
        try {
          await apiRequest(params);
          // 登録後主要施設取得
          getMajorFacilityGraphQl(areaCtx, areaCtx.bigArea, "");
  
          markerList.forEach((target, index) => {
            var keys = Object.keys(target);
            keys.forEach((key) => {
              if (key === areaCtx.majorFacility.code) {
                markerList.splice(index, 1);
              }
            })
          });
          infowindow.forEach((target, index) => {
            var keys = Object.keys(target);
            keys.forEach((key) => {
              if (key === areaCtx.majorFacility.code) {
                infowindow.splice(index, 1);
              }
            })
          });
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }

  const onMoveStart = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        resetInfowindow(infowindow);
        item.visibility = "visible";
        item.isEdit = true;
        handleMapActionChange(true);
        item.info.close();
        item.marker.setDraggable(true);
        item.marker.setOptions({
          opacity: 0.5,
        });
      }
    })
  }

  const onMoveCancel = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        handleMapActionChange(false);
        item.visibility = "hidden";
        item.isEdit = false;
        item.marker.setDraggable(false);
        item.marker.setPosition({lat: majorFacility.latlng.lat, lng: majorFacility.latlng.lng});
        item.marker.setOptions({
          opacity: 1,
        });
      }
    })
  }

  const onMoveUpdate = (majorFacility: any) => {
    markerList.forEach((item) => {
      if (item.code === majorFacility.code) {
        
        resetInfowindow(infowindow);
        const latlng = item.marker.getPosition();
        const params = {
          mode: "smallarea",
          endPoint: "/strategy/majorfacility/v1/regist",
          query: {
            uuid: majorFacility.code,
            centerlat: latlng?.lat().toString(),
            centerlon: latlng?.lng().toString(),
            big_area_uuid: majorFacility.parent_code,
            major_facility_name: majorFacility.name,
            value: majorFacility.attribution.value,
            unit: majorFacility.attribution.unit,
            delete_flag: '0',
            comment: !majorFacility.attribution.comment ? "" : majorFacility.attribution.comment
          }
        };
        handleMapActionChange(false);
        item.visibility = "hidden";
        item.marker.setDraggable(false);

        // userLogging
        userLogging('主要施設位置移動', params, user);
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner()
        }
        (async () => {
          try {
            await apiRequest(params);
            // 登録後主要施設取得
            getMajorFacilityGraphQl(areaCtx, areaCtx.bigArea, "");
            item.isEdit = false;
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner()
            }
          } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
            }
          }
        })();
      }
    })
  }
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((listItem) => {
        google.maps.event.clearListeners(listItem.marker, "click")
        
        listItem.marker.addListener("click", (e:google.maps.MapMouseEvent, majorFacility: any) => {onClick(e, listItem.majorFacility)});
      })
    }
  }, [markerList, onClick]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((listItem) => {
        google.maps.event.clearListeners(listItem.marker, "domready")
        
        listItem.marker.addListener("domready", (majorFacility: any) => {onClickInfo(listItem.majorFacility)});
      })
    }
  }, [markerList, onClickInfo]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.code)
        
        item.info.addListener('infoWindow-' + item.code, (majorFacility: any) => {onInfoWindow(item.majorFacility)});
      })
    }
  }, [markerList, onInfoWindow]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'update-' + item.code)
        
        item.info.addListener('update-' + item.code, (majorFacility: any) => {onUpdate(item.majorFacility)});
      })
    }
  }, [markerList, onUpdate]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'delete-' + item.code)
        
        item.info.addListener('delete-' + item.code, (majorFacility: any) => {onDelete(item.majorFacility)});
      })
    }
  }, [markerList, onDelete]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'move-' + item.code)
        
        item.info.addListener("move-" + item.code, (majorFacility: any) => {onMoveStart(item.majorFacility)});
      })
    }
  }, [markerList, onMoveStart]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'moveCancel-' + item.code)

        item.info.addListener("moveCancel-" + item.code, (majorFacility: any) => {onMoveCancel(item.majorFacility)});
      })
    }
  }, [markerList, onMoveCancel]);
  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      markerList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'moveUpdate-' + item.code)

        item.info.addListener("moveUpdate-" + item.code, (majorFacility: any) => {onMoveUpdate(item.majorFacility)});
      })
    }
  }, [markerList, onMoveUpdate]);

  return (
    <div>
      {
        markerList.map((row, index) => (
          <Paper sx={{position:'absolute',left:'35%',top:'75px',visibility: row.visibility}} key={index} >
            <Stack spacing={4} direction="row">
                <Button id={"moveCancel-" + row.code} variant="contained" startIcon={<ArrowBackIcon />}>キャンセル</Button>
                <Button id={"moveUpdate-" + row.code} variant="contained" startIcon={<Done />} >保存</Button>
            </Stack>
          </Paper>
        ))
      }
      <MajorFacilityUpdateModal props={{open:modalOpen,handleModal:setModalOpen,mode:"majorFacility",targetMajorFacility:areaCtx.majorFacility}}/>
      <ConfirmDialog props={{
        open:confirmDialogOpen,
        handleModal:setConfirmDialogOpen,
        onCancel:onDeleteCancel,
        onExecution:onDeleteExecution,
        mode:"deleteConfirm",
        body:areaCtx.majorFacility.name + "を削除してよろしいですか。",
        confirmCancel:"キャンセル",
        confirmOk:"削除"}} />
    </div>
  );
}