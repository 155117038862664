import React, { useRef, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Unstable_Grid2';
import { v4 as uuidv4 } from 'uuid';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { apiRequest } from "../api/apiCall";
import { areaContext } from './areaContext';
import { getMajorFacilityGraphQl } from './areaDataManage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface majorFacilityRegistProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    mode:string,
    latlng?:google.maps.LatLng,
    polygon?:google.maps.Polygon
  }
}

const MajorFacilityRegistModal:React.FC<majorFacilityRegistProps> = ({props}) => {
  const [majorOpen, setMajorOpen] = React.useState(false);
  const [facilityName, setFacilityName] = React.useState('');
  const [facilityValue, setFacilityValue] = React.useState('');
  const [facilityUnit, setFacilityUnit] = React.useState('');

  const facilityNameinputRef = useRef<HTMLInputElement>(null);
  const facilityValueinputRef = useRef<HTMLInputElement>(null);
  const facilityUnitinputRef = useRef<HTMLInputElement>(null);

  const [facilityNameInputError, setFacilityNameInputError] = useState(false);
  const [facilityValueInputError, setFacilityValueInputError] = useState(false);
  const [facilityUnitInputError, setFacilityUnitInputError] = useState(false);
  const utilityCtx = useUtilityContext();
  const user = useUserContext();

  const handleClose = () => {
    setMajorOpen(false)
    props.handleModal(false)
    setFacilityNameInputError(false);
    setFacilityValueInputError(false);
    setFacilityUnitInputError(false);
    setFacilityName('');
    setFacilityValue('');
    setFacilityUnit('');
    if (facilityNameinputRef?.current) {
      facilityNameinputRef?.current?.setCustomValidity('');
    }
    if (facilityValueinputRef?.current) {
      facilityValueinputRef?.current?.setCustomValidity('');
    }
    if (facilityUnitinputRef?.current) {
      facilityUnitinputRef?.current?.setCustomValidity('');
    }

  };

  const areaCtx = React.useContext(areaContext);

  const changeFacilityName = (e : any) => {
    if (facilityNameinputRef?.current) {
      facilityNameinputRef?.current.setCustomValidity('');
      setFacilityNameInputError(false);
      setFacilityName(e.target.value);
      const maxLength = 33;
      if (e.target.value.length > maxLength) {
        setFacilityNameInputError(true);
        facilityNameinputRef?.current.setCustomValidity('施設名は' + maxLength + '文字以内で入力してください。');
      } else if (e.target.value === '') {
        setFacilityNameInputError(true);
        facilityNameinputRef?.current.setCustomValidity("施設名を入力してください。");
      }
    }
  }

  const changeFacilityValue = (e : any) => {
    if (facilityValueinputRef?.current) {
      facilityValueinputRef?.current.setCustomValidity('');
      setFacilityValueInputError(false);
      setFacilityValue(e.target.value);
      if (e.target.value === '') {
        setFacilityValueInputError(true);
        facilityValueinputRef?.current.setCustomValidity("値を入力してください。");
      } else if(isNaN(e.target.value)){
        setFacilityValueInputError(true);
        facilityValueinputRef?.current.setCustomValidity("値は数値を入力してください。");
      }
    }
  }

  const changeFacilityUnit = (e : any) => {
    if (facilityUnitinputRef?.current) {
      facilityUnitinputRef?.current.setCustomValidity('');
      setFacilityUnitInputError(false);
      setFacilityUnit(e.target.value);
      if (e.target.value === '') {
        setFacilityUnitInputError(true);
        facilityUnitinputRef?.current.setCustomValidity("単位を入力してください。");
      }
    }
  }

  const handleMajorfacilityRegist = () => {
    const codeArr:number[] = []
    areaCtx.bigAreaList.forEach((val)=>{
      codeArr.push(Number(val.code));
    })
    const LatLng = {lat:0,lng:0};
    if(props.latlng){
      LatLng.lat = props.latlng.lat();
      LatLng.lng = props.latlng.lng();
    }

    const params = {
      mode: "majorfacility",
      endPoint: "/strategy/majorfacility/v1/regist",
      query: {
        uuid: uuidv4(),
        centerlat: LatLng.lat.toString(),
        centerlon: LatLng.lng.toString(),
        delete_flag: '0',
        big_area_uuid: areaCtx.bigArea.code,
        major_facility_name: facilityName,
        value: facilityValue,
        unit: facilityUnit,
        comment: ""
      }
    };

    // userLogging
    userLogging('主要施設登録 完了', params, user);
    handleClose();
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        // 登録後エリア取得
        getMajorFacilityGraphQl(areaCtx, areaCtx.bigArea, "");
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  React.useEffect(() =>{
    setFacilityName('');
    setFacilityValue('');
    setFacilityUnit('');
    if(props.open){
      setMajorOpen(props.open);
    }
  },[props.open])

    return (
      <div>
        <Modal
          open={majorOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  主要施設登録
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField id="big-area-view-name" label="行政区名" variant="outlined" defaultValue={areaCtx.bigArea.name} fullWidth disabled />
              </Grid>
              <Grid xs={12}>
                <TextField 
                  error={facilityNameInputError}
                  id="major-facility-regist-name" 
                  label="施設名" 
                  variant="outlined" 
                  fullWidth 
                  onChange={changeFacilityName}
                  inputRef={facilityNameinputRef}
                  helperText={facilityNameinputRef?.current?.validationMessage}/>
              </Grid>
              <Grid xs={12}>
              <TextField 
                  error={facilityValueInputError}
                  id="major-facility-regist-value" 
                  label="値" 
                  variant="outlined" 
                  fullWidth 
                  onChange={changeFacilityValue}
                  inputRef={facilityValueinputRef}
                  helperText={facilityValueinputRef?.current?.validationMessage}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*"
                  }}/>
              </Grid>
              <Grid xs={12}>
              <TextField 
                  error={facilityUnitInputError}
                  id="major-facility-regist-unit" 
                  label="単位" 
                  variant="outlined" 
                  fullWidth 
                  onChange={changeFacilityUnit}
                  inputRef={facilityUnitinputRef}
                  helperText={facilityUnitinputRef?.current?.validationMessage}/>
              </Grid>
              <Grid xs={6}>
                <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                  キャンセル
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button 
                  variant="contained" 
                  color="success"
                  disabled={
                    facilityName !== '' && facilityValue !== '' && facilityUnit !== '' &&
                    facilityNameInputError !== true && facilityValueInputError !== true && facilityUnitInputError !== true 
                    ? false : true}
                  fullWidth onClick={handleMajorfacilityRegist}>
                  登録
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  
}

export default MajorFacilityRegistModal;