import { createContext, useCallback, useState } from 'react';

import { AreaType,latlngLiteral,initialAreaType,existingSejStoreType,closedSejStoreType,PlanAreaType,ZoneType,ConflictStoresType } from './areaInterface'

// set context type
type AreaContext = {
  targetLatlng: latlngLiteral;
  setTargetLatlng: (targetLatlng: latlngLiteral) => void;
  doArea: AreaType;
  setDoArea: (doArea: AreaType) => void;
  doList: AreaType[];
  setDoList: (doList: AreaType[]) => void;
  bigArea: AreaType;
  setBigArea: (bigAreaName: AreaType) => void;
  bigAreaList: AreaType[];
  setBigAreaList: (bigAreaList: AreaType[]) => void;
  bigAreaSelectList: AreaType[];
  setBigAreaSelectList: (bigAreaList: AreaType[]) => void;
  addBigArea: AreaType;
  setAddBigArea: (addBigArea: AreaType) => void;
  smallArea: AreaType;
  setSmallArea: (bigAreaName: AreaType) => void;
  smallAreaList: AreaType[];
  setSmallAreaList: (bigAreaList: AreaType[]) => void;
  smallAreaSelectList: AreaType[];
  setSmallAreaSelectList: (bigAreaList: AreaType[]) => void;
  addSmallArea: AreaType;
  setAddSmallArea: (addBigArea: AreaType) => void;
  addMajorFacility: AreaType;
  setAddMajorFacility: (addMajorFacility: AreaType) => void;
  workingSmallArea: AreaType;
  setWorkingSmallArea: (bigAreaName: AreaType) => void;

  majorFacility: AreaType;
  setMajorFacility: (MajorFacilityName: AreaType) => void;
  majorFacilityList: AreaType[];
  setMajorFacilityList: (majorFacilityList: AreaType[]) => void;
  majorFacilitySelectList: AreaType[];
  setMajorFacilitySelectList: (majorFacilityList: AreaType[]) => void;

  googleMap: google.maps.Map | null;
  setGoogleMap: (tgtMap: google.maps.Map) => void;
  areaDisable: boolean;
  setAreaDisable: (areaDisable: boolean) => void;
  initialCheck: boolean;
  setInitialCheck: (initialCheck: boolean) => void;
  areaCommentDisable: boolean;
  setAreaCommentDisable: (areaDisable: boolean) => void;

  existingSejStoreList: existingSejStoreType[];
  setExistingSejStoreList: (existingSejStoreList: existingSejStoreType[]) => void;
  closedSejStoreList: closedSejStoreType[];
  setClosedSejStoreList: (closedSejStoreList: closedSejStoreType[]) => void;
  planAreaList: PlanAreaType[];
  setPlanAreaList: (planAreaList: PlanAreaType[]) => void;
  zoneList: ZoneType[];
  setZoneList: (zoneList: ZoneType[]) => void;
  conflictStoresList: ConflictStoresType[];
  setConflictStoresList: (zoneList: ConflictStoresType[]) => void;
  
  mapCenter: latlngLiteral;
  setMapCenter: (MapCenter: latlngLiteral) => void;
  mapZoom: number;
  setMapZoom: (MapZoom: number) => void;
  
  hasExistingSej: boolean;
  setHasExistingSej: (initialCheck: boolean) => void;
  hasClosedSej: boolean;
  setHasClosedSej: (initialCheck: boolean) => void;
  hasZone: boolean;
  setHasZone: (initialCheck: boolean) => void;
  hasPlanArea: boolean;
  setHasPlanArea: (initialCheck: boolean) => void;
  hasConflictStores: boolean;
  setHasConflictStores: (initialCheck: boolean) => void;
};

// context default value
const defaultContext: AreaContext = {
  targetLatlng: {lat:0,lng:0},
  setTargetLatlng: () => {},
  doArea: initialAreaType,
  setDoArea: () => {},
  doList: [],
  setDoList: () => {},
  bigArea: initialAreaType,
  setBigArea: () => {},
  bigAreaList: [],
  setBigAreaList: () => {},
  bigAreaSelectList: [],
  setBigAreaSelectList: () => {},
  addBigArea: initialAreaType,
  setAddBigArea: () => {},
  smallArea: initialAreaType,
  setSmallArea: () => {},
  smallAreaList: [],
  setSmallAreaList: () => {},
  smallAreaSelectList: [],
  setSmallAreaSelectList: () => {},
  addSmallArea: initialAreaType,
  setAddSmallArea: () => {},
  addMajorFacility: initialAreaType,
  setAddMajorFacility: () => {},
  workingSmallArea: initialAreaType,
  setWorkingSmallArea: () => {},

  majorFacility: initialAreaType,
  setMajorFacility: () => {},
  majorFacilityList: [],
  setMajorFacilityList: () => {},
  majorFacilitySelectList: [],
  setMajorFacilitySelectList: () => {},

  googleMap: null,
  setGoogleMap: () => {},
  areaDisable: false,
  setAreaDisable: () => {},
  initialCheck: false,
  setInitialCheck: () => {},
  areaCommentDisable: false,
  setAreaCommentDisable: () => {},

  existingSejStoreList: [],
  setExistingSejStoreList: () => {},
  closedSejStoreList: [],
  setClosedSejStoreList: () => {},
  planAreaList: [],
  setPlanAreaList: () => {},
  zoneList: [],
  setZoneList: () => {},
  conflictStoresList: [],
  setConflictStoresList: () => {},

  mapCenter: {lat:0,lng:0},
  setMapCenter: () => {},
  mapZoom: 0,
  setMapZoom: () => {},

  hasExistingSej: false,
  setHasExistingSej: () => {},
  hasClosedSej: false,
  setHasClosedSej: () => {},
  hasZone: false,
  setHasZone: () => {},
  hasPlanArea: false,
  setHasPlanArea: () => {},
  hasConflictStores: false,
  setHasConflictStores: () => {},
};

// context object
export const areaContext = createContext<AreaContext>(defaultContext);

// custom Hook
export const useAreaData = (): AreaContext => {
  // state名はThemeContext typeのプロパティに合わせる。
  const [targetLatlng, setTargetLatlngState] = useState<latlngLiteral>({lat:0,lng:0});
  const [doArea, setDoAreaState] = useState<AreaType>(initialAreaType);
  const [doList, setDoListState] = useState<AreaType[]>([]);
  const [bigArea, setBigAreaState] = useState<AreaType>(initialAreaType);
  const [bigAreaList, setBigAreaListState] = useState<AreaType[]>([]);
  const [bigAreaSelectList, setBigAreaListSelectState] = useState<AreaType[]>([]);
  const [addBigArea, setAddBigAreaState] = useState<AreaType>(initialAreaType);
  const [smallArea, setSmallAreaState] = useState<AreaType>(initialAreaType);
  const [smallAreaList, setSmallAreaListState] = useState<AreaType[]>([]);
  const [smallAreaSelectList, setSmallAreaListSelectState] = useState<AreaType[]>([]);
  const [addSmallArea, setAddSmallAreaState] = useState<AreaType>(initialAreaType);
  const [addMajorFacility, setAddMajorFacilityState] = useState<AreaType>(initialAreaType);
  const [workingSmallArea, setWorkingSmallAreaState] = useState<AreaType>(initialAreaType);

  const [majorFacility, setMajorFacilityState] = useState<AreaType>(initialAreaType);
  const [majorFacilityList, setMajorFacilityListState] = useState<AreaType[]>([]);
  const [majorFacilitySelectList, setMajorFacilitySelectListState] = useState<AreaType[]>([]);
  
  const [googleMap, setGoogleMapState] = useState<google.maps.Map | null>(null);
  const [areaDisable, setAreaDisableState] = useState<boolean>(true);
  const [initialCheck, setInitialCheckState] = useState<boolean>(false);
  const [areaCommentDisable, setAreaCommentDisableState] = useState<boolean>(true);

  const [existingSejStoreList, setExistingSejStoreListState] = useState<existingSejStoreType[]>([]);
  const [closedSejStoreList, setClosedSejStoreListState] = useState<closedSejStoreType[]>([]);
  const [planAreaList, setPlanAreaListState] = useState<PlanAreaType[]>([]);
  const [zoneList, setZoneListState] = useState<ZoneType[]>([]);
  const [conflictStoresList, setConflictStoresListState] = useState<ConflictStoresType[]>([]);

  const [mapCenter, setMapCenterState] = useState<latlngLiteral>({lat:0,lng:0});
  const [mapZoom, setMapZoomState] = useState<number>(0);

  const [hasExistingSej, setHasExistingSejState] = useState<boolean>(false);
  const [hasClosedSej, setHasClosedSejState] = useState<boolean>(false);
  const [hasZone, setHasZoneState] = useState<boolean>(false);
  const [hasPlanArea, setHasPlanAreaState] = useState<boolean>(false);
  const [hasConflictStores, setHasConflictStoresState] = useState<boolean>(false);

  // 関数名はThemeContext typeのプロパティに合わせる。
  const setTargetLatlng = useCallback((current: latlngLiteral): void => {
    setTargetLatlngState(current);
  }, []);
  const setDoArea = useCallback((current: AreaType): void => {
    setDoAreaState(current);
  }, []);
  const setDoList = useCallback((current: AreaType[]): void => {
    setDoListState(current);
  }, []);
  const setBigArea = useCallback((current: AreaType): void => {
    setBigAreaState(current);
  }, []);
  const setBigAreaList = useCallback((current: AreaType[]): void => {
    setBigAreaListState(current);
  }, []);
  const setBigAreaSelectList = useCallback((current: AreaType[]): void => {
    setBigAreaListSelectState(current);
  }, []);
  const setAddBigArea = useCallback((current: AreaType): void => {
    setAddBigAreaState(current);
  }, []);
  const setSmallArea = useCallback((current: AreaType): void => {
    setSmallAreaState(current);
  }, []);
  const setSmallAreaList = useCallback((current: AreaType[]): void => {
    setSmallAreaListState(current);
  }, []);
  const setSmallAreaSelectList = useCallback((current: AreaType[]): void => {
    setSmallAreaListSelectState(current);
  }, []);
  const setAddSmallArea = useCallback((current: AreaType): void => {
    setAddSmallAreaState(current);
  }, []);
  const setAddMajorFacility = useCallback((current: AreaType): void => {
    setAddMajorFacilityState(current);
  }, []);
  const setWorkingSmallArea = useCallback((current: AreaType): void => {
    setWorkingSmallAreaState(current);
  }, []);

  const setMajorFacilityList = useCallback((current: AreaType[]): void => {
    setMajorFacilityListState(current);
  }, []);
  const setMajorFacilitySelectList = useCallback((current: AreaType[]): void => {
    setMajorFacilitySelectListState(current);
  }, []);

  const setMajorFacility = useCallback((current: AreaType): void => {
    setMajorFacilityState(current);
  }, []);
  const setGoogleMap = useCallback((current: google.maps.Map): void => {
    setGoogleMapState(current);
  }, []);
  const setAreaDisable = useCallback((current: boolean): void => {
    setAreaDisableState(current);
  }, []);
  const setInitialCheck = useCallback((current: boolean): void => {
    setInitialCheckState(current);
  }, []);
  const setAreaCommentDisable = useCallback((current: boolean): void => {
    setAreaCommentDisableState(current);
  }, []);
  const setExistingSejStoreList = useCallback((current: existingSejStoreType[]): void => {
    setExistingSejStoreListState(current);
  }, []);
  const setClosedSejStoreList = useCallback((current: closedSejStoreType[]): void => {
    setClosedSejStoreListState(current);
  }, []);
  const setPlanAreaList = useCallback((current: PlanAreaType[]): void => {
    setPlanAreaListState(current);
  }, []);
  const setZoneList = useCallback((current: ZoneType[]): void => {
    setZoneListState(current);
  }, []);
  const setConflictStoresList = useCallback((current: ConflictStoresType[]): void => {
    setConflictStoresListState(current);
  }, []);

  const setMapCenter = useCallback((current: latlngLiteral): void => {
    setMapCenterState(current);
  }, []);
  const setMapZoom = useCallback((current: number): void => {
    setMapZoomState(current);
  }, []);
  const setHasExistingSej = useCallback((current: boolean): void => {
    setHasExistingSejState(current);
  }, []);
  const setHasClosedSej = useCallback((current: boolean): void => {
    setHasClosedSejState(current);
  }, []);
  const setHasZone = useCallback((current: boolean): void => {
    setHasZoneState(current);
  }, []);
  const setHasPlanArea = useCallback((current: boolean): void => {
    setHasPlanAreaState(current);
  }, []);
  const setHasConflictStores = useCallback((current: boolean): void => {
    setHasConflictStoresState(current);
  }, []);
  return {
    targetLatlng,
    setTargetLatlng,
    doArea,
    setDoArea,
    doList,
    setDoList,
    bigArea,
    setBigArea,
    bigAreaList,
    setBigAreaList,
    bigAreaSelectList,
    setBigAreaSelectList,
    addBigArea,
    setAddBigArea,
    smallArea,
    setSmallArea,
    smallAreaList,
    setSmallAreaList,
    smallAreaSelectList,
    setSmallAreaSelectList,
    addSmallArea,
    setAddSmallArea,
    addMajorFacility,
    setAddMajorFacility,
    workingSmallArea,
    setWorkingSmallArea,

    majorFacility,
    setMajorFacility,
    majorFacilityList,
    setMajorFacilityList,
    majorFacilitySelectList,
    setMajorFacilitySelectList,


    googleMap,
    setGoogleMap,
    areaDisable,
    setAreaDisable,
    initialCheck,
    setInitialCheck,
    areaCommentDisable,
    setAreaCommentDisable,

    existingSejStoreList,
    setExistingSejStoreList,
    closedSejStoreList,
    setClosedSejStoreList,
    planAreaList,
    setPlanAreaList,
    zoneList,
    setZoneList,
    conflictStoresList,
    setConflictStoresList,

    mapCenter,
    setMapCenter,
    mapZoom,
    setMapZoom,

    hasExistingSej,
    setHasExistingSej,
    hasClosedSej,
    setHasClosedSej,
    hasZone,
    setHasZone,
    hasPlanArea,
    setHasPlanArea,
    hasConflictStores,
    setHasConflictStores,
  };
};