export interface areaSelectProps {
  props:{
    label:string,
    dataList: AreaType[]
  }
}

export interface analysisDataProps {
  props:{
    data1:string,
    data2:string,
  }
}

export interface latlngLiteral {
  lat: number,
  lng: number
}

export interface AreaType {
  parent_code: string;
  code: string;
  name: string;
  latlng: latlngLiteral;
  attribution?: any;
  direction?: string;
}

export const initialAreaType:AreaType = {
  parent_code: '',
  code: '',
  name: '',
  latlng: {lat:0,lng:0},
  attribution: {}
}

export const makeAreaTypeData = (parent_code:string, code:string, name:string, lat:number, lng:number, attribution:any) => {
  const obj:AreaType = {
    parent_code,
    code,
    name,
    latlng:{lat,lng},
    attribution
  };
  return obj;
}

export interface existingSejStoreType {
  store_code: string,
  ctycode:  string,
  tenpo_name: string,
  tenpo_address: string,
  prop_site_no: string,
  step: number,
  centerlat: number,
  centerlon: number,
  geohash: string,
  openingdate: string,
  closingdate: string,
  doname: string,
  dmname: string,
  ofcname: string,
  zoname: string,
  contract_type: string,
  parkingarea: number,
  sales_forecast: number,
  salesfloor: number,
  liquorlicense: string,
  tobaccolicense: string,
  plan_area_feature: string,
  building_feature: string,
  entrance_feature: string,
  existing_best_location_flag: string,
  existing_multi_flag: string,
  store_direction: string,
  sales_type: string,
}

export interface closedSejStoreType {
  store_code: string,
  ctycode: string,
  tenpo_name: string,
  tenpo_address: string,
  prop_site_no: string,
  step: number,
  centerlat: number,
  centerlon: number,
  geohash: string,
  openingdate: string,
  closingdate: string,
  dmname: string,
  ofcname: string,
  zoname: string,
  contract_type: string,
  customers: number,
  parkingarea: number,
  sales: number,
  sales_forecast: number,
  salesfloor: number,
  liquorlicense: string,
  tobaccolicense: string,
  plan_area_feature: string,
  building_feature: string,
  entrance_feature: string,
  update_date: string,
  existing_best_location_flag: string,
  existing_multi_flag: string,
  store_direction: string,
}

export interface PlanAreaType {
  id: string,
  uuid: string,
  centerlat: number,
  centerlon: number,
  geometry: string,
  entrance_feature: string,
  plan_area_feature: string,
  building_feature: string,
  citycode: string,
  display_flag: string,
  deletable_flag: string,
  request_flag: string,
  edit_flag: string,
  corporate_flag: string,
  plan_area_name: string,
  step: number,
  plan_area_address: string,
  plan_area_zipcode: string,
  plan_area_townname: string,
  plan_area_townname_serial_number: string,
  zone_id: string,
  plan_area_area_size: number,
  building_area_size: number,
  plan_area_current_state: string,
  plan_area_owner_id: string,
  plan_area_amount_sales: string,
  latlon_jpn: string,
  do_code: string,
  dm_confirm: string,
  rm_confirm: string,
  existing_best_location_flag: string,
  existing_multi_flag: string,
  evaluation_score: string,
  evaluation_version: string,
  visit_activity: string,
  current_situation1: string,
  current_situation2: string,
  minor_classification1: string,
  minor_classification2: string,
  tenant_name1: string,
  tenant_name2: string,
  branch_name1: string,
  branch_name2: string,
  remarks: string,
  pending_flag: string,
  giving_up_reason: string,
  store_direction: string,
  create_date: string,
  update_date: string,
  zone_is_partial_step: string,
  zone_plus_one_existing_feature: string,
  zone_scrap_existing_feature: string,
  zone_pincer_attack_flag: string,
}

export interface ZoneType {
  id: string,
  uuid: string,
  centerlat: number,
  centerlon: number,
  shape_geometry: string,
  shape_feature: string,
  citycode: string,
  zone_name: string,
  zone_type: string,
  status: string,
  request_flag: string,
  zone_townname: string,
  zone_townname_serial_number: string,
  plan_area_id: string,
  do_code: string,
  dm_confirm: string,
  rm_confirm: string,
  intention: string,
  plus_one_existing_store_name: string,
  plus_one_existing_store_id: string,
  plus_one_existing_feature: string,
  plus_one_existing_latlng: string,
  multi_owner_existing_store_name: string,
  multi_owner_existing_store_id: string,
  multi_owner_existing_feature: string,
  multi_owner_existing_latlng: string,
  scrap_existing_name: string,
  scrap_existing_id: string,
  scrap_existing_feature: string,
  scrap_existing_latlng: string,
  pincer_attack_flag: string,
  pincer_attack_conflict_store_name: string,
  pincer_attack_conflict_store_id: string,
  pincer_attack_conflict_feature: string,
  pincer_attack_conflict_latlng: string,
  pincer_attack_existing_store_name: string,
  pincer_attack_existing_store_id: string,
  pincer_attack_existing_feature: string,
  pincer_attack_existing_latlng: string,
  is_partial_step: boolean,
  create_date: string,
  update_date: string,
}

export interface ConflictStoresType {
  id: string,
  uuid: string,
  centerlat: number,
  centerlon: number,
  create_date: string,
  update_date: string,
  citycode: string,
  display_flag: string,
  type: string,
  comment: string,
  bland_id: string,
  tenpo_name: string,
  tenpo_address: string,
  handle_category_liquor: string,
  handle_category_tobacco: string,
  handle_category_parking: string,
  sales_floor_area_size: number,
  parking_area_size: number,
  open_date: string,
  close_date: string,
  bland_name: string,
  tel: string,
  business_hours: string,
  closing_time: string,
  regular_holiday: string,
  handle_category_ATM: string,
  fax: string,
  handle_category_other: string,
  location: string,
  gondola_scale: string,
  store_frontage: string,
  number_of_parking: string,
  front_parking_tangent: string,
  front_first_entrance: string,
  front_first_depth: string,
  front_second_entrance: string,
  front_second_depth: string,
  side_parking_tangent: string,
  side_first_entrance: string,
  side_first_depth: string,
  side_second_entrance: string,
  side_second_depth: string,
  estimated_daily_sales: string,
  assortment_meat: string,
  assortment_vegetable: string,
  assortment_fish: string,
  assortment_frozen_food: string,
  assortment_medicine: string,
}