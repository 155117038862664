import React, { useRef, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { apiRequest } from "../api/apiCall";
import { areaContext } from './areaContext';
import { directionList, getBigAreaGraphQl } from './areaDataManage';
import { AreaType, initialAreaType } from './areaInterface';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface bigAreaUpdateProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    mode:string,
    targetBigArea:AreaType
  }
}

const BigAreaUpdateModal:React.FC<bigAreaUpdateProps> = ({props}) => {
  const [bigArea, setBigArea] = useState<AreaType>(initialAreaType);
  const [bigOpen, setBigOpen] = React.useState(false);
  const [bigAreaDirection, setBigAreaDirection] = React.useState('');
  const [bigAreaName, setBigAreaName] = React.useState('');
  const bigAreainputRef = useRef<HTMLInputElement>(null);
  const bigAreaDirectionRef = useRef<HTMLInputElement>(null);
  const [bigAreaInputError, setBigAreaInputError] = useState(false);
  const [bigAreaDirectionInputError, setBigAreaDirectionInputError] = useState(false);
  const utilityCtx = useUtilityContext();
  const user = useUserContext();

  const handleClose = () => {
    setBigOpen(false)
    props.handleModal(false)
    setBigAreaDirectionInputError(false);
    setBigAreaInputError(false);
    setBigAreaName('')
    setBigAreaDirection('');
    if (bigAreainputRef?.current) {
      bigAreainputRef?.current.setCustomValidity('');
    }
  };

  const areaCtx = React.useContext(areaContext);

  const changeBigAreaName = (e : any) => {
    if (bigAreainputRef?.current) {
      bigAreainputRef?.current.setCustomValidity('');
      setBigAreaInputError(false);
      setBigAreaName(e.target.value);
      if (e.target.value === '') {
        setBigAreaInputError(true);
        bigAreainputRef?.current.setCustomValidity("行政区を入力してください。");
      }
    }
  }
  const changeBigAreaDirection:any = (e : any) => {
    if (bigAreaDirectionRef?.current) {
      setBigAreaDirectionInputError(false);
      setBigAreaDirection(e.target.value);
      if (e.target.value === '') {
        setBigAreaDirectionInputError(true);
      }
    }
  }

  const handleBigRegist = () => {
    const codeArr:number[] = []
    areaCtx.bigAreaList.forEach((val)=>{
      codeArr.push(Number(val.code));
    })

    const params = {
      mode: "bigarea",
      endPoint: "/strategy/bigarea/v1/regist",
      query: {
        uuid: bigArea.code,
        centerlat: bigArea.latlng.lat.toString(),
        centerlon: bigArea.latlng.lng.toString(),
        big_area_name: bigAreaName,
        do_code: bigArea.parent_code,
        direction: bigAreaDirection,
      }
    };
    // userLogging
    userLogging('行政区編集 完了', params, user);
    handleClose();
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        // 登録後エリア取得
        getBigAreaGraphQl(areaCtx, areaCtx.doArea.code, "", bigArea.code);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  React.useEffect(() =>{
    if(props.open){
      setBigAreaName('')
      setBigAreaDirection('')
      setBigOpen(props.open);
    }

    if (props.targetBigArea !== initialAreaType) {
      setBigArea(props.targetBigArea)
      setBigAreaName(props.targetBigArea.name)
      setBigAreaDirection(props.targetBigArea.name)
      setBigAreaDirection(props.targetBigArea.attribution.direction_code);
  }
  },[props.open, props.targetBigArea])

    return (
      <div>
        <Modal
          open={bigOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  行政区編集
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField id="big-area-do" label="ＤＯ" variant="outlined" defaultValue={areaCtx.doArea.name} fullWidth disabled />
              </Grid>
              <Grid xs={12}>
                <TextField 
                  error={bigAreaInputError}
                  required
                  id="big-area-regist-name" 
                  label="行政区名" 
                  variant="outlined" 
                  fullWidth 
                  value={bigAreaName}
                  onChange={changeBigAreaName}
                  inputRef={bigAreainputRef}
                  helperText={bigAreainputRef?.current?.validationMessage}/>
              </Grid>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <InputLabel required id="big-area-regist-direction">方向性</InputLabel>
                  <Select
                    error={bigAreaDirectionInputError}
                    sx={{}}
                    labelId="big-area-regist-direction-select"
                    id="big-area-regist-direction-select"
                    value={bigAreaDirection}
                    label="方向性"
                    onChange={changeBigAreaDirection}
                    inputRef={bigAreaDirectionRef}
                  >
                    {Object.entries(directionList).map(([key,value]) => (
                      <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                  キャンセル
                </Button>
              </Grid>
              <Grid xs={6}>
                <Button 
                  variant="contained" 
                  color="success"
                  disabled={bigAreaName !== '' && bigAreaDirection !== '' &&
                  bigAreaInputError !== true && bigAreaDirectionInputError !== true
                  ? false : true}
                  fullWidth onClick={handleBigRegist}>
                  完了
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  
}

export default BigAreaUpdateModal;


