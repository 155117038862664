import { postRequest } from "./axiosAction";
import { getApiParam } from "./params";

// graphqlの呼び出し
export const graphqlRequest = async <T>(params: any) => {
  const graphql_api_url: string = process.env.REACT_APP_STRATEGY_GRAPHQL! + "/graphql";
  let res: T;
  try {
    res = await postRequest(graphql_api_url, getApiParam(params)) as T;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  return res;
};

export const apiRequest = async (params: any) => {
  const graphql_api_url: string = process.env.REACT_APP_STRATEGY_GRAPHQL! + params.endPoint;
  let res: any = {};
  try {
    res = await postRequest(graphql_api_url, getApiParam(params));
  } catch (error) {
    console.log("error", error);
    throw error;
  }
  return res;
};