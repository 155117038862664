import * as React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ReplayIcon from '@mui/icons-material/Replay';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useUtilityContext } from '../../utility-provider';
import { booleanKinksPolygon, getLineIntersect, getPointOnLine } from '../../utility/turf';

const basePolygonOptions = {
  strokeColor: "#FF0000",
  strokeOpacity: 0,
  strokeWeight: 2,
  fillColor: "#FF0000",
  fillOpacity: 0,
  clickable: true,
  zIndex: 1001,
}

interface PolygonDrawingProps extends google.maps.PolygonOptions {
  setDrawPolygon?: React.Dispatch<React.SetStateAction<google.maps.Polygon | undefined>>
  setPolygonOptions?: React.Dispatch<React.SetStateAction<google.maps.PolygonOptions>>
  handleModal?:React.Dispatch<React.SetStateAction<boolean>>,
}

export const PolygonDrawing: React.FC<PolygonDrawingProps> = ({
  setDrawPolygon,
  setPolygonOptions,
  handleModal,
  ...options
  }) => {
  const [overlayPolygon, setOverlayPolygon] = React.useState<google.maps.Polygon>();
  const [startPoint, setStartPoint] = React.useState<google.maps.Marker>();
  const [tempLine, setTempLine] = React.useState<google.maps.Polyline>();
  const [chasePolygonLine, setChasePolygonLine] = React.useState<google.maps.Polyline>();
  const [posPolygonArray, setPosPolygonArray] = React.useState<google.maps.LatLng[]>([]);
  const [controlBarVisibility, setControlBarVisibility] = React.useState<string>("hidden");

  const utilityCtx = useUtilityContext();

  const japanPoints = [
    new google.maps.LatLng(23.76371428122162, 123.00645985915843),
    new google.maps.LatLng(24.277098587435376, 143.2163593499933),
    new google.maps.LatLng(41.90413564957214, 144.08786830991315),
    new google.maps.LatLng(47.280923147488046, 154.76657924741315),
    new google.maps.LatLng(48.66359222734448, 152.87693080991315),
    new google.maps.LatLng(45.46188216704111, 144.48337612241315),
    new google.maps.LatLng(45.79488945313425, 139.83274206369904),
    new google.maps.LatLng(38.389651501813184, 137.37180456369904),
    new google.maps.LatLng(34.822023562011985, 129.31176512637802),
    new google.maps.LatLng(32.707914240374386, 128.15459262003836)
  ];

  React.useEffect(() => {
    // 透明ポリゴン初期化
    if (!overlayPolygon) {
      setOverlayPolygon(new google.maps.Polygon({paths:japanPoints,...basePolygonOptions}));
    }
    return () => {
      if (overlayPolygon) {
        overlayPolygon.setMap(null);
      }
    };
  }, [overlayPolygon]);

  React.useEffect(() => {
    // 始点ポイント初期化
    if (!startPoint) {
      setStartPoint(new google.maps.Marker({}));
    }
    return () => {
      if (startPoint) {
        startPoint.setMap(null);
      }
    };
  }, [startPoint]);

  React.useEffect(() => {
    // 描写中ライン初期化
    if (!tempLine) {
      setTempLine(new google.maps.Polyline({}));
    }
    return () => {
      if (tempLine) {
        tempLine.setMap(null);
      }
    };
  }, [tempLine]);

  React.useEffect(() => {
    // 追従ライン初期化
    if (!chasePolygonLine) {
      setChasePolygonLine(new google.maps.Polyline({}));
    }
    return () => {
      if (chasePolygonLine) {
        chasePolygonLine.setMap(null);
      }
    };
  }, [chasePolygonLine]);

  React.useEffect(() => {
    // 本処理
    if (overlayPolygon) {
      ["click","dblclick"].forEach((eventName) =>
        google.maps.event.clearListeners(overlayPolygon, eventName)
      );

      overlayPolygon.setOptions(options);

      // ポリゴン描写処理の設定
      overlayPolygon.addListener("click",(e:google.maps.PolyMouseEvent) => {addPolygonPoint(e)})
      overlayPolygon.addListener("dblclick",(e:google.maps.PolyMouseEvent) => {
        // 地図拡大処理の防止
        e.stop()
        // ポリゴンの確定
        confirmPolygon()
      })
    }
  }, [overlayPolygon, options, setDrawPolygon]);

  React.useEffect(() => {
    setControlBarVisibility(options.visible ? "visible" : "hidden");
  },[options.visible])

  // ポリゴン配列に緯度経度を追加
  const addPolygonPoint = (e:google.maps.PolyMouseEvent) => {
    if(posPolygonArray.length === 0){
      posPolygonArray.push(e.latLng!);
      // スタート地点設定
      setPoint(e.latLng!)
      // 追従ライン設定
      overlayPolygon!.addListener("mousemove",(e:google.maps.PolyMouseEvent) => {setChasePolygonLineEvent(e)})
      // 始点クリック時のイベント
      if(startPoint){
        startPoint.addListener("click",() => {confirmPolygon()})
      }
    }else{
      if(e.latLng?.equals(posPolygonArray[posPolygonArray.length - 1])){
        // 連続で同じ座標をクリックした場合は配列に追加しない
        return;
      }
      // クリックした点をつなぐラインを描写
      posPolygonArray.push(e.latLng!);
      setPolygonLine()
      // エラーチェック
      // 交差チェック
      if(booleanKinksError(false)){
        removePolygonPoint()
      }
    }
  }

  // 始点のマーカーを表示
  const setPoint = (latLng:google.maps.LatLng) => {
    if(startPoint){
      startPoint.setMap(overlayPolygon!.getMap())
      startPoint.setPosition(latLng)
    }
  }

  // カーソルに追従する線を表示
  const setChasePolygonLineEvent = (e:google.maps.PolyMouseEvent) => {
    if(chasePolygonLine){
      chasePolygonLine.setMap(overlayPolygon!.getMap())
      chasePolygonLine.setPath([posPolygonArray[posPolygonArray.length - 1],e.latLng!])
    }
  }

  // ポリゴンの点の数を判定
  const booleanShortLengthError = () => {
    let msg;
    // 登録されたポリゴンの点が3点以下の場合エラーを表示
    if (posPolygonArray.length < 3) {
      msg = "３点以上登録してください。";
      if(utilityCtx.showSnackbar){
        utilityCtx.showSnackbar('error', msg);
      }
    }
    return msg != null;
  };

  // よじれ判定
  const booleanKinksError = (isConfirm:boolean) => {
    let msg;
    if (isConfirm) {
      // ポリゴン確定時によじれを判定
      const checkPolygon = new google.maps.Polygon({paths:posPolygonArray})
      if (booleanKinksPolygon(checkPolygon)) {
        // よじれているポリゴンの場合
        msg = "よじれを持つポリゴンは作成できません。";
        if(utilityCtx.showSnackbar){
          utilityCtx.showSnackbar('error', msg);
        }
      }
    } else {
      // ポリゴンの点追加時に交差するラインを判定
      if(posPolygonArray.length <= 2){
        return;
      }
      const currentPolygonLine = new google.maps.Polyline();
      const addPolygonLine = new google.maps.Polyline();
      const validPolygonLine = new google.maps.Polyline();
      const arrayLastPos = posPolygonArray.length - 1;
      currentPolygonLine.setPath(posPolygonArray.slice(0, arrayLastPos));
      addPolygonLine.setPath([posPolygonArray[arrayLastPos - 1], posPolygonArray[arrayLastPos]]);
      const distPoint = getPointOnLine(addPolygonLine, 1);
      validPolygonLine.setPath([distPoint, posPolygonArray[arrayLastPos]]);
      if (getLineIntersect(currentPolygonLine, validPolygonLine).features.length > 0) {
        // よじれているポリゴンの場合
        msg = "よじれを持つポリゴンは作成できません。";
        if(utilityCtx.showSnackbar){
          utilityCtx.showSnackbar('error', msg);
        }
      }
    }
    return msg != null;
  }

  // ポリゴンの確定
  const confirmPolygon = () => {
    if (
      !booleanShortLengthError() &&
      !booleanKinksError(true)
    ) {
      const returnPolygon = new google.maps.Polygon({paths:posPolygonArray})

      // ポリゴンの削除
      clearPolygonSettings()

      if(setDrawPolygon){
        setDrawPolygon(returnPolygon)
      }
      if(setPolygonOptions){
        setPolygonOptions({visible:false})
      }
      if(handleModal){
        console.log("handle modal")
        handleModal(true);
      }
    }
  }

  // ポリゴン配列の最後のデータを削除(一つ戻すボタン)
  const removePolygonPoint = () => {
    if (posPolygonArray.length === 1) {
      posPolygonArray.pop();
      clearStartPoint();
      clearChasePolygonLine(true);
    } else if (posPolygonArray.length >= 2) {
      posPolygonArray.pop();
      setPolygonLine();
      clearChasePolygonLine(false);
    }
  }

  // 作成中の線を表示
  const setPolygonLine = () => {
    if(tempLine){
      tempLine.setMap(overlayPolygon!.getMap())
      tempLine.setPath(posPolygonArray)
    }
  }

  // 描画モード解除(戻るボタン)
  const closePolygonDrawing = () => {
    clearPolygonSettings();
    if(setPolygonOptions){
      setPolygonOptions({visible:false})
    }
  }

  // ポリゴン初期化(やり直すボタン)
  const clearPolygonSettings = () => {
    clearPolygon();
    clearOverlayPolygon();
    clearStartPoint();
    clearPolygonLine();
    clearChasePolygonLine(true);
    clearPosPolygonArray();
  }

  const clearPolygon = () => {
    if(setDrawPolygon){
      setDrawPolygon(undefined)
    }
  }

  const clearOverlayPolygon = () => {
    google.maps.event.clearInstanceListeners(overlayPolygon!);
    setOverlayPolygon(new google.maps.Polygon({paths:japanPoints,...basePolygonOptions}))
  }

  const clearStartPoint = () => {
    if (startPoint) {
      google.maps.event.clearInstanceListeners(startPoint);
      startPoint.setMap(null);
    }
  }

  const clearPolygonLine = () => {
    if (tempLine) {
      tempLine.setMap(null);
    }
  }

  const clearChasePolygonLine = (isClearListener:boolean) => {
    if (chasePolygonLine) {
      if (isClearListener) {
        google.maps.event.clearListeners(overlayPolygon!, "mousemove");
      }
      chasePolygonLine.setMap(null);
    }
  }

  const clearPosPolygonArray = () => {
    setPosPolygonArray([]);
  }


  
  return (
    <Paper sx={{position:'absolute',left:'30%',top:'75px',visibility:controlBarVisibility}} >
      <Stack spacing={2} direction="row">
        <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={closePolygonDrawing}>戻る</Button>
        <Button variant="contained" startIcon={<ArrowBackIosNewIcon />} onClick={removePolygonPoint}>一つ戻す</Button>
        <Button variant="contained" startIcon={<ReplayIcon />} onClick={clearPolygonSettings}>やり直す</Button>
      </Stack>
    </Paper>
  );
};