
import * as React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { areaContext } from '../../data/areaContext';
import { analysisDataProps } from '../../data/areaInterface';

const AnalysisData:React.FC<analysisDataProps> = ({props}) => {
  const areaCtx = React.useContext(areaContext);

  const TableCell = styled(MuiTableCell)(({ theme }) => ({
    padding: theme.spacing(1),
    fontSize: 1,
  }));

  let bigNumberOfHouseholds = 0;
  let bigTotalPopulation = 0;
  let bigPopulationChange = 0;
  let bigEmployedPerson = 0;
  let smallNumberOfHouseholds = 0;
  let smallTotalPopulation = 0;
  let smallPopulationChange = 0;
  let smallEmployedPerson = 0;
  if (areaCtx.initialCheck) {
    const analysisInformation = JSON.parse(areaCtx.smallArea.attribution.analysis_information);
    smallNumberOfHouseholds = Math.round(analysisInformation.numberOfHouseholds);
    smallTotalPopulation = Math.round(analysisInformation.totalPopulation);
    smallPopulationChange = Math.round(analysisInformation.populationChange);
    smallEmployedPerson = Math.round(analysisInformation.employedPerson);
    areaCtx.smallAreaList.forEach((area) => {
      const areaAnalysisInformation = JSON.parse(area.attribution.analysis_information);
      bigNumberOfHouseholds += Math.round(areaAnalysisInformation.numberOfHouseholds);
      bigTotalPopulation += Math.round(areaAnalysisInformation.totalPopulation);
      bigPopulationChange += Math.round(areaAnalysisInformation.populationChange);
      bigEmployedPerson += Math.round(areaAnalysisInformation.employedPerson);
    })
  }

  const tableCell1:any = { padding: 0, fontSize: 10 }
  const tableCell2:any = { borderLeft: "1px solid rgba(224, 224, 224, 1)", padding: 0, fontSize: 10 }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ }} aria-label="AreaTable">
        <TableHead>
          <TableRow>
            <TableCell sx={{backgroundColor: '#fadbd4'}}></TableCell>
            <TableCell align="center" colSpan={2} sx={{backgroundColor:'#fadbd4', padding: 0,  fontSize: 10, fontWeight: 'bold'}}>行政区</TableCell>
            <TableCell align="center" colSpan={2} sx={{backgroundColor:'#fadbd4', padding: 0,  fontSize: 10, fontWeight: 'bold'}}>エリア</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
              <TableCell align="center" sx={tableCell1}>人口</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{bigTotalPopulation.toLocaleString()}</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{smallTotalPopulation.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell align="center" sx={tableCell1}>人口増減数<br />H27-22</TableCell>
              <TableCell align="center" sx={tableCell2}>{bigPopulationChange.toLocaleString()}</TableCell>
              <TableCell align="center" sx={tableCell2}>{(Math.round((bigTotalPopulation / (bigTotalPopulation - bigPopulationChange)) * 1000) / 10).toLocaleString()}%</TableCell>
              <TableCell align="center" sx={tableCell2}>{smallPopulationChange.toLocaleString()}</TableCell>
              <TableCell align="center" sx={tableCell2}>{(Math.round((smallTotalPopulation / (smallTotalPopulation - smallPopulationChange)) * 1000) / 10).toLocaleString()}%</TableCell>
          </TableRow>
          <TableRow>
              <TableCell align="center" sx={tableCell1}>世帯数</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{bigNumberOfHouseholds.toLocaleString()}</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{smallNumberOfHouseholds.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell align="center" sx={tableCell1}>就業者数</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{bigEmployedPerson.toLocaleString()}</TableCell>
              <TableCell align="center" colSpan={2} sx={tableCell2}>{smallEmployedPerson.toLocaleString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

}

export default AnalysisData;