import React, { useRef, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { v4 as uuidv4 } from 'uuid';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { getPolygonCenter, googleMapsPolygon2GeoJsonPolygon } from '../../utility/turf';
import { apiRequest } from "../api/apiCall";
import { areaContext } from './areaContext';
import { directionList, getSmallAreaGraphQl } from './areaDataManage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface smallAreaRegistProps {
  props:{
    open:boolean,
    handleModal:React.Dispatch<React.SetStateAction<boolean>>,
    mode:string,
    latlng?:google.maps.LatLng,
    polygon?:google.maps.Polygon
  }
}

const SmallAreaRegistModal:React.FC<smallAreaRegistProps> = ({props}) => {
  const [smallOpen, setSmallOpen] = React.useState(false);
  const [smallAreaDirection, setSmallAreaDirection] = React.useState('');
  const [smallAreaName, setSmallAreaName] = React.useState('');
  const smallAreainputRef = useRef<HTMLInputElement>(null);
  const smallAreaDirectionRef = useRef<HTMLInputElement>(null);
  const [smallAreaInputError, setSmallAreaInputError] = useState(false);
  const [smallAreaDirectionInputError, setSmallAreaDirectionInputError] = useState(false);
  const utilityCtx = useUtilityContext();
  const user = useUserContext();

  const handleClose = () => {
    setSmallOpen(false)
    props.handleModal(false)
    setSmallAreaDirectionInputError(false);
    setSmallAreaInputError(false);
    setSmallAreaName('')
    setSmallAreaDirection('');
    if (smallAreainputRef?.current) {
      smallAreainputRef?.current.setCustomValidity('');
      
    }
  };

  const areaCtx = React.useContext(areaContext);

  const changeSmallAreaName = (e : any) => {

    if (smallAreainputRef?.current) {
      smallAreainputRef?.current.setCustomValidity('');
      setSmallAreaInputError(false);
      setSmallAreaName(e.target.value);
      if (e.target.value === '') {
        setSmallAreaInputError(true);
        smallAreainputRef?.current.setCustomValidity("エリアを入力してください。");
      }
    }

  }
  const changeSmallAreaDirection:any = (e : any) => {

    if (smallAreaDirectionRef?.current) {
      setSmallAreaDirectionInputError(false);
      setSmallAreaDirection(e.target.value);
      if (e.target.value === '') {
        setSmallAreaDirectionInputError(true);
      }
    }

  }

  const handleSmallRegist = () => {
    const codeArr:number[] = []
    areaCtx.smallAreaList.forEach((val)=>{
      codeArr.push(Number(val.code));
    })
    const uuid = uuidv4();
    const poly = googleMapsPolygon2GeoJsonPolygon(props.polygon!);
    props.polygon!.setMap(null);
    const centerLatLng = getPolygonCenter(poly);

    const params = {
      mode: "smallarea",
      endPoint: "/strategy/smallarea/v1/regist",
      query: {
        uuid: uuid,
        centerlat: centerLatLng.geometry.coordinates[1].toString(),
        centerlon: centerLatLng.geometry.coordinates[0].toString(),
        delete_flag: '0',
        big_area_uuid: areaCtx.bigArea.code,
        small_area_name: smallAreaName,
        direction: smallAreaDirection,
        polygon: JSON.stringify(poly).toString(),
        comment: "",
        comment2: "",
        analysis_information: "",
        cvs_data: "",
        cvs_data_user_input: "{}"
      }
    };

    // userLogging
    userLogging('エリア登録 完了', params, user);
    handleClose();
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        // 登録後エリア取得
        getSmallAreaGraphQl(areaCtx, areaCtx.bigArea, "", uuid);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  React.useEffect(() =>{
    if(props.open){
      setSmallAreaName('')
      setSmallOpen(props.open);
    }
  },[props.open])

    return (
      <div>
        <Modal
          open={smallOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={1}>
              <Grid xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  エリア登録
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField id="big-area-view-name" label="行政区名" variant="outlined" defaultValue={areaCtx.bigArea.name} fullWidth disabled />
              </Grid>
              <Grid xs={12}>
                <TextField 
                  error={smallAreaInputError}
                  required
                  id="small-area-regist-name" 
                  label="エリア名" 
                  variant="outlined" 
                  fullWidth 
                  onChange={changeSmallAreaName}
                  inputRef={smallAreainputRef}
                  helperText={smallAreainputRef?.current?.validationMessage}/>
              </Grid>
              <Grid xs={12}>
                <FormControl fullWidth>
                  <InputLabel required id="small-area-regist-direction">方向性</InputLabel>
                  <Select
                    error={smallAreaDirectionInputError}
                    sx={{}}
                    labelId="small-area-regist-direction-select"
                    id="small-area-regist-direction-select"
                    value={smallAreaDirection}
                    label="方向性"
                    onChange={changeSmallAreaDirection}
                    inputRef={smallAreaDirectionRef}
                  >
                    {Object.entries(directionList).map(([key,value]) => (
                      <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <Button variant="contained" color="error" fullWidth onClick={handleClose}>
                  キャンセル
                </Button>
              </Grid>
              <Grid xs={6}>
              <Button 
                  variant="contained" 
                  color="success"
                  disabled={smallAreaName !== '' && smallAreaDirection !== '' &&
                  smallAreaInputError !== true && smallAreaDirectionInputError !== true
                  ? false : true}
                  fullWidth onClick={handleSmallRegist}>
                  登録
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  
}

export default SmallAreaRegistModal;