import * as React from 'react';

import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PolylineIcon from '@mui/icons-material/Polyline';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { areaContext } from '../data/areaContext';
import MainFacilityModal from '../data/MajorFacilityModal';
import { ContextMenuProps } from './mapInterface';

const ContextMenu:React.FC<ContextMenuProps> = ({props}) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();
  const [mainOpen, setMainOpen] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<string>("hidden");
  const user = useUserContext();

  React.useEffect(() => {
    setVisible(props.visibility)
  },[props.visibility])

  React.useEffect(() => {
    // 地図選択位置へのピン設定
    if (!marker && props.latlng && props.map) {
      // 初期設定時
      setMarker(new google.maps.Marker({position:props.latlng,map:props.map,animation:google.maps.Animation.DROP}));
    }

    if(marker && props.latlng && props.map){
      // 更新時
      marker.setOptions({position:props.latlng,map:props.map,animation:google.maps.Animation.DROP});
    }

    if(marker && !props.latlng){
      // 削除時
      marker.setMap(null);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker,props.latlng]);

  const areaCtx = React.useContext(areaContext);
  const utilityCtx = useUtilityContext();

  const onClickSmallArea = () => {
    const masSmallAreaList = 20;
    if(utilityCtx.showSnackbar && areaCtx.bigArea.name === ''){
      utilityCtx.showSnackbar('warning','行政区を選択してください')
      return
    }
    if(utilityCtx.showSnackbar && areaCtx.smallAreaList.length >= masSmallAreaList){
      utilityCtx.showSnackbar('warning','エリアの上限（' + masSmallAreaList + '個）に達している為、登録できません。')
      return
    }
    if(marker){
      marker.setMap(null);
    }
    setVisible("hidden")
    if(props.setPolygonOptions){
      // userLogging
      userLogging('コンテキストメニューエリア登録 モーダルOPEN', {}, user);
      props.setPolygonOptions({visible:true})
    }
  }

  const onClickMainFacility = () => {
    if(utilityCtx.showSnackbar && areaCtx.bigArea.name === ''){
      utilityCtx.showSnackbar('warning','行政区を選択してください')
      return
    }
    if(marker){
      marker.setMap(null);
    }
    // userLogging
    userLogging('コンテキストメニュー主要施設登録 モーダルOPEN', {}, user);
    setVisible("hidden")
    setMainOpen(true);
  }

  return (
    <Paper sx={{ width: 180, position: 'fixed', left: props.left, top: props.top, visibility:visible }}>
      <MenuList>
        <MenuItem>
          <ListItemIcon onClick={onClickSmallArea}>
            <PolylineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={onClickSmallArea}>エリア登録</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon onClick={onClickMainFacility}>
            <MapsHomeWorkIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText onClick={onClickMainFacility}>主要施設登録</ListItemText>
          <MainFacilityModal props={{open:mainOpen,handleModal:setMainOpen,mode:"mainFacility",latlng:props.latlng}}/>
        </MenuItem>
      </MenuList>
    </Paper>
  )
}

export default ContextMenu;