import * as React from "react";

export const onMarkercomplete = (marker: google.maps.Marker) => {
  // avoid directly mutating state
  console.log('マーカー設置');
  marker.setDraggable(true);
  // setClicks([...clicks, e.latLng!]);
  // setDrawingManager({drawingControl:true});
};

export const drawingManagerInitialValue: google.maps.drawing.DrawingManagerOptions = {
  drawingControl:false,
  drawingMode:null
}

interface DrawingManagerProps extends google.maps.drawing.DrawingManagerOptions {
  onMarkercomplete?: (e: google.maps.Marker) => void;
}

export const DrawingManager: React.FC<DrawingManagerProps> = ({
    onMarkercomplete,
    ...options
  }) => {
  const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager>();
  
  React.useEffect(() => {
    if (!drawingManager) {
      setDrawingManager(new google.maps.drawing.DrawingManager({drawingControl:false}));
    }

    // remove marker from map on unmount
    return () => {
      if (drawingManager) {
        drawingManager.setMap(null);
      }
    };
  }, [drawingManager]);

  React.useEffect(() => {
    if (drawingManager) {
      drawingManager.setOptions(options);

      ["markercomplete"].forEach((eventName) =>
        google.maps.event.clearListeners(drawingManager, eventName)
      );
      if(onMarkercomplete){
        drawingManager.addListener("markercomplete",(e:google.maps.Marker) => {onMarkercomplete(e)})
      }
      
    }
  }, [drawingManager, options, onMarkercomplete]);

  return null;
};