import Grid from '@mui/material/Grid';

import AnalysisData from './Small/AnalysisData';
import SmallAreaComment1 from './Small/SmallAreaComment1';
import SmallAreaComment2 from './Small/SmallAreaComment2';
import ResultButton from './Small/ResultButton';

import React from 'react';
import AreaTable from './Big/AreaTable';
import MajorFacility from './Big/MajorFacility';
import SalesTable from './Big/SalesTable';

interface AreaViewProps {
  props:{
    smallAreaView:boolean
  }
}

const AreaView:React.FC<AreaViewProps> = ({props}) => {

  return (
    <>
      {props.smallAreaView ? 
        // エリア
        <>
          <Grid item xs={12}>
            <AnalysisData props={{ data1: "10000", data2: "20000" }} />
          </Grid>
          <Grid item xs={12}>
            <SmallAreaComment1 props={{ data1: "50000", data2: "60000" }} />
          </Grid>
          <Grid item xs={12}>
            <SmallAreaComment2 props={{ data1: "50000", data2: "60000" }} />
          </Grid>
          <Grid item xs={12}>
            <ResultButton />
          </Grid>
        </>
        :
        // 行政区
        <>
          {/* <Grid item xs={12}>
            <SalesTable />
          </Grid> */}
          <Grid item xs={12}>
            <AreaTable />
          </Grid>
          <Grid item xs={12}>
            <MajorFacility />
          </Grid>
        </>
      }
    </>
  );
}

export default AreaView;
