import * as React from 'react';

import { InputLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import { areaContext } from '../../data/areaContext';
import { initialAreaType } from '../../data/areaInterface';

interface SmallAreaComment2Props {
  props:{
    data1:string,
    data2:string
  }
}

const SmallAreaComment2:React.FC<SmallAreaComment2Props> = ({props}) => {

  const areaCtx = React.useContext(areaContext);
  const [comment2, setComment2] = React.useState(areaCtx.smallArea.attribution.comment2);

  React.useEffect(() => {
    setComment2("");
    if (areaCtx.smallArea !== initialAreaType) {
      setComment2(areaCtx.smallArea.attribution.comment2);
    }
  }, [areaCtx.smallArea]);

  React.useEffect(() => {
    areaCtx.workingSmallArea.attribution.comment2 = comment2;
    areaCtx.setAreaCommentDisable(!areaCtx.areaCommentDisable)
  }, [comment2, areaCtx.smallArea.attribution.comment2]);

  return (
    <TableContainer component={Paper} sx={{ borderLeft: 1, borderRight: 1, borderBottom: 1,borderTop: 0, minHeight:180,maxHeight:180 }}>
      <InputLabel sx={{fontSize: 10}}>【出店計画】</InputLabel>
      <TextField
          multiline
          rows={5}
          inputProps={{style: {fontSize: "10"}}} 
          sx={{
            display: "flex",
            maxWidth: 360,
            "& .MuiInput-root": {
              padding: 0,
              "& .MuiInput-input": {
                height: "165px !important"
              }
            },
          }}
          variant="standard"
          value={comment2}
          onChange={e => {
            setComment2(e.target.value)
        }}
        />
    </TableContainer>
  );
}

export default SmallAreaComment2;