import * as React from 'react';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

import {areaContext}  from './areaContext'
import {areaSelectProps,AreaType,initialAreaType} from './areaInterface'

const AreaSelect:React.FC<areaSelectProps> = ({props}) => {
  const areaCtx = React.useContext(areaContext);
  const handleChange = (event: any) => {
    const value = JSON.parse(event.target.value);
    if(props.label === "ＤＯ")areaCtx.setDoArea(value as AreaType);
    if(props.label === "ＤＯ")areaCtx.setMapCenter(value.latlng);
    if(props.label === "行政区")areaCtx.setBigArea(value as AreaType);
    if(props.label === "行政区")areaCtx.setMapCenter(value.latlng);
    if(props.label === "エリア")areaCtx.setInitialCheck(false);
    if(props.label === "エリア")areaCtx.setSmallArea(value as AreaType);
    if(props.label === "エリア")areaCtx.setInitialCheck(true);
  };
  const [select, setSelect] = React.useState<string>("")

  React.useEffect(() => {
    setSelect("")
    if (props.label === 'ＤＯ') {
      setSelect(JSON.stringify(areaCtx.doArea))
    } else if(props.label === '行政区') {
      setSelect(JSON.stringify(areaCtx.bigArea))
    } else if(props.label === 'エリア') {
      setSelect(JSON.stringify(areaCtx.smallArea))
    }
  }, [props.label, areaCtx.doArea, areaCtx.bigArea, areaCtx.smallArea])

  return (
    <FormControl fullWidth>
      <InputLabel id="area-select-id" sx={{top:"10px"}} shrink={true}>{props.label}</InputLabel>
      <Select
        sx={{backgroundColor:"#ffffff",'.css-ffg8md-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{paddingBottom:'4px'}}}
        labelId="area-select"
        id="area-select"
        value={select}
        label="{props.label}"
        onChange={handleChange}
        native={true}
      >
        {props.dataList.map((value) => (
          <option 
            value={JSON.stringify(value)} 
            key={value.code}
          >
            {value.name + (props.label === '行政区' ? '（' + value.attribution?.direction_name + '）': '')}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default AreaSelect