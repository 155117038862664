import * as React from "react";

import { getCvsBrandCodeValue } from '../../utility/codeMaster';
import { GeoJsonPolygon, GeoJsonPolygon2googleMapsPolygon } from '../../utility/turf';
import { areaContext } from '../data/areaContext';

interface listInterFace {
  key: number,
  data: any,
  marker: google.maps.Marker|google.maps.Polygon,
  pathOptions: any,
  info: google.maps.InfoWindow,
}

// 既存店
const EXISTINGSEJSTORE = 'existingSejStore'
// 閉店
const CLOSEDSEJSTORE = 'closedSejStore'
// ZONE
const ZONE = 'zone'
// 候補地
const PLANAREA = 'planArea'
// 競合店
const CONFLICTSTORES = 'conflictStores'

export const zIndexOrder = {
  kouhochiPolygon: 100,
  buildingPolygon: 101,
  buildingEntrance: 102,
  // 所有者賃貸人
  ownerLessor: 103,
  // 編集モード時は一律300
  zoneEditPolygon: 300,
  // ピンメモ系
  pinMemoPin: 201,
  pinMemoPolygon: 200,
  pinMemoLine: 202,
  pinMemoArrow: 203,
  pinMemoMarker: 299,
  // ×印は一番上
  pinMemoCrossIcon: 900,
  marketArea: 10,
  // 店舗アイコン
  existingSejStoreIcon: 410,
  conflictStoreIcon: 402,
  closedSejStoreIcon: 401,
  // 店舗バッジ
  badge: 450,
  // 出店戦略
  zoneStrategy: 440,
  background: 50,
  // zone
  zonePolygon: 60,
  // 所有者賃貸人位置変更時
  editOwnerLessor: 1001,
};

/**
 既存店、閉店、候補地の敷地を表示
 * @param planAreaFeature 
 * @returns 
 */
const planAreaFeature = (planAreaFeature: any) => {
  const polyData = JSON.parse(planAreaFeature) as GeoJsonPolygon
  const poly = GeoJsonPolygon2googleMapsPolygon(polyData);
  poly.setOptions({
    clickable: false,
    editable: false,
    fillColor: "#FF0000",
    fillOpacity: 0.1,
    strokeWeight: 1.0,
    strokeColor: "#FF0000",
    visible: true,
    zIndex: zIndexOrder.kouhochiPolygon,
  });

  return poly
}

/**
 * 既存店、閉店、候補地の建物を表示
 * @param buildingFeature 
 * @returns 
 */
const buildingFeature = (buildingFeature: any) => {
  const polyData = JSON.parse(buildingFeature) as GeoJsonPolygon
  const poly = GeoJsonPolygon2googleMapsPolygon(polyData);
  poly.setOptions({
    clickable: false,
    editable: false,
    fillColor: "#000000",
    fillOpacity: 0.1,
    strokeWeight: 1.0,
    strokeColor: "#000000",
    visible: true,
    zIndex: zIndexOrder.buildingPolygon,
  });

  return poly
}

export const RecruitViewData = () => {
  const areaCtx = React.useContext(areaContext);
  const [exSejStoreList, setExSejStoreList] = React.useState<listInterFace[]>();
  const [closedSejStoreList, setClosedSejStoreList] = React.useState<listInterFace[]>();
  const [planAreaList, setPlanAreaList] = React.useState<listInterFace[]>();
  const [zoneList, setZoneList] = React.useState<listInterFace[]>();
  const [conflictStoresList, setConflictStoresList] = React.useState<listInterFace[]>();

  const [infowindow, setInfowindow] = React.useState<any[]>([]);
  
  const infoClear = () => {
    // infoWindow非表示
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        if (!areaCtx.hasExistingSej && info[key]["target"] === EXISTINGSEJSTORE) {
          info[key]["info"].close();
        }
        if (!areaCtx.hasClosedSej && info[key]["target"] === CLOSEDSEJSTORE) {
          info[key]["info"].close();
        }
        if (!areaCtx.hasPlanArea && info[key]["target"] === PLANAREA) {
          info[key]["info"].close();
        }
        if (!areaCtx.hasZone && info[key]["target"] === ZONE) {
          info[key]["info"].close();
        }
        if (!areaCtx.hasConflictStores && info[key]["target"] === CONFLICTSTORES) {
          info[key]["info"].close();
        }
      })
    })
    // infoWindowリストから削除
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        if (!areaCtx.hasExistingSej && info[key]["target"] === EXISTINGSEJSTORE) {
          infowindow.splice(index, 1);
        }
        if (!areaCtx.hasClosedSej && info[key]["target"] === CLOSEDSEJSTORE) {
          infowindow.splice(index, 1);
        }
        if (!areaCtx.hasPlanArea && info[key]["target"] === PLANAREA) {
          infowindow.splice(index, 1);
        }
        if (!areaCtx.hasZone && info[key]["target"] === ZONE) {
          infowindow.splice(index, 1);
        }
        if (!areaCtx.hasConflictStores && info[key]["target"] === CONFLICTSTORES) {
          infowindow.splice(index, 1);
        }
      })
    })
  }
   
  const reset = () => {
    exSejStoreList?.forEach((item) => {
      item.marker.setMap(null);
      item.pathOptions?.forEach((options: any) => {
        var keys = Object.keys(options);
        options[keys[0]].setMap(null)
      })
    })
    closedSejStoreList?.forEach((item) => {
      item.marker.setMap(null);
      item.pathOptions?.forEach((options: any) => {
        var keys = Object.keys(options);
        options[keys[0]].setMap(null)
      })
    })
    planAreaList?.forEach((item) => {
      item.marker.setMap(null);
      item.pathOptions?.forEach((options: any) => {
        var keys = Object.keys(options);
        options[keys[0]].setMap(null)
      })
    })
    zoneList?.forEach((item) => {
      item.marker.setMap(null);
      item.pathOptions?.forEach((options: any) => {
        var keys = Object.keys(options);
        options[keys[0]].setMap(null)
      })
    })
    conflictStoresList?.forEach((item) => {
      item.marker.setMap(null);
      item.pathOptions?.forEach((options: any) => {
        var keys = Object.keys(options);
        options[keys[0]].setMap(null)
      })
    })
    areaCtx.setExistingSejStoreList([]);
    areaCtx.setHasExistingSej(false);
    areaCtx.setClosedSejStoreList([]);
    areaCtx.setHasClosedSej(false);
    areaCtx.setPlanAreaList([]);
    areaCtx.setHasPlanArea(false);
    areaCtx.setZoneList([]);
    areaCtx.setHasZone(false);
    areaCtx.setConflictStoresList([]);
    areaCtx.setHasConflictStores(false);
  }

  React.useEffect(() => {
    infoClear()

    if (areaCtx.mapZoom < 12) {
      reset()
    } else {
      if (!areaCtx.hasExistingSej) {
        exSejStoreList?.forEach((item) => {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        })
        areaCtx.setExistingSejStoreList([]);
      }
  
      if (!areaCtx.hasClosedSej) {
        closedSejStoreList?.forEach((item) => {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        })
        areaCtx.setClosedSejStoreList([]);
      }
  
      if (!areaCtx.hasPlanArea) {
        planAreaList?.forEach((item) => {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        })
        areaCtx.setPlanAreaList([]);
      }
  
      if (!areaCtx.hasZone) {
        zoneList?.forEach((item) => {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        })
        areaCtx.setZoneList([]);
      }
  
      if (!areaCtx.hasConflictStores) {
        conflictStoresList?.forEach((item) => {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        })
        areaCtx.setConflictStoresList([]);
      }
    }

  }, [areaCtx.hasExistingSej, areaCtx.hasClosedSej, areaCtx.hasPlanArea, areaCtx.hasZone, areaCtx.hasConflictStores])

  React.useEffect(() => {
    if (areaCtx.mapZoom < 12) {
      infoClear()
      reset()
    }
  }, [areaCtx.mapZoom])

  // 既存店
  React.useEffect(() => {
    const tmpMarker:any[] = [];
    if (areaCtx.existingSejStoreList && areaCtx.existingSejStoreList.length !== 0) {
      areaCtx.existingSejStoreList.forEach((data, index) => {
        try {
          // marker
          var marker = new google.maps.Marker({
            position:
            {
              lat: data.centerlat,
              lng: data.centerlon,
            },
          });
          marker.setOptions({
            icon: {
              url: process.env.PUBLIC_URL + '/image/sej_normal.png',
              anchor: new google.maps.Point(10, 10),
            },
            zIndex: zIndexOrder.existingSejStoreIcon
          })
          marker.setMap(areaCtx.googleMap!);

          const pathOptions:any[] = [];

          let planAreaFeaturePoly = new google.maps.Polygon({})
          if (data.plan_area_feature) {
            planAreaFeaturePoly = planAreaFeature(data.plan_area_feature)
            planAreaFeaturePoly.setMap(areaCtx.googleMap!)          
          }

          let buildingFeaturePoly = new google.maps.Polygon({})

          if (data.building_feature) {
            buildingFeaturePoly = buildingFeature(data.building_feature)
            buildingFeaturePoly.setMap(areaCtx.googleMap!)  
          }

          pathOptions.push({planAreaFeaturePoly: planAreaFeaturePoly})
          pathOptions.push({buildingFeaturePoly: buildingFeaturePoly})

          tmpMarker.push({
            key: data.store_code,
            data: data,
            marker: marker,
            pathOptions: pathOptions,
            "info": new google.maps.InfoWindow({}),
          })

          marker.addListener("click", function (e: google.maps.MapMouseEvent) {
            onClick(e, EXISTINGSEJSTORE, data);
          });
        } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

      // 前回表示分のリスト取得
      const  diffBefore: any[] = []
      exSejStoreList?.forEach((item) => {
        diffBefore.push(item.data)
      })
      // 前回表示分のリストとの差分取得
      const diff = diffBefore.filter(x => !areaCtx.existingSejStoreList.includes(x))

      setExSejStoreList(tmpMarker);

      // 前回表示分の差分をMAPから表示削除
      if (diff.length > 0) {
        diffClear(diff, exSejStoreList)
      }
    } else {
      exSejStoreList?.forEach((item) => {
        item.marker.setMap(null);
        item.pathOptions?.forEach((options: any) => {
          var keys = Object.keys(options);
          options[keys[0]].setMap(null)
        })
      })
    }
  }, [areaCtx.existingSejStoreList])

  const onClick = (e: google.maps.MapMouseEvent, target: string, data: any) => {
    exSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        const contentStr = setContentStr(target, data)
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        areaCtx.setTargetLatlng({lat: data.centerlat, lng: data.centerlon})
        areaCtx.googleMap?.setCenter({lat: data.centerlat, lng: data.centerlon})
        info.open(areaCtx.googleMap!);
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[data.store_code]: {
          "key" : data.store_code,
          "info" : info,
          "target": target,
        }});

        info.addListener('domready', () => {
          onClickInfo(target, data);
        });
      }
    })
  }

  const onClickInfo = (target: string, data: any) => {
    // zindex調整
    exSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        document.getElementById('infoWindow-' + data.store_code)?.addEventListener('click', () => {
          onInfoWindow(target,data)
        });
      }
    });
  }

  const onInfoWindow = (target: string, data: any) => {
    exSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      exSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "click")
        
        item.marker.addListener("click", (e:google.maps.MapMouseEvent, target: string, data: any) => {onClick(e, EXISTINGSEJSTORE, item.data)});
      })
    }
  }, [exSejStoreList, onClick]);

  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      exSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "domready")
        
        item.marker.addListener("domready", (target: string, data: any) => {onClickInfo(EXISTINGSEJSTORE, item.data)});
      })
    }
  }, [exSejStoreList, onClickInfo]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      exSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.key)
        
        item.info.addListener('infoWindow-' + item.key, (target: string, data: any) => {onInfoWindow(EXISTINGSEJSTORE, item.data)});
      })
    }
  }, [exSejStoreList, onInfoWindow]);
  // 既存店

  // 閉店
  React.useEffect(() => {
    const tmpMarker:any[] = [];
    if (areaCtx.closedSejStoreList && areaCtx.closedSejStoreList.length !== 0) {
      areaCtx.closedSejStoreList.forEach((data, index) => {
        try{
          // marker
          var marker = new google.maps.Marker({
            position:
            {
              lat: data.centerlat,
              lng: data.centerlon,
            }
          });
          marker.setOptions({
            icon: {
              url: process.env.PUBLIC_URL + '/image/sej_closed.png',
              anchor: new google.maps.Point(10, 10),
            },
            zIndex: zIndexOrder.closedSejStoreIcon
          })
          marker.setMap(areaCtx.googleMap!);

          const pathOptions:any[] = [];

          let planAreaFeaturePoly = new google.maps.Polygon({})
          if (data.plan_area_feature) {
            planAreaFeaturePoly = planAreaFeature(data.plan_area_feature)
            planAreaFeaturePoly.setMap(areaCtx.googleMap!)          
          }

          let buildingFeaturePoly = new google.maps.Polygon({})

          if (data.building_feature) {
            buildingFeaturePoly = buildingFeature(data.building_feature)
            buildingFeaturePoly.setMap(areaCtx.googleMap!)  
          }

          pathOptions.push({planAreaFeaturePoly: planAreaFeaturePoly})
          pathOptions.push({buildingFeaturePoly: buildingFeaturePoly})

          tmpMarker.push({
            key: data.store_code,
            data: data,
            marker: marker,
            pathOptions: pathOptions,
            "info": new google.maps.InfoWindow({}),
          })

          marker.addListener("click", function (e: google.maps.MapMouseEvent) {
            onClickClosedSejStore(e, CLOSEDSEJSTORE, data);
          });
        } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

      // 前回表示分のリスト取得
      const  diffBefore: any[] = []
      closedSejStoreList?.forEach((item) => {
        diffBefore.push(item.data)
      })
      // 前回表示分のリストとの差分取得
      const diff = diffBefore.filter(x => !areaCtx.closedSejStoreList.includes(x))

      setClosedSejStoreList(tmpMarker);

      // 前回表示分の差分をMAPから表示削除
      if (diff.length > 0) {
        diffClear(diff, closedSejStoreList)
      }
    } else {
      closedSejStoreList?.forEach((item) => {
        item.marker.setMap(null);
        item.pathOptions?.forEach((options: any) => {
          var keys = Object.keys(options);
          options[keys[0]].setMap(null)
        })
      })
    }
  }, [areaCtx.closedSejStoreList])

  const onClickClosedSejStore = (e: google.maps.MapMouseEvent, target: string, data: any) => {
    closedSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        const contentStr = setContentStr(target, data)
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        areaCtx.setTargetLatlng({lat: data.centerlat, lng: data.centerlon})
        areaCtx.googleMap?.setCenter({lat: data.centerlat, lng: data.centerlon})
        info.open(areaCtx.googleMap!);
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[data.store_code]: {
          "key" : data.store_code,
          "info" : info,
          "target": target,
        }});

        info.addListener('domready', () => {
          onClickInfoClosedSejStore(target, data);
        });
      }
    })
  }

  const onClickInfoClosedSejStore = (target: string, data: any) => {
    // zindex調整
    closedSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        document.getElementById('infoWindow-' + data.store_code)?.addEventListener('click', () => {
          onInfoWindowClosedSejStore(target, data)
        });
      }
    });
  }

  const onInfoWindowClosedSejStore = (target: string, data: any) => {
    closedSejStoreList?.forEach((item) => {
      if (item.key === data.store_code) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      closedSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "click")
        
        item.marker.addListener("click", (e:google.maps.MapMouseEvent, target: string, data: any) => {onClickClosedSejStore(e, CLOSEDSEJSTORE, item.data)});
      })
    }
  }, [closedSejStoreList, onClickClosedSejStore]);

  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      closedSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "domready")
        
        item.marker.addListener("domready", (target: string, data: any) => {onClickInfoClosedSejStore(CLOSEDSEJSTORE, item.data)});
      })
    }
  }, [closedSejStoreList, onClickInfoClosedSejStore]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      closedSejStoreList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.key)
        
        item.info.addListener('infoWindow-' + item.key, (target: string, data: any) => {onInfoWindowClosedSejStore(CLOSEDSEJSTORE, item.data)});
      })
    }
  }, [closedSejStoreList, onInfoWindowClosedSejStore]);
  // 閉店

  // 候補地
  React.useEffect(() => {
    const tmpMarker:any[] = [];
    if (areaCtx.planAreaList && areaCtx.planAreaList.length !== 0) {
      areaCtx.planAreaList.forEach((data, index) => {
        try{
          if (data.step >= 8) {
            return;
          }
          // marker
          var marker = new google.maps.Marker({
            position:
            {
              lat: data.centerlat,
              lng: data.centerlon,
            }
          });
          let icon:string = ""
          let existingFeature:boolean = false
          if (Number(data.step) === 0) {
            // STEP0：黒
            icon = process.env.PUBLIC_URL + '/image/double_circle_black.svg'
          } else if (Number(data.step) === 7) {
            // STEP7：透けてるSEJアイコン
            icon = process.env.PUBLIC_URL + '/image/sej_cleanness.png'
          } else if (data.zone_id) {
            if (!data.zone_is_partial_step) {
              // 同じZONE内にSTEP4以上の候補地が存在しない（is_partial_step=false）
              icon = process.env.PUBLIC_URL + '/image/double_circle_blue.svg'
            } else if (Number(data.step) >= 4) {
              // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
              // STEP4以上：赤
              icon = process.env.PUBLIC_URL + '/image/double_circle.svg'
              existingFeature = true
            } else {
              // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
              // STEP3以下：グレー
              icon = process.env.PUBLIC_URL + '/image/double_circle_gray.svg'
            }
          } else {
            // ZONEに入っていない（zone_idが空）
            // →STEP1～6：青
            icon = process.env.PUBLIC_URL + '/image/double_circle_blue.svg'
          }

          marker.setOptions({
            icon: {
              url: icon,
              anchor: new google.maps.Point(10, 10),
            },
            zIndex: zIndexOrder.kouhochiPolygon,
          })
          marker.setMap(areaCtx.googleMap!);

          const pathOptions:any[] = [];

          // plus_one_existing_feature が空文字、null以外の場合なら候補地の左上に+1アイコンを出す
          let plusOneExistingFeature = new google.maps.Marker({})
          if (data.zone_plus_one_existing_feature && existingFeature) {
            plusOneExistingFeature = new google.maps.Marker({
              position:
              {
                lat: data.centerlat,
                lng: data.centerlon,
              }
            });
            plusOneExistingFeature.setOptions({
              icon: {
                url: process.env.PUBLIC_URL + '/image/attachment_plus.svg',
                anchor: new google.maps.Point(-10, 10),
              },
              zIndex: zIndexOrder.badge,
            })
            plusOneExistingFeature.setMap(areaCtx.googleMap!);
          }

          // scrap_existing_feature が空文字、null以外の場合なら候補地の左上にBアイコンを出す
          let scrapExistingFeature = new google.maps.Marker({})
          if (data.zone_scrap_existing_feature && existingFeature) {
            scrapExistingFeature = new google.maps.Marker({
              position:
              {
                lat: data.centerlat,
                lng: data.centerlon,
              }
            });
            scrapExistingFeature.setOptions({
              icon: {
                url: process.env.PUBLIC_URL + '/image/attachment_build.svg',
                anchor: new google.maps.Point(-10, 10),
              },
              zIndex: zIndexOrder.badge,
            })
            scrapExistingFeature.setMap(areaCtx.googleMap!);
          }

          // zone.pincer_attack_flag===1だったら左下に「挟」を出す（STEP4以上の場合）
          let pincerAttack = new google.maps.Marker({})
          if (data.zone_pincer_attack_flag === '1' && existingFeature) {
            pincerAttack = new google.maps.Marker({
              position:
              {
                lat: data.centerlat,
                lng: data.centerlon,
              }
            });
            pincerAttack.setOptions({
              icon: {
                url: process.env.PUBLIC_URL + '/image/attachment_pincer.svg',
                anchor: new google.maps.Point(10, -10),
              },
              zIndex: zIndexOrder.badge,
            })
            pincerAttack.setMap(areaCtx.googleMap!);
          }

          pathOptions.push({plusOneExistingFeature: plusOneExistingFeature})
          pathOptions.push({scrapExistingFeature: scrapExistingFeature})
          pathOptions.push({pincerAttack: pincerAttack})

          let planAreaFeaturePoly = new google.maps.Polygon({})
          if (data.plan_area_feature) {
            planAreaFeaturePoly = planAreaFeature(data.plan_area_feature)
            planAreaFeaturePoly.setMap(areaCtx.googleMap!)          
          }

          let buildingFeaturePoly = new google.maps.Polygon({})

          if (data.building_feature) {
            buildingFeaturePoly = buildingFeature(data.building_feature)
            buildingFeaturePoly.setMap(areaCtx.googleMap!)  
          }

          pathOptions.push({planAreaFeaturePoly: planAreaFeaturePoly})
          pathOptions.push({buildingFeaturePoly: buildingFeaturePoly})

          tmpMarker.push({
            key: data.uuid,
            data: data,
            marker: marker,
            pathOptions: pathOptions,
            "info": new google.maps.InfoWindow({}),
          })
  
          marker.addListener("click", function (e: google.maps.MapMouseEvent) {
            onClickPlanArea(e, PLANAREA, data);
          });
        } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

      // 前回表示分のリスト取得
      const  diffBefore: any[] = []
      planAreaList?.forEach((item) => {
        diffBefore.push(item.data)
      })
      // 前回表示分のリストとの差分取得
      const diff = diffBefore.filter(x => !areaCtx.planAreaList.includes(x))

      setPlanAreaList(tmpMarker);

      // 前回表示分の差分をMAPから表示削除
      if (diff.length > 0) {
        diffClear(diff, planAreaList)
      }
    } else {
      planAreaList?.forEach((item) => {
        item.marker.setMap(null);
        item.pathOptions?.forEach((options: any) => {
          var keys = Object.keys(options);
          options[keys[0]].setMap(null)
        })
      })
    }
  }, [areaCtx.planAreaList])

  const onClickPlanArea = (e: google.maps.MapMouseEvent, target: string, data: any) => {
    planAreaList?.forEach((item) => {
      if (item.key === data.uuid) {
        const contentStr = setContentStr(target, data)
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        areaCtx.setTargetLatlng({lat: data.centerlat, lng: data.centerlon})
        areaCtx.googleMap?.setCenter({lat: data.centerlat, lng: data.centerlon})
        info.open(areaCtx.googleMap!);
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[data.uuid]: {
          "key" : data.uuid,
          "info" : info,
          "target": target,
        }});

        info.addListener('domready', () => {
          onClickInfoPlanArea(target, data);
        });
      }
    })
  }

  const onClickInfoPlanArea = (target: string, data: any) => {
    // zindex調整
    planAreaList?.forEach((item) => {
      if (item.key === data.uuid) {
        document.getElementById('infoWindow-' + data.uuid)?.addEventListener('click', () => {
          onInfoWindowPlanArea(target, data)
        });
      }
    });
  }

  const onInfoWindowPlanArea = (target: string, data: any) => {
    planAreaList?.forEach((item) => {
      if (item.key === data.uuid) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      planAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "click")
        
        item.marker.addListener("click", (e:google.maps.MapMouseEvent, target: string, data: any) => {onClickPlanArea(e, PLANAREA, item.data)});
      })
    }
  }, [planAreaList, onClickPlanArea]);

  
  React.useEffect(() => {
    if (areaCtx.googleMap) {
      planAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "domready")
        
        item.marker.addListener("domready", (target: string, data: any) => {onClickInfoPlanArea(PLANAREA, item.data)});
      })
    }
  }, [planAreaList, onClickInfoPlanArea]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      planAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.key)
        
        item.info.addListener('infoWindow-' + item.key, (target: string, data: any) => {onInfoWindowPlanArea(PLANAREA, item.data)});
      })
    }
  }, [planAreaList, onInfoWindowPlanArea]);
  // 候補地

  // zone
  React.useEffect(() => {
    const tmpMarker:any[] = [];
    if (areaCtx.zoneList && areaCtx.zoneList.length !== 0) {
      areaCtx.zoneList.forEach((data, index) => {
        try{
          const pathOptions:any[] = [];

          const polyData = JSON.parse(data.shape_feature) as GeoJsonPolygon
          const poly = GeoJsonPolygon2googleMapsPolygon(polyData);
          poly.setOptions(setZonePolyOption(data));
          poly.setMap(areaCtx.googleMap!);

          // 以下ライン、マーカー
          if (data.plus_one_existing_feature) {
            let polyLine = JSON.parse(data.plus_one_existing_feature)
            const plusOneLine = new google.maps.Polyline({
              path: polyLine['coordinates'].map((val: any) => {
                return {lat: val[1], lng: val[0]}
              }),
              strokeColor: "#ff0000",
              clickable:false,
              icons: [
                {
                  icon: 
                    { 
                      path: 'M 0,-1 0,1',
                      strokeOpacity:1,
                      scale: 4
                    },
                  offset: '0',
                  repeat: '20px',
                },
                {
                  icon: 
                    {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                      strokeOpacity:1,
                    },
                  offset: "100%"
                }
              ],
              strokeOpacity:0,
              zIndex: zIndexOrder.zoneStrategy,
            })

            plusOneLine.setMap(areaCtx.googleMap!)
            pathOptions.push({
              plusOneLine: plusOneLine,
            })
          }

          if (data.multi_owner_existing_feature) {
            let polyLine = JSON.parse(data.multi_owner_existing_feature)
            const multiOwnerLine = new google.maps.Polyline({
              path: polyLine['coordinates'].map((val: any) => {
                return {lat: val[1], lng: val[0]}
              }),
              strokeColor: "#4169e1",
              clickable:false,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.CIRCLE },
                  offset: "100%"
                },
                {
                  icon: { path: google.maps.SymbolPath.CIRCLE },
                  offset: "0%"
                },
              ],
              zIndex: zIndexOrder.zoneStrategy,
            })
            multiOwnerLine.setMap(areaCtx.googleMap!)
            pathOptions.push({
              multiOwnerLine: multiOwnerLine,
            })
          }

          if (data.scrap_existing_feature) {
            let polyLine = JSON.parse(data.scrap_existing_feature)
            const scrapLine = new google.maps.Polyline({
              path: polyLine['coordinates'].map((val: any) => {
                return {lat: val[1], lng: val[0]}
              }),
              strokeColor: "#ff0000",
              clickable:false,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: "100%"
                }
              ],
              zIndex: zIndexOrder.zoneStrategy,
            })
            scrapLine.setMap(areaCtx.googleMap!)
            pathOptions.push({
              scrapLine: scrapLine,
            })
          }
          
          if (data.scrap_existing_latlng.length > 2) {
            const scrapSejBadgeMarker = new google.maps.Marker({
              position: JSON.parse(data.scrap_existing_latlng),
              icon: {
                url: process.env.PUBLIC_URL + '/image/attachment_scrap.svg',
                anchor: new google.maps.Point(18, 18),
              },
              zIndex: zIndexOrder.badge,
              clickable: false,
            });
            scrapSejBadgeMarker.setMap(areaCtx.googleMap!)
            pathOptions.push({
              scrapSejBadgeMarker: scrapSejBadgeMarker,
            })
          }

          if (data.pincer_attack_conflict_feature) {
            let polyLine = JSON.parse(data.pincer_attack_conflict_feature)
            const conflictFeatureLine = new google.maps.Polyline({
              path: polyLine['coordinates'].map((val: any) => {
                return {lat: val[1], lng: val[0]}
              }),
              strokeColor: "#ff8c00",
              clickable:false,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: "100%",
                },
              ],
              zIndex: zIndexOrder.zoneStrategy,
            })
            conflictFeatureLine.setMap(areaCtx.googleMap!)
            pathOptions.push({
              conflictFeatureLine: conflictFeatureLine,
            })
          }

          if (data.pincer_attack_conflict_latlng.length > 2) {
            const conflictLatlngMarker = new google.maps.Marker({
              position: JSON.parse(data.pincer_attack_conflict_latlng),
              icon: {
                url: process.env.PUBLIC_URL + '/image/pincer_attack_orage.svg',
                anchor: new google.maps.Point(12.5, 9.5),
              },
              zIndex: zIndexOrder.background,
            });
            conflictLatlngMarker.setMap(areaCtx.googleMap!)
            pathOptions.push({
              conflictLatlngMarker: conflictLatlngMarker,
            })
          }

          if (data.pincer_attack_existing_feature) {
            let polyLine = JSON.parse(data.pincer_attack_existing_feature)
            const existingFeatureLine = new google.maps.Polyline({
              path: polyLine['coordinates'].map((val: any) => {
                return {lat: val[1], lng: val[0]}
              }),
              strokeColor: "#ff8c00",
              clickable:false,
              icons: [
                {
                  icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                  offset: "100%",
                },
              ],
              zIndex: zIndexOrder.zoneStrategy,
            })
            existingFeatureLine.setMap(areaCtx.googleMap!)
            pathOptions.push({
              existingFeatureLine: existingFeatureLine,
            })
          }
          if (data.pincer_attack_existing_latlng.length > 2) {
            const existingLatlngMarker = new google.maps.Marker({
              position: JSON.parse(data.pincer_attack_existing_latlng),
              icon: {
                url: process.env.PUBLIC_URL + '/image/attachment_pincer.svg',
                anchor: new google.maps.Point(18, 0),
              },
              zIndex: zIndexOrder.badge,
              clickable: false,
            });
            existingLatlngMarker.setMap(areaCtx.googleMap!)
            pathOptions.push({
              existingLatlngMarker: existingLatlngMarker,
            })
          }

          tmpMarker.push({
            key: data.uuid,
            data: data,
            marker: poly,
            pathOptions: pathOptions,
            "info": new google.maps.InfoWindow({}),
          })

          poly.addListener("click", function (e: google.maps.MapMouseEvent) {
            onClickZone(e, ZONE, data);
          });
        } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

      // 前回表示分のリスト取得
      const  diffBefore: any[] = []
      zoneList?.forEach((item) => {
        diffBefore.push(item.data)
      })
      // 前回表示分のリストとの差分取得
      const diff = diffBefore.filter(x => !areaCtx.zoneList.includes(x))

      setZoneList(tmpMarker);

      // 前回表示分の差分をMAPから表示削除
      if (diff.length > 0) {
        diffClear(diff, zoneList)
      }
    } else {
      zoneList?.forEach((item) => {
        item.marker.setMap(null);
        item.pathOptions?.forEach((options: any) => {
          var keys = Object.keys(options);
          options[keys[0]].setMap(null)
        })
      })
    }
  }, [areaCtx.zoneList])

  const setZonePolyOption = (data: any) => {
    if (
      data.status === "2" &&
      data.request_flag === "0"
    ) {
      return {
        fillColor: "#cfe1e6",
        strokeColor: "#008db7",
        strokeWeight: 1.0,
        visible: true,
        zIndex: zIndexOrder.zonePolygon,
      };
    } else {
      return {
        fillColor: "#fae8e1",
        strokeColor: "#faa884",
        strokeWeight: 1.0,
        visible: true,
        zIndex: zIndexOrder.zonePolygon,
      };
    }
  }

  const onClickZone = (e: google.maps.MapMouseEvent, target: string, data: any) => {
    zoneList?.forEach((item) => {
      if (item.key === data.uuid) {
        const contentStr = setContentStr(target, data)
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        areaCtx.setTargetLatlng({lat: data.centerlat, lng: data.centerlon})
        areaCtx.googleMap?.setCenter({lat: data.centerlat, lng: data.centerlon})
        info.open(areaCtx.googleMap!);
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[data.uuid]: {
          "key" : data.uuid,
          "info" : info,
          "target": target,
        }});

        info.addListener('domready', () => {
          onClickInfoZone(target, data);
        });
      }
    })
  }
  const onClickInfoZone = (target: string, data: any) => {
    // zindex調整
    zoneList?.forEach((item) => {
      if (item.key === data.uuid) {
        document.getElementById('infoWindow-' + data.uuid)?.addEventListener('click', () => {
          onInfoWindowZone(target, data)
        });
      }
    });
  }

  const onInfoWindowZone = (target: string, data: any) => {
    zoneList?.forEach((item) => {
      if (item.key === data.uuid) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      zoneList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "click")
        
        item.marker.addListener("click", (e:google.maps.MapMouseEvent, target: string, data: any) => {onClickZone(e, ZONE, item.data)});
      })
    }
  }, [zoneList, onClickZone]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      zoneList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "domready")
        
        item.marker.addListener("domready", (target: string, data: any) => {onClickInfoZone(ZONE, item.data)});
      })
    }
  }, [zoneList, onClickInfoZone]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      zoneList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.key)
        
        item.info.addListener('infoWindow-' + item.key, (target: string, data: any) => {onInfoWindowZone(ZONE, item.data)});
      })
    }
  }, [zoneList, onInfoWindowZone]);
  // zone


  /**
   * 競合店アイコンの設定
   * @param bland_id 
   * @param isTobacco 
   * @param isNewShop 
   * @param type 
   * @returns 
   */
  const setInnerConflictIconPath = (bland_id: string, isTobacco: string, isNewShop: string, type: string) => {
    if (isTobacco === "1" && isNewShop === "1") {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco_new.svg";
    } else if (isTobacco === "1" && String(bland_id).length === 4) {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed_tobacco.svg";
    } else if (isTobacco === "1") {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco.svg";
    } else if (isNewShop === "1") {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal_new.svg";
    } else if (String(bland_id).length === 4) {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed.svg";
    } else {
      return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal.svg";
    }
  }

  // 競合店
  React.useEffect(() => {
    const tmpMarker:any[] = [];
    if (areaCtx.conflictStoresList && areaCtx.conflictStoresList.length !== 0) {
      let date = new Date();
      let nowY4M =
        date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0");
      let nowY2M =
        date.getFullYear().toString().substring(2, 4) +
        (date.getMonth() + 1).toString().padStart(2, "0");

      areaCtx.conflictStoresList.forEach((data, index) => {
        try{
          const pathOptions:any[] = [];
          // marker
          var marker = new google.maps.Marker({
            position:
            {
              lat: data.centerlat,  
              lng: data.centerlon,
            }
          });
          
          const icon:string = setInnerConflictIconPath(
            data.bland_id,
            data.handle_category_tobacco,
            data.open_date === nowY4M || data.open_date === nowY2M ? "1" : "0",
            data.type
          )

          var x = 10
          var y = 14
          if (icon.indexOf('conflict_normal.svg')) {
            x = 5
            y = 2
          }
          marker.setOptions({
            icon: {
              url: icon,
              anchor: new google.maps.Point(x, y),
            },
            zIndex: zIndexOrder.conflictStoreIcon,
          })

          tmpMarker.push({
            key: data.uuid,
            data: data,
            marker: marker,
            pathOptions: pathOptions,
            "info": new google.maps.InfoWindow({}),
          })

          marker.setMap(areaCtx.googleMap!);

          marker.addListener("click", function (e: google.maps.MapMouseEvent) {
            onClickConflictStores(e, CONFLICTSTORES, data);
          });
        } catch (error) {
          console.log("------------------------")
          console.log("表示エラー")
          console.log(error)
          console.log(data)
          console.log("------------------------")
        }
      })

      const  diffBefore: any[] = []
      // 前回表示分のリスト取得
      conflictStoresList?.forEach((item) => {
        diffBefore.push(item.data)
      })
      // 前回表示分のリストとの差分取得
      const diff = diffBefore.filter(x => !areaCtx.conflictStoresList.includes(x))

      setConflictStoresList(tmpMarker);

      // 前回表示分の差分をMAPから表示削除
      if (diff.length > 0) {
        diffClear(diff, conflictStoresList)
      }
    } else {
      conflictStoresList?.forEach((item) => {
        item.marker.setMap(null);
        item.pathOptions?.forEach((options: any) => {
          var keys = Object.keys(options);
          options[keys[0]].setMap(null)
        })
      })
    }
  }, [areaCtx.conflictStoresList])

  const onClickConflictStores = (e: google.maps.MapMouseEvent, target: string, data: any) => {
    conflictStoresList?.forEach((item) => {
      if (item.key === data.uuid) {
        const contentStr = setContentStr(target, data)
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        areaCtx.setTargetLatlng({lat: data.centerlat, lng: data.centerlon})
        areaCtx.googleMap?.setCenter({lat: data.centerlat, lng: data.centerlon})
        info.open(areaCtx.googleMap!);
        info.setZIndex(infowindow.length + 1)
        item.info = info
        infowindow.push({[data.uuid]: {
          "key" : data.uuid,
          "info" : info,
          "target": target,
        }});

        info.addListener('domready', () => {
          onClickInfoConflictStores(target, data);
        });
      }
    })
  }
  const onClickInfoConflictStores = (target: string, data: any) => {
    // zindex調整
    conflictStoresList?.forEach((item) => {
      if (item.key === data.uuid) {
        document.getElementById('infoWindow-' + data.uuid)?.addEventListener('click', () => {
          onInfoWindowConflictStores(target, data)
        });
      }
    });
  }

  const onInfoWindowConflictStores = (target: string, data: any) => {
    conflictStoresList?.forEach((item) => {
      if (item.key === data.uuid) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      conflictStoresList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "click")
        
        item.marker.addListener("click", (e:google.maps.MapMouseEvent, target: string, data: any) => {onClickConflictStores(e, CONFLICTSTORES, item.data)});
      })
    }
  }, [conflictStoresList, onClickConflictStores]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      conflictStoresList?.forEach((item) => {
        google.maps.event.clearListeners(item.marker, "domready")
        
        item.marker.addListener("domready", (target: string, data: any) => {onClickInfoConflictStores(CONFLICTSTORES, item.data)});
      })
    }
  }, [conflictStoresList, onClickInfoConflictStores]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      conflictStoresList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.key)
        
        item.info.addListener('infoWindow-' + item.key, (target: string, data: any) => {onInfoWindowConflictStores(CONFLICTSTORES, item.data)});
      })
    }
  }, [conflictStoresList, onInfoWindowConflictStores]);
  // 競合店

  /**
   * 前回表示分のmarker、polygonの差分を非表示にする
   * @param diffList 
   * @param list 
   */
  const diffClear = (diffList: any, list: any) => {
    list?.forEach(function(item: any){
      diffList?.forEach(function(diffItem: any){
        if (item.key === diffItem.uuid) {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        }
        if (item.key === diffItem.store_code) {
          item.marker.setMap(null);
          item.pathOptions?.forEach((options: any) => {
            var keys = Object.keys(options);
            options[keys[0]].setMap(null)
          })
        }
      })
    });
  }

  
  const setZoneType = (data: any) => {
    let zoneType = ''
    if (data.zone_type === '1') {
      zoneType = '純増（通常）'
    } else if (data.zone_type === '2') {
      zoneType = '純増（限定）'
    } else if (data.zone_type === '3') {
      zoneType = 'Ｓ＆Ｂ（通常）'
    } else if (data.zone_type === '4') {
      zoneType = 'Ｓ＆Ｂ（救済）'
    } else if (data.zone_type === '5') {
      zoneType = '一番立地'
    } else if (data.zone_type === '6') {
      zoneType = '敷地拡大による建て替え'
    } else if (data.zone_type === '7') {
      zoneType = 'タイプ変更、オーナー交代、その他'
    }

    return zoneType
  }

  const setIntention = (data: any) => {
    let intention = ''
    if (data.zone_type === '1') {
      intention = '無店エリア出店'
    } else if (data.zone_type === '2') {
      intention = 'S&B+1'
    } else if (data.zone_type === '3') {
      intention = '閉店+1'
    } else if (data.zone_type === '9') {
      intention = '該当なし'
    }

    return intention
  }

  const setDMRM = (data: any) => {
    let dmrm = ''
    if (data.zone_type === '0') {
      dmrm = '未'
    } else if (data.zone_type === '1') {
      dmrm = '可'
    } else if (data.zone_type === '2') {
      dmrm = '否'
    }

    return dmrm
  }

  const dateReplace = (date: string) => {
    let resultDate = '-'
    if (date) {
      if (date.length === 6) {
        resultDate = date.substring(0, 4) + '年' + date.substring(4, 6) + '月'
      } else if (date.length === 4) {
        if (Number(date.substring(2, 4)) <= 12) {
          resultDate = '20' + date.substring(0, 2) + '年' + date.substring(2, 4) + '月'
        }
      }
    }

    return resultDate
  }
    
  const setContentStr = (target: string, data: any) => {
    let html = ''
    let type = ''
    if (target === EXISTINGSEJSTORE || target === CLOSEDSEJSTORE) {
      if (data.contract_type === '1') {
        type = 'T/S'
      } else if (data.contract_type === '3') {
        type = 'A'
      } else if (data.contract_type === '5') {
        type = 'C'
      }
    }
    if (target === EXISTINGSEJSTORE) {
      html = '<div id=infoWindow-' + data.store_code + '>' +
      '<p style="margin: 0"><label>店番号：' + data.store_code + ' タイプ：' + type + '</label></p>' +
      '<p style="margin: 0"><label>店名：' + data.tenpo_name + '</label></p>' +
      '<p style="margin: 0"><label>方向性：' + data.store_direction + '</label></p>' +
      '<p style="margin: 0"><label>ＤＯ名：' + data.doname + '</label></p>' +
      '<p style="margin: 0"><label>ＤＭ名：' + data.dmname + '</label></p>' +
      '<p style="margin: 0"><label>ＯＦＣ名：' + data.ofcname + '</label></p>' +
      '<p style="margin: 0"><label>開店日：' + data.openingdate + '</label></p>' +
      '<p style="margin: 0"><label>煙草：' + data.tobaccolicense + ' 酒：' + data.liquorlicense + '</label></p>' +
      '<p style="margin: 0"><label>Ｐ面積：' + data.parkingarea + '㎡</label></p>' +
      '<p style="margin: 0"><label>売場面積：' + data.salesfloor + '㎡</label></p>' +
      '</div>';
    } else if (target === CLOSEDSEJSTORE) {
      html = '<div id=infoWindow-' + data.store_code + '>' +
      '<p style="margin: 0"><label>店番号：' + data.store_code + ' タイプ：' + type + '</label></p>' +
      '<p style="margin: 0"><label>店名：' + data.tenpo_name + '</label></p>' +
      '<p style="margin: 0"><label>開店日：' + data.openingdate + '</label></p>' +
      '<p style="margin: 0"><label>閉店日：' + data.closingdate + '</label></p>' +
      '<p style="margin: 0"><label>煙草：' + data.tobaccolicense + ' 酒：' + data.liquorlicense + '</label></p>' +
      '<p style="margin: 0"><label>Ｐ面積：' + data.parkingarea + '㎡</label></p>' +
      '<p style="margin: 0"><label>売場面積：' + data.salesfloor + '㎡</label></p>' +
      '</div>';
    } else if (target === ZONE) {
      const status = data.status === 2 ? '承認' : '未承認'
      html = '<div id=infoWindow-' + data.uuid + '>' +
      '<p style="margin: 0"><label>ＺＯＮＥＮＯ：' + data.id + '</label></p>' +
      '<p style="margin: 0"><label>ＺＯＮＥ名：' + data.zone_name + '</label></p>' +
      '<p style="margin: 0"><label>ＺＯＮＥ種別：' + setZoneType(data) + '</label></p>' +
      '<p style="margin: 0"><label>出店意図：' + setIntention(data) + '</label></p>' +
      '<p style="margin: 0"><label>承認ステータス：' + status + '</label></p>' +
      '<p style="margin: 0"><label>ＤＭ確認：' + setDMRM(data) + '</label></p>' +
      '<p style="margin: 0"><label>ＲＭ確認：' + setDMRM(data) + '</label></p>' +
      '<p style="margin: 0"><label>近くの対象既存店：' + data.multi_owner_existing_store_name + '</label></p>' +
      '<p style="margin: 0"><label>プラスワン対象既存店：' + data.plus_one_existing_store_name + '</label></p>' +
      '<p style="margin: 0"><label>挟撃対象競合店：' + data.pincer_attack_conflict_store_name + '</label></p>' +
      '<p style="margin: 0"><label>挟撃既存店：' + data.pincer_attack_existing_store_name + '</label></p>' +
      '</div>';
    } else if (target === PLANAREA) {
      html = '<div id=infoWindow-' + data.uuid + '>' +
      '<p style="margin: 0"><label>候補地名：' + data.plan_area_name + '</label></p>' +
      '<p style="margin: 0"><label>ＳＴＥＰ：' + data.step + '</label></p>' +
      '</div>';
    } else if (target === CONFLICTSTORES) {
      const openDate = dateReplace(data.open_date)
      const closeDate = dateReplace(data.close_date)
      const hasTabaco = data.handle_category_tobacco === '1' ? '有' : '無';
      const hasLiquor = data.handle_category_liquor === '1' ? '有' : '無';
      html = '<div id=infoWindow-' + data.uuid + '>' +
      '<p style="margin: 0"><label>チェーン名：' + getCvsBrandCodeValue(Number(data.bland_id)).blandJapaneseName + '</label></p>' +
      '<p style="margin: 0"><label>店名：' + data.tenpo_name + '</label></p>' +
      '<p style="margin: 0"><label>コメント：' + data.comment + '</label></p>' +
      '<p style="margin: 0"><label>開店日：' + openDate + '</label></p>' +
      '<p style="margin: 0"><label>閉店日：' + closeDate + '</label></p>' +
      '<p style="margin: 0"><label>煙草：' + hasTabaco + ' 酒：' + hasLiquor + '</label></p>' +
      '</div>';
    }

    return html;
  }

  return (<></>);
}