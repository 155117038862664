import * as React from 'react';

import { InputLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import { areaContext } from '../../data/areaContext';
import { initialAreaType } from '../../data/areaInterface';

interface SmallAreaComment1Props {
  props:{
    data1:string,
    data2:string
  }
}

const SmallAreaComment1:React.FC<SmallAreaComment1Props> = ({props}) => {

  const areaCtx = React.useContext(areaContext);
  const [comment, setComment] = React.useState<string>(areaCtx.smallArea.attribution.comment);

  React.useEffect(() => {
    setComment("");
    if (areaCtx.smallArea !== initialAreaType) {
      setComment(areaCtx.smallArea.attribution.comment);
    }
  }, [areaCtx.smallArea]);

  React.useEffect(() => {
    areaCtx.workingSmallArea.attribution.comment = comment;
    areaCtx.setAreaCommentDisable(!areaCtx.areaCommentDisable)
  }, [comment, areaCtx.smallArea.attribution.comment]);


  return (
    <TableContainer component={Paper} sx={{ borderLeft: 1, borderRight: 1, borderBottom: 0,borderTop: 1, minHeight:180,maxHeight:180 }}>
      <InputLabel sx={{fontSize: 10}}>【エリア特徴／新規活性化方向性】</InputLabel>
      <TextField
          multiline
          rows={5}
          inputProps={{style: {fontSize: "10"}}} 
          sx={{
            display: "flex",
            maxWidth: 360,
            "& .MuiInput-root": {
              padding: 0,
              "& .MuiInput-input": {
                height: "165px !important"
              }
            },
          }}
          variant="standard"
          value={comment}
          onChange={e => {
            setComment(e.target.value)
        }}
        />
    </TableContainer>
  );
}

export default SmallAreaComment1;