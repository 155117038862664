import * as React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { areaContext } from '../data/areaContext';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import { apiRequest } from "../api/apiCall";
import {getSmallAreaGraphQl} from '../data/areaDataManage'

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  fontSize: 1,
}));

const TableCellCount = styled(MuiTableCell)(({ theme }) => ({
  padding: theme.spacing(0),
  fontSize: 1,
}));

const TableCellInput = styled(Input)(({ theme }) => ({
  minWidth: '25px',
  maxWidth: '35px',
  fontSize: 1,
  '.css-1x51dt5-MuiInputBase-input-MuiInput-input':{textAlign:'center'},
}));

/**
 * シェア率計算
 * @param total
 * @param target
 * @returns 
 */
function share(total: number , target: number ) {
  if (target === 0 || target === 0) {
    return 0;
  }
  return Math.round((target / total) * 1000) / 10;
}

/**
 * 人口/店計算
 * @param totalPopulation
 * @param cvsTotal
 * @param target
 * @returns 
 */
function population(totalPopulation: number , target: number ) {
  if (totalPopulation === 0 || target === 0) {
    return 0;
  }
  // 人口／店舗数
  return Math.round(totalPopulation / target);
}

const defaultFeatureCvs = {
  sej: "",
  familymart: "",
  lawson: "",
  ministop: "",
  dailyYamazaki: "",
  others: "",
}

const defaultFeatureCvsTotal = {
  total: 0,
  sej: 0,
  familymart: 0,
  lawson: 0,
  ministop: 0,
  dailyYamazaki: 0,
  others: 0,
}

export default function CVSTable() {
  const areaCtx = React.useContext(areaContext);
  // 人口総数（現在）
  let totalPopulation = 0;
  // cvsデータ（現在）
  let cvsTotal = 0;
  let sej = 0;
  let familymart = 0;
  let lawson = 0;
  let ministop = 0;
  let dailyYamazaki = 0;
  let others = 0;
  // cvsシェアデータ（現在）
  let cvsTotalShare = 0;
  let sejShare = 0;
  let familymartShare = 0;
  let lawsonShare = 0;
  let ministopShare = 0;
  let dailyYamazakiShare = 0;
  let othersShare = 0;
  // 人口/店
  let populationRatioTotal = 0;
  let sejPopulation = 0;
  let familymartPopulation = 0;
  let lawsonPopulation = 0;
  let ministopPopulation = 0;
  let dailyYamazakiPopulation = 0;
  let othersPopulation = 0;
  
  const [featureCvsTotal, setFeatureCvsTotal] = React.useState(0);
  const [featureCvs, setFeatureCvs] = React.useState(defaultFeatureCvs);
  const [featureCvsShare, setFeatureCvsShare] = React.useState(defaultFeatureCvsTotal);
  const [featureCvsPopulation, setFeatureCvsPopulation] = React.useState(defaultFeatureCvsTotal);
  const handleFeatureCvs = (e: any) => {
    if(isNaN(e.target.value)){
      return;
    }
    setFeatureCvs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  React.useEffect(() => {
    setFeatureCvsTotal(
      Number(featureCvs.sej) + 
      Number(featureCvs.familymart) + 
      Number(featureCvs.lawson) +
      Number(featureCvs.ministop) +
      Number(featureCvs.dailyYamazaki) +
      Number(featureCvs.others)
    );

    let targetCvsDataUserInput = defaultFeatureCvs
    if (areaCtx.initialCheck) {
      if(Object.keys(areaCtx.smallArea.attribution).length) {
        if (Object.keys(areaCtx.smallArea.attribution.cvs_data_user_input).length > 2) {
          targetCvsDataUserInput = JSON.parse(areaCtx.smallArea.attribution.cvs_data_user_input);
        }
        areaCtx.workingSmallArea.attribution.cvs_data_user_input = JSON.stringify(featureCvs);
        areaCtx.setAreaCommentDisable(!areaCtx.areaCommentDisable)
      }
    }
  }, [featureCvs, areaCtx.initialCheck]);

  React.useEffect(() => {
    let featureSej = 0;
    let featureFamilymart = 0;
    let featureLawson = 0;
    let featureMinistop = 0;
    let featureDailyYamazaki = 0;
    let featureOthers = 0;
    if (areaCtx.initialCheck) {
      if(Object.keys(areaCtx.smallArea.attribution).length) {
        if (Object.keys(areaCtx.smallArea.attribution.cvs_data_user_input).length) {
          const cvs_data_user_input = JSON.parse(areaCtx.smallArea.attribution.cvs_data_user_input);
          featureSej = cvs_data_user_input["sej"] !== undefined ? Number(cvs_data_user_input.sej) : 0;
          featureFamilymart = cvs_data_user_input["familymart"] !== undefined ? Number(cvs_data_user_input.familymart) : 0;
          featureLawson = cvs_data_user_input["lawson"] !== undefined ? Number(cvs_data_user_input.lawson) : 0;
          featureMinistop = cvs_data_user_input["ministop"] !== undefined ? Number(cvs_data_user_input.ministop) : 0;
          featureDailyYamazaki = cvs_data_user_input["dailyYamazaki"] !== undefined ? Number(cvs_data_user_input.dailyYamazaki) : 0;
          featureOthers = cvs_data_user_input["others"] !== undefined ? Number(cvs_data_user_input.others) : 0;
        }
      }
    } else {
      areaCtx.smallAreaList.forEach((area) => {
        if(Object.keys(area.attribution).length) {
          if (Object.keys(area.attribution.cvs_data_user_input).length) {
            const cvs_data_user_input = JSON.parse(area.attribution.cvs_data_user_input);
            featureSej += cvs_data_user_input["sej"] !== undefined ? Number(cvs_data_user_input.sej) : 0;
            featureFamilymart += cvs_data_user_input["familymart"] !== undefined ? Number(cvs_data_user_input.familymart) : 0;
            featureLawson += cvs_data_user_input["lawson"] !== undefined ? Number(cvs_data_user_input.lawson) : 0;
            featureMinistop += cvs_data_user_input["ministop"] !== undefined ? Number(cvs_data_user_input.ministop) : 0;
            featureDailyYamazaki += cvs_data_user_input["dailyYamazaki"] !== undefined ? Number(cvs_data_user_input.dailyYamazaki) : 0;
            featureOthers += cvs_data_user_input["others"] !== undefined ? Number(cvs_data_user_input.others) : 0;
  
          }
        }
      })
    }
    const sejName = "sej"
    const familymartName = "familymart"
    const lawsonName = "lawson"
    const ministopName = "ministop"
    const dailyYamazakiName = "dailyYamazaki"
    const othersName = "others"
    setFeatureCvs((prev) => ({ ...prev, [sejName]: String(featureSej) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureSej) }));
    setFeatureCvs((prev) => ({ ...prev, [familymartName]: String(featureFamilymart) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureFamilymart) }));
    setFeatureCvs((prev) => ({ ...prev, [lawsonName]: String(featureLawson) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureLawson) }));
    setFeatureCvs((prev) => ({ ...prev, [ministopName]: String(featureMinistop) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureMinistop) }));
    setFeatureCvs((prev) => ({ ...prev, [dailyYamazakiName]: String(featureDailyYamazaki) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureDailyYamazaki) }));
    setFeatureCvs((prev) => ({ ...prev, [othersName]: String(featureOthers) === "0" ? areaCtx.initialCheck ? "" : "0" : String(featureOthers) }));
  }, [areaCtx.initialCheck, areaCtx.doArea, areaCtx.bigArea, areaCtx.smallArea]);

  React.useEffect(() => {
    // シェア率
    setFeatureCvsShare((prev) => ({ ...prev, "total": share(featureCvsTotal, featureCvsTotal) }));
    setFeatureCvsShare((prev) => ({ ...prev, "sej": share(featureCvsTotal, Number(featureCvs.sej)) }));
    setFeatureCvsShare((prev) => ({ ...prev, "familymart": share(featureCvsTotal, Number(featureCvs.familymart)) }));
    setFeatureCvsShare((prev) => ({ ...prev, "lawson": share(featureCvsTotal, Number(featureCvs.lawson)) }));
    setFeatureCvsShare((prev) => ({ ...prev, "ministop": share(featureCvsTotal, Number(featureCvs.ministop)) }));
    setFeatureCvsShare((prev) => ({ ...prev, "dailyYamazaki": share(featureCvsTotal, Number(featureCvs.dailyYamazaki)) }));
    setFeatureCvsShare((prev) => ({ ...prev, "others": share(featureCvsTotal, Number(featureCvs.others)) }));
    // 人口/店
    setFeatureCvsPopulation((prev) => ({ ...prev, "total": population(totalPopulation, Number(featureCvsTotal)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "sej": population(totalPopulation, Number(featureCvs.sej)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "familymart": population(totalPopulation, Number(featureCvs.familymart)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "lawson": population(totalPopulation, Number(featureCvs.lawson)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "ministop": population(totalPopulation, Number(featureCvs.ministop)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "dailyYamazaki": population(totalPopulation, Number(featureCvs.dailyYamazaki)) }));
    setFeatureCvsPopulation((prev) => ({ ...prev, "others": population(totalPopulation, Number(featureCvs.others)) }));
  }, [featureCvsTotal, areaCtx.initialCheck]);
  
  const areaName = areaCtx.initialCheck ? "エリア" : "行政区";
  if (areaCtx.initialCheck) {
    // エリア集計
    // 人口
    const analysisInformation = JSON.parse(areaCtx.smallArea.attribution.analysis_information);
    totalPopulation = Math.round(analysisInformation.totalPopulation);
    // 店舗数集計
    const cvsData = JSON.parse(areaCtx.smallArea.attribution.cvs_data);
    sej = 'sej' in cvsData ? cvsData.sej : 0;
    familymart = 'familymart' in cvsData ? cvsData.familymart : 0;
    lawson = 'lawson' in cvsData ? cvsData.lawson : 0;
    ministop = 'ministop' in cvsData ? cvsData.ministop : 0;
    dailyYamazaki = 'dailyYamazaki' in cvsData ? cvsData.dailyYamazaki : 0;
    others = 'others' in cvsData ? cvsData.others : 0;
    cvsTotal = sej + familymart + lawson + ministop + dailyYamazaki + others;
  } else {
    // 行政区集計
    // 人口
    // 店舗数集計
    areaCtx.smallAreaList.forEach((area) => {
      const cvsData = JSON.parse(area.attribution.cvs_data);
      sej += 'sej' in cvsData ? cvsData.sej : 0;
      familymart += 'familymart' in cvsData ? cvsData.familymart : 0;
      lawson += 'lawson' in cvsData ? cvsData.lawson : 0;
      ministop += 'ministop' in cvsData ? cvsData.ministop : 0;
      dailyYamazaki += 'dailyYamazaki' in cvsData ? cvsData.dailyYamazaki : 0;
      others += 'others' in cvsData ? cvsData.others : 0;
      
      const analysisInformation = JSON.parse(area.attribution.analysis_information);
      totalPopulation += Math.round(analysisInformation.totalPopulation);
    })
    cvsTotal = sej + familymart + lawson + ministop + dailyYamazaki + others;
  }
  // シェア率計算
  sejShare = share(cvsTotal, sej);
  familymartShare = share(cvsTotal, familymart);
  lawsonShare = share(cvsTotal, lawson);
  ministopShare = share(cvsTotal, ministop);
  dailyYamazakiShare = share(cvsTotal, dailyYamazaki);
  othersShare = share(cvsTotal, others);
  cvsTotalShare = share(cvsTotal, (sej + familymart + lawson + ministop + dailyYamazaki + others));
  // 人口/店
  populationRatioTotal = population(totalPopulation, cvsTotal);
  sejPopulation = population(totalPopulation, sej);
  familymartPopulation = population(totalPopulation, familymart);
  lawsonPopulation = population(totalPopulation, lawson);
  ministopPopulation = population(totalPopulation, ministop);
  dailyYamazakiPopulation = population(totalPopulation, dailyYamazaki);
  othersPopulation = population(totalPopulation, others);

  const tableCell1:any = { fontSize: 10 }
  const tableCell2:any = {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: 10,
    "& .MuiInput-input": {
      padding: 0
    },
    "& .MuiInput-root:before": {
      border:"none"
    },
  }
  const tableCell3:any = {
    fontSize: 10,
    "& .MuiInput-input": {
      padding: 0
    }
  }
  const tableCell4:any = {
    backgroundColor: '#b1c5de',
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    fontSize: 10,
    "& .MuiInput-input": {
      padding: 0
    },
    "& .MuiInput-root:before": {
      border:"none"
    },
  }

  return (
    <React.Fragment>
      {/* <Title>Recent Orders</Title> */}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={2} rowSpan={2} sx={tableCell1}>{areaName}</TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}>CVS合計</TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}><img src={`${process.env.PUBLIC_URL}/image/sej_normal.png`} alt="セブンイレブン"></img></TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}><img src={`${process.env.PUBLIC_URL}/image/conflict_familymart_normal.svg`} alt="ファミリーマート"></img></TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}><img src={`${process.env.PUBLIC_URL}/image/conflict_lawson_normal.svg`} alt="ローソン"></img></TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}><img src={`${process.env.PUBLIC_URL}/image/conflict_ministop_normal.svg`} alt="ミニストップ"></img></TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}><img src={`${process.env.PUBLIC_URL}/image/conflict_dailyYamazaki_normal.svg`} alt="デイリーヤマザキ"></img></TableCell>
            <TableCell align="center" colSpan={2} sx={tableCell2}>その他</TableCell>
          </TableRow>
          <TableRow>

            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
            <TableCell align="center" sx={tableCell2}>店舗数</TableCell>
            <TableCell align="center" sx={tableCell2}>シェア率</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell rowSpan={2} align="center" sx={tableCell1}>現在</TableCell>
            <TableCell align="center" sx={tableCell2}>{totalPopulation.toLocaleString()}</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{cvsTotal.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{cvsTotalShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{sej.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{sejShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{familymart.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{familymartShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{lawson.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{lawsonShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{ministop.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{ministopShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{dailyYamazaki.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{dailyYamazakiShare}%</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{others.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{othersShare}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={tableCell2}>人口/店</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{populationRatioTotal.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{sejPopulation.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{familymartPopulation.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{lawsonPopulation.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{ministopPopulation.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{dailyYamazakiPopulation.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{othersPopulation.toLocaleString()}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell rowSpan={2} align="center" sx={tableCell1}>今後</TableCell>
            <TableCell align="center" sx={tableCell2}>{totalPopulation.toLocaleString()}</TableCell>
            <TableCellCount align="center" sx={tableCell2}>{featureCvsTotal.toLocaleString()}</TableCellCount>
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.total}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="sej" value={featureCvs.sej} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.sej}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.sej}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="familymart" value={featureCvs.familymart} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.familymart}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.familymart}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="lawson" value={featureCvs.lawson} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.lawson}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.lawson}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="ministop" value={featureCvs.ministop} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.ministop}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.ministop}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="dailyYamazaki" value={featureCvs.dailyYamazaki} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.dailyYamazaki}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.dailyYamazaki}%</TableCell>
            {
              areaCtx.initialCheck 
              ? <TableCellCount align="center" sx={tableCell4}><TableCellInput sx={tableCell3} name="others" value={featureCvs.others} onChange={handleFeatureCvs}/></TableCellCount>
              : <TableCellCount align="center" sx={tableCell2}>{featureCvs.others}</TableCellCount>
            }
            <TableCell align="center" sx={tableCell2}>{featureCvsShare.others}%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" sx={tableCell2}>人口/店</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.total.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.sej.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.familymart.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.lawson.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.ministop.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.dailyYamazaki.toLocaleString()}</TableCell>
            <TableCell colSpan={2} align="center" sx={tableCell2}>{featureCvsPopulation.others.toLocaleString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
}