import * as React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import Info from '@mui/icons-material/Info';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { getDownloadURL, ref } from 'firebase/storage';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { storage } from '../../utility/firebase';
import { userLogging } from '../../utility/firestore';
import { getManual } from '../../utility/storage';
import { apiRequest } from "../api/apiCall";
import { areaContext } from '../data/areaContext';
import { getBigAreaGraphQl, initMapCenter, setZoomLevel } from '../data/areaDataManage';
import { AreaType, initialAreaType } from '../data/areaInterface';
import BigAreaRegistModal from '../data/BigAreaRegistModal';
import BigAreaUpdateModal from '../data/BigAreaUpdateModal';
import ConfirmDialog from '../data/ConfirmDialog';
import PdfDownLoadModal from '../data/PdfDownLoadModal';
import ResultDialog from '../data/ResultDialog';

interface ListItemProps {
  props:{
    open: boolean
  }
}

const SecondaryListItems:React.FC<ListItemProps> = ({props}) => {
  const areaCtx = React.useContext(areaContext);
  const utilityCtx = useUtilityContext();
  const user = useUserContext();

  const [pdfOpen, setPdfOpen] = React.useState<boolean>(false);
  const [bigOpen, setBigOpen] = React.useState<boolean>(false);
  const [bigUpdateOpen, setBigUpdateOpen] = React.useState<boolean>(false);
  const [mapCenter, setMapCenter] = React.useState<google.maps.LatLng>();
  const [downloadUrl, setDownloadUrl] = React.useState<string>("")

  const [resultDialogOpen, setResultDialogOpen] = React.useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

  const openModal = () => {
    // userLogging
    userLogging('PDFダウンロードモーダル OPEN', {}, user);
    setPdfOpen(true);
  };

  const onStyle = {
    padding: 0,
    margin: 0,
    paddingLeft: '14px',
  };

  const onTextStyle = {
    margin: 0,
    display: props.open === true ? "none": "block",
    '& .MuiTypography-root': {
      textAlign: "center",
      fontSize: "10px",
      marginTop: 0,
    },
    '& > span > p': {
      margin: 0,
    },
  };

  /**
   * PDF保存ボタンdisabledチェック
   * @returns 
   */
  const onCheckPdfCreate = (bigArea: AreaType) => {
    let disabled = false;
    if (areaCtx.bigArea === initialAreaType) {
      disabled = true;
    }

    return disabled;
  }

  /**
   * PDF保存
   * @returns 
   */
  const onStrategyPdfCreate = () => {
    const center = areaCtx.googleMap!.getCenter()
    const jsonUserData = JSON.parse(user.userData);
    const params = {
      mode: "strategypdf",
      endPoint: "/strategy/strategypdf/v1/regist",
      query: {
        big_area_uuid: areaCtx.bigArea.code,
        zoom: Math.ceil(areaCtx.googleMap!.getZoom()!),
        lat: center?.lat(), 
        lng: center?.lng(),
        doc_id: jsonUserData.firebase_id,
        distance: setZoomLevel(areaCtx),
        planArea: areaCtx.hasPlanArea === false ? "0" : "1",
        zone: areaCtx.hasZone === false ? "0" : "1",
        conflictStores: areaCtx.hasConflictStores === false ? "0" : "1",
        closedSejStore: areaCtx.hasClosedSej === false ? "0" : "1",
        existingSejStore: areaCtx.hasExistingSej === false ? "0" : "1",
        type: "",
        mode: areaCtx.initialCheck === true ? "smallarea" : "bigarea",
        small_area_uuid: areaCtx.initialCheck === true ? areaCtx.smallArea.code : "",
        do_area_name: areaCtx.doArea.name,
        hasClosed: "false",
      }
    };

    // userLogging
    userLogging('PDF保存', params, user);

    (async () => {
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      try {
        await apiRequest(params);
        setResultDialogOpen(true)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  const onClickBigArea = () => {
    initMapCenter(areaCtx)
    setMapCenter(new google.maps.LatLng(areaCtx.mapCenter.lat, areaCtx.mapCenter.lng, false))
    // userLogging
    userLogging('行政区登録 OPEN', {}, user);
    setBigOpen(true);
  }

  const onClickUpdateBigArea = () => {
    // userLogging
    userLogging('行政区編集 OPEN', {}, user);
    setBigUpdateOpen(true);
  }

  /**
   * マニュアルダウンロード準備
   */
  const getManualList = () => {
    // userLogging
    userLogging('マニュアルダウンロード準備', {}, user);
    (async () => {
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      try {
        const result = await getManual()
        const storageRef = ref(storage, result[0]["fullPath"]);

        getDownloadURL(storageRef).then(function(url) {
          setDownloadUrl(url)
        })
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e)
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  };

  /**
   * マニュアルダウンロード
   */
   React.useEffect(() => {
    if (downloadUrl !== '') {
      window.opener = null
      window.open(downloadUrl, '_blank')
      setDownloadUrl("")
    }
  }, [downloadUrl])

  const onClose = () => {
    setResultDialogOpen(false)
  }

  const onDeleteBigArea = () => {
    // userLogging
    userLogging('行政区削除 ダイアログOPEN', {}, user);
    setConfirmDialogOpen(true)
  }

  const onDeleteCancel = () => {
    setConfirmDialogOpen(false)
  }

  const onDeleteExecution = () => {
    setConfirmDialogOpen(false)
    if (areaCtx.bigArea) {
      const params = {
        mode: "bigarea",
        endPoint: "/strategy/bigarea/v1/regist",
        query: {
          uuid: areaCtx.bigArea.code,
          centerlat: areaCtx.bigArea.latlng.lat.toString(),
          centerlon: areaCtx.bigArea.latlng.lng.toString(),
          big_area_name: areaCtx.bigArea.name,
          do_code: areaCtx.bigArea.parent_code,
          direction: areaCtx.bigArea.attribution.direction_code,
          mode: 'delete',
        }
      };
      if (utilityCtx.showSpinner) {
        utilityCtx.showSpinner();
      }
      // userLogging
      userLogging('行政区削除 実行', params, user);
      (async () => {
        try {
          await apiRequest(params);
          // 登録後エリア取得
          getBigAreaGraphQl(areaCtx, areaCtx.doArea.code, "", "");
          if (utilityCtx.hideSpinner) {
            utilityCtx.hideSpinner();
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }

  return (
    <React.Fragment>
      <ListItemButton sx={onStyle}>
        <ListItemIcon onClick={onClickBigArea}>
          <LocationCityIcon />
        </ListItemIcon>
        <ListItemText primary="行政区登録" />
      </ListItemButton>
      <ListItemText sx={onTextStyle} onClick={onClickBigArea} primary="行政区登録" />
      <BigAreaRegistModal props={{open:bigOpen,handleModal:setBigOpen,mode:"bigArea",latlng:mapCenter}}/>
      <ListItemButton sx={onStyle} disabled={onCheckPdfCreate(areaCtx.bigArea)}>
        <ListItemIcon onClick={onClickUpdateBigArea}>
          <LocationCityIcon />
        </ListItemIcon>
        <ListItemText primary="行政区編集" />
      </ListItemButton>
      <ListItemText sx={onTextStyle} primary="行政区編集" />
      <BigAreaUpdateModal props={{open:bigUpdateOpen,handleModal:setBigUpdateOpen,mode:"majorFacility",targetBigArea:areaCtx.bigArea}}/>
      {/* <Divider sx={{ my: 1 }} /> */}
      <ListItemButton sx={onStyle} disabled={onCheckPdfCreate(areaCtx.bigArea)}>
        <ListItemIcon onClick={onDeleteBigArea}>
          <LocationCityIcon />
        </ListItemIcon>
        <ListItemText primary="行政区削除" />
      </ListItemButton>
      <ListItemText sx={onTextStyle} primary="行政区削除" />
      <ConfirmDialog props={{
        open:confirmDialogOpen,
        handleModal:setConfirmDialogOpen,
        onCancel:onDeleteCancel,
        onExecution:onDeleteExecution,
        mode:"deleteConfirm",
        body:areaCtx.bigArea.name + "を削除してよろしいですか。",
        confirmCancel:"キャンセル",
        confirmOk:"削除"}} />
      <ListItemButton sx={onStyle} disabled={onCheckPdfCreate(areaCtx.bigArea)} >
      {/* <ListItemButton sx={onStyle} disabled={true} > */}
        <ListItemIcon onClick={(event) => onStrategyPdfCreate()}>
          <PictureAsPdfIcon />
        </ListItemIcon>
        <ListItemText primary="保存" onClick={onStrategyPdfCreate}/>
      </ListItemButton>
      <ListItemText sx={onTextStyle} primary="保存" />
      <ResultDialog props={{
        open:resultDialogOpen,
        handleModal:setResultDialogOpen,
        onClose:onClose,
        body:"PDF保存中です。\n1分ほどお待ちください。",
        resultButton:"閉じる"}} />
      <ListItemButton sx={onStyle} disabled={onCheckPdfCreate(areaCtx.bigArea)}>
        <ListItemIcon onClick={(event) => openModal()}>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary="ﾀﾞｳﾝﾛｰﾄﾞ" onClick={openModal} />
        <PdfDownLoadModal props={{open:pdfOpen,handleModal:setPdfOpen,mode:"pdfdownload"}}/>
      </ListItemButton>
      <ListItemText sx={onTextStyle} primary="ﾀﾞｳﾝﾛｰﾄﾞ" />
      {/* <Divider sx={{ my: 1 }} /> */}
      <ListItemButton sx={onStyle} disabled={false}>
        <ListItemIcon onClick={(event) => getManualList()}>
          <Info />
        </ListItemIcon>
        <ListItemText primary="マニュアル" />
      </ListItemButton>
      {/* <ListItemText sx={onTextStyle} primary="マニュアル" onClick={getManualList} /> */}
      <ListItemText sx={onTextStyle} primary="マニュアル" />
    </React.Fragment>
  );
}

export default SecondaryListItems;
