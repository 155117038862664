import * as React from 'react';

import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { graphqlRequest } from "../api/apiCall";
import { areaContext } from './areaContext';
import { AreaType, initialAreaType, makeAreaTypeData } from './areaInterface';

// 方向性リスト
// 行政区・エリア共通
export const directionList:{[key: string]: string} = {
  '1':'純増',
  '2':'新規＋活性化',
  '3':'出店厳格化',
}

export const getDirection = (direction:string) => {
  return directionList[direction]
}

/**
 * 行政区取得GraphQl
 * @returns
 */
 export function getBigAreaGraphQl(areaCtx: any, doCode: any, uuid: any, selectUuid: String) {
  let res: any = [];
  let params: any = {
    mode: "getBigAreaData",
    doCode: doCode,
    uuid: uuid,
  };
  // init
  areaCtx.setBigAreaList(res);
  areaCtx.setBigAreaSelectList(res);
  areaCtx.setBigArea(initialAreaType);

  (async () => {
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.getBigAreaData;
        if (res.length > 0) {
          areaCtx.setBigAreaList(res)
          areaCtx.setBigAreaSelectList(res)
          areaCtx.setBigArea(res[0])
          areaCtx.setTargetLatlng(res[0].latlng)
          areaCtx.setAreaDisable(true);
          areaCtx.setInitialCheck(false);

          if (selectUuid !== "") {
            res.forEach((area: any) => {
              if (area.code === selectUuid) {
                areaCtx.setBigArea(area);
                areaCtx.setTargetLatlng(area.latlng)
              }
            });
          }
        }
      }
      return res;
    } catch (e) {
      console.log(e);
    }
  })();
}

/**
 * エリア取得GraphQl
 * @returns 
 */
 export function getSmallAreaGraphQl(areaCtx: any, bigArea: any, uuid: any, selectUuid: String) {
  let res: any = [];
  let params: any = {
    mode: "getSmallAreaData",
    big_area_uuid: bigArea.code,
    uuid: uuid,
  };
  // init
  areaCtx.setSmallAreaList(res);
  areaCtx.setSmallAreaSelectList(res);
  areaCtx.setSmallArea(initialAreaType);
  areaCtx.setAreaDisable(true);
  areaCtx.setInitialCheck(false);

  (async () => {
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.getSmallAreaData;
        if (res.length > 0) {
          areaCtx.setSmallAreaList(res)
          areaCtx.setSmallAreaSelectList(res)
          areaCtx.setSmallArea(res[0])
          areaCtx.setTargetLatlng(res[0].latlng)
          areaCtx.setAreaDisable(false);
          areaCtx.setInitialCheck(true);

          if (selectUuid !== "") {
            res.forEach((area: any) => {
              if (area.code === selectUuid) {
                areaCtx.setSmallArea(area);
                areaCtx.setTargetLatlng(area.latlng)
              }
            });
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  })();
}

/**
 * 主要施設取得GraphQl
 * @returns 
 */
 export function getMajorFacilityGraphQl(areaCtx: any, bigArea: any, uuid: any) {
  let res: any = [];
  let params: any = {
    mode: "getMajorFacilityData",
    big_area_uuid: bigArea.code
  };
  // init
  areaCtx.setMajorFacilityList(res);
  areaCtx.setMajorFacilitySelectList(res);
  areaCtx.setMajorFacility(initialAreaType);

  (async () => {
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.getMajorFacilityData;
        if (res.length > 0) {
          areaCtx.setMajorFacilityList(res)
          areaCtx.setMajorFacilitySelectList(res)
          areaCtx.setMajorFacility(res[0])
        }
      }
    } catch (e) {
      console.log(e);
    }
  })();
}

/**
 * 
 * MAP移動前後の判定距離をzoomレベルに応じて変更
 * @param areaCtx
 * @returns 
 */
export const setZoomLevel = (areaCtx: any) => {
  let distance = 1;
  if(areaCtx.mapZoom >= 17){
    distance = 1;
  }else if(areaCtx.mapZoom >= 16){
    distance = 2;
  }else if(areaCtx.mapZoom >= 15){
    distance = 3;
  }else if(areaCtx.mapZoom >= 14){
    distance = 5;
  }else if(areaCtx.mapZoom >= 13){
    distance = 10;
  }else if(areaCtx.mapZoom >= 12){
    distance = 21;
  }

  return distance;
}

/**
 * mapCenterが初期値の場合googlemapのcenterを設定
 * @param areaCtx 
 */
 export const initMapCenter = (areaCtx: any) => {
  if (areaCtx.mapCenter.lat === 0 && areaCtx.mapCenter.lng === 0) {
    const center = areaCtx.googleMap!.getCenter()
    if (center) {
      areaCtx.setMapCenter({lat: center.lat(), lng: center.lng()})
    }
  }
}

/**
 * 既存店取得GraphQl
 * @returns 
 * 
 */
 export function existingSejStoreSearchByDistance(areaCtx: any, utilityCtx: any, user: any) {

  initMapCenter(areaCtx)
  
  let res: any = [];
  let params: any = {
    mode: "existingSejStoreSearchByDistance",
    longitude: areaCtx.mapCenter.lng,
    latitude: areaCtx.mapCenter.lat, 
    distance: setZoomLevel(areaCtx),
  };

  // userLogging
  userLogging('既存店 ON', params, user);

  (async () => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.existingSejStoreSearchByDistance;
        if (res.length > 0) {
          areaCtx.setExistingSejStoreList(res)
        }
      }
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    } catch (e) {
      console.log(e);
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    }
  })();
}

/**
 * 閉店取得GraphQl
 * @returns 
 */
 export function closedSejStoreSearchByDistance(areaCtx: any, utilityCtx: any, user: any) {
  
  initMapCenter(areaCtx)
  
  let res: any = [];
  let params: any = {
    mode: "closedSejStoreSearchByDistance",
    longitude: areaCtx.mapCenter.lng,
    latitude: areaCtx.mapCenter.lat, 
    distance: setZoomLevel(areaCtx),
  };

  // userLogging
  userLogging('閉店 ON', params, user);

  (async () => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.closedSejStoreSearchByDistance;
        if (res.length > 0) {
          areaCtx.setClosedSejStoreList(res)
        }
      }
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    } catch (e) {
      console.log(e);
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    }
  })();
}

/**
 * 候補地取得GraphQl
 * @returns 
 */
 export function planAreaSearchByDistance(areaCtx: any, utilityCtx: any, user: any) {
  
  initMapCenter(areaCtx)
  
  const jsonUserData = JSON.parse(user.userData);
  let res: any = [];
  let manageCitiesFlg = true
  if (jsonUserData.administrator_flag === "1" || (jsonUserData.manageCities?.includes && jsonUserData.manageCities?.includes("ALL"))) {
    manageCitiesFlg = false;
  }
  let stepCnt = jsonUserData.manage_steps.filter(
    (val: any) => val !== '0' && val !== '8' && val !== '99'
  );
  let managePlansOutsideCities = jsonUserData.manage_plans_outside_cities;
  if (!managePlansOutsideCities.includes("")) {
    managePlansOutsideCities.push("");
  }
  let params: any = {
    mode: "planAreaSearchByDistance",
    longitude: areaCtx.mapCenter.lng,
    latitude: areaCtx.mapCenter.lat, 
    distance: setZoomLevel(areaCtx),
    manageCitiesFlg: manageCitiesFlg,
    manageCities: '"' + jsonUserData.manage_cities + '"',
    manageStep: '"' + stepCnt + '"',
    managePlansOutsideCities: '"' + managePlansOutsideCities + '"',
  };

  // userLogging
  userLogging('候補地 ON', params, user);

  (async () => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.planAreaSearchByDistance;
        if (res.length > 0) {
          areaCtx.setPlanAreaList(res)
        }
      }
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    } catch (e) {
      console.log(e);
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    }
  })();
}

/**
 * zone取得GraphQl
 * @returns 
 */
 export function zoneSearchByDistance(areaCtx: any, utilityCtx: any, user: any) {
  
  initMapCenter(areaCtx)

  const jsonUserData = JSON.parse(user.userData);
  let res: any = [];
  let manageCitiesFlg = true
  if (jsonUserData.administrator_flag === "1" || (jsonUserData.manageCities?.includes && jsonUserData.manageCities?.includes("ALL"))) {
    manageCitiesFlg = false;
  }
  let params: any = {
    mode: "zoneSearchByDistance",
    longitude: areaCtx.mapCenter.lng,
    latitude: areaCtx.mapCenter.lat, 
    distance: setZoomLevel(areaCtx),
    manageCitiesFlg: manageCitiesFlg,
    manageCities: '"' + jsonUserData.manage_cities + '"',
  };

  // userLogging
  userLogging('ZONE ON', params, user);

  (async () => {
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data != null) {
        res = result.data.data.zoneSearchByDistance;
        if (res.length > 0) {
          areaCtx.setZoneList(res)
        }
      }
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    } catch (e) {
      console.log(e);
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    }
  })();
}

/**
 * 競合店取得GraphQl
 * @returns 
 */
 export function getConflictStores(areaCtx: any, utilityCtx: any, user: any) {

  initMapCenter(areaCtx)

  let res: any = [];

  let params: any = {
    mode: "getConflictStores",
    longitude: areaCtx.mapCenter.lng,
    latitude: areaCtx.mapCenter.lat, 
    distance: setZoomLevel(areaCtx),
    type: "",
    hasClosed: false,
  };

  // userLogging
  userLogging('競合店 ON', params, user);

  if (utilityCtx.showSpinner) {
    utilityCtx.showSpinner();
  }
  (async () => {
    try {
      let result: any = await graphqlRequest(params);
      if (result.data.data !== null) {
        res = result.data.data.getConflictStores;
        if (res.length > 0) {
          areaCtx.setConflictStoresList(res)
        }
      }
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    } catch (e) {
      console.log(e);
      if (utilityCtx.hideSpinner) {
        utilityCtx.hideSpinner();
      }
    }
  })();
}

/**
 * リクルートビュー取得
 * @returns 
 */
 export function recruitViewData(areaCtx: any, utilityCtx: any, user: any) {
  (async () => {
    try {
      if (areaCtx.hasExistingSej) {
        await existingSejStoreSearchByDistance(areaCtx, utilityCtx, user)
      }
      if (areaCtx.hasClosedSej) {
        await closedSejStoreSearchByDistance(areaCtx, utilityCtx, user)
      }
      if (areaCtx.hasPlanArea) {
        await planAreaSearchByDistance(areaCtx, utilityCtx, user)
      }
      if (areaCtx.hasZone) {
        await zoneSearchByDistance(areaCtx, utilityCtx, user)
      }
      if (areaCtx.hasConflictStores) {
        await getConflictStores(areaCtx, utilityCtx, user)
      }
    } catch (e) {
      console.log(e);
    }
  })();
}

const AreaDataManage = () => {
  const areaCtx = React.useContext(areaContext);
  const user = useUserContext();
  const utilityCtx = useUtilityContext();

  React.useEffect(() =>{
    // firestoreのユーザーデータからDOリストの登録
    if(user.userData){
      const doDataList:AreaType[] = [];
      const jsonUserData = JSON.parse(user.userData);
      const doList:any[] = jsonUserData.manage_do;
      if(doList.length === 0){
        // 担当DOない場合
        return;
      }
      doList.forEach((val,idx)=>{
        doDataList.push(makeAreaTypeData('',val.code,val.name,val.lat,val.lng,{}))
      })
      areaCtx.setDoList(doDataList)
      areaCtx.setDoArea(doDataList[0])
    }
  },[user.userData])

  React.useEffect(() =>{
    if (areaCtx.doArea !== initialAreaType) {
      areaCtx.setTargetLatlng(areaCtx.doArea.latlng);
      getBigAreaGraphQl(areaCtx, areaCtx.doArea.code, "", "");
    }
  },[areaCtx.doArea])

  React.useEffect(() => {
    if (areaCtx.bigArea !== initialAreaType) {
      areaCtx.setTargetLatlng(areaCtx.bigArea.latlng);
      getMajorFacilityGraphQl(areaCtx, areaCtx.bigArea, "");
      getSmallAreaGraphQl(areaCtx, areaCtx.bigArea, "", "");
    }
  },[areaCtx.bigArea])

  React.useEffect(() => {
    if (areaCtx.smallArea !== initialAreaType) {
      areaCtx.setTargetLatlng(areaCtx.smallArea.latlng);
      areaCtx.setMapCenter(areaCtx.smallArea.latlng);
    } else if (areaCtx.bigArea !== initialAreaType) {
      areaCtx.setMapCenter(areaCtx.bigArea.latlng);
    } else {
      areaCtx.setMapCenter(areaCtx.doArea.latlng);
    }
  },[areaCtx.smallArea])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
    ) {
      recruitViewData(areaCtx, utilityCtx, user)
    }

  },[areaCtx.mapCenter])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
      && areaCtx.hasExistingSej
    ) {
      existingSejStoreSearchByDistance(areaCtx, utilityCtx, user)
    }
  },[areaCtx.hasExistingSej])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
      && areaCtx.hasClosedSej
    ) {
      closedSejStoreSearchByDistance(areaCtx, utilityCtx, user)
    }
  },[areaCtx.hasClosedSej])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
      && areaCtx.hasPlanArea
    ) {
      planAreaSearchByDistance(areaCtx, utilityCtx, user)
    }
  },[areaCtx.hasPlanArea])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
      && areaCtx.hasZone
    ) {
      zoneSearchByDistance(areaCtx, utilityCtx, user)
    }
  },[areaCtx.hasZone])

  React.useEffect(() => {
    if (
      areaCtx.mapZoom >= 12 &&
      (
        areaCtx.bigArea !== initialAreaType ||
        areaCtx.smallArea !== initialAreaType
      )
      && areaCtx.hasConflictStores
    ) {
      getConflictStores(areaCtx, utilityCtx, user)
    }
  },[areaCtx.hasConflictStores])

  React.useEffect(() => {
    // 行政区登録時の処理
    if(areaCtx.addBigArea === initialAreaType){
      // 初期値の場合は何もしない
      return;
    }
    const list = areaCtx.bigAreaList;
    list.push(areaCtx.addBigArea)
    areaCtx.setBigAreaList(list)
    const selectList = areaCtx.bigAreaList.filter(area => area.parent_code === areaCtx.doArea.code)
    areaCtx.setBigAreaSelectList(selectList)
    areaCtx.setBigArea(areaCtx.addBigArea)
    areaCtx.setTargetLatlng(areaCtx.addBigArea.latlng);
    areaCtx.setAreaDisable(true);
    areaCtx.setInitialCheck(false);

  },[areaCtx.addBigArea])

  React.useEffect(() => {
    // エリア登録時の処理
    if(areaCtx.addSmallArea === initialAreaType){
      // 初期値の場合は何もしない
      return;
    }
    const list = areaCtx.smallAreaList;
    list.push(areaCtx.addSmallArea)
    areaCtx.setSmallAreaList(list)
    areaCtx.setSmallArea(areaCtx.addSmallArea)
    areaCtx.setTargetLatlng(areaCtx.addSmallArea.latlng);
    if(areaCtx.bigArea && areaCtx.smallArea ) {
      // 行政区、エリアの2つが設定されている場合
      areaCtx.setAreaDisable(false);
      areaCtx.setInitialCheck(true);
    }
  },[areaCtx.addSmallArea])

  React.useEffect(() => {
    // 主要施設登録の処理
    if(areaCtx.addMajorFacility === initialAreaType){
      // 初期値の場合は何もしない
      return;
    }
    const list = areaCtx.majorFacilityList;
    list.push(areaCtx.addMajorFacility)
    areaCtx.setMajorFacilityList(list)
    // 仮で行政区に紐づけ
    const selectList = areaCtx.majorFacilityList.filter(area => area.parent_code === areaCtx.bigArea.parent_code)
    areaCtx.setMajorFacilitySelectList(selectList)
    areaCtx.setMajorFacility(areaCtx.addMajorFacility)
    areaCtx.setTargetLatlng(areaCtx.addMajorFacility.latlng);

  },[areaCtx.addMajorFacility])

  return (<></>)
}

export default AreaDataManage