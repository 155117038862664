import { latLng2point } from "./turf";

let longtap: boolean,
timer: any,
dragStartLatLng: any,
dragStartPoint: any,
dragEndLatLng: any,
dragEndPoint: any;

/**
 * スワイプ距離を計算する
 * @param pos1 
 * @param pos2 
 * @returns 
 */
const isSwipe = (pos1: any, pos2: any) => {
  return Math.sqrt(
    Math.pow(pos2.x - pos1.x, 2) + Math.pow(pos2.y - pos1.y, 2)
  ) > 5
    ? true
    : false;
};


/**
 * マップタップ時のスタート処理
 * エリア（polygon）タップ時のエンド処理
 * タップ開始からの時間測定
 * @param e 
 * @param m 
 */
export const handleTouchStart = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
  dragStartLatLng = m.getCenter()!.toJSON();
  dragStartPoint = latLng2point(dragStartLatLng, m);
  longtap=false;
  timer = setTimeout(() => {
    if (
      !isSwipe(latLng2point(dragStartLatLng, m), dragStartPoint) 
    ) {
      longtap=true
    }
  }, 700);
}

/**
 * マップタップ時のエンド処理
 * ロングタップ
 * 　└一定距離移動時=>false
 * 　└上記以外=>true
 * ロングタップ=>true
 * @param e 
 * @param m 
 * @returns 
 */
export const handleTouchEnd = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
  clearInterval(timer);
  if (!longtap) {
    dragEndLatLng = m.getCenter()!.toJSON();
    dragEndPoint = latLng2point(dragEndLatLng, m);

    // 一定距離動かしているかを判定
    if (isSwipe(dragEndPoint, latLng2point(dragStartLatLng, m))) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

/**
 * エリア（polygon）タップ時のエンド処理
 * ロングタップ===true コネテキストメニュー表示
 * ロングタップ===false infoWindowの表示
 * @param event 
 * @param m 
 * @returns 
 */
export const handlePolygonTouchEnd = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
  clearInterval(timer);
  if (!longtap) {
    return false;
  } else {
    return true;
  }
}