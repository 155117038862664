import * as React from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Done from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useUserContext } from '../../user-provider';
import { useUtilityContext } from '../../utility-provider';
import { userLogging } from '../../utility/firestore';
import { handlePolygonTouchEnd, handleTouchStart } from "../../utility/tablet";
import {
  GeoJsonPolygon, GeoJsonPolygon2googleMapsPolygon, getPolygonCenter, googleMapsPolygon2GeoJsonPolygon
} from '../../utility/turf';
import { apiRequest } from "../api/apiCall";
import { areaContext } from '../data/areaContext';
import { getSmallAreaGraphQl } from '../data/areaDataManage';
import ConfirmDialog from '../data/ConfirmDialog';
import SmallAreaUpdateModal from '../data/SmallAreaUpdateModal';


export const drawingManagerInitialValue: google.maps.drawing.DrawingManagerOptions = {
  drawingControl:false,
  drawingMode:null
}


interface SmallAreaPolygonProps {
  handleMapActionChange?: any,
  mapAction?: boolean,
  propsOnRightClick: any,
  propsOnMouseDown: any,
}

export const SmallAreaPolygon: React.FC<SmallAreaPolygonProps> = ({
  handleMapActionChange,
  mapAction,
  propsOnRightClick,
  propsOnMouseDown,
}) => {
  const [smallAreaPolygon, setSmallAreaPolygon] = React.useState<google.maps.Polygon[]>();

  const areaCtx = React.useContext(areaContext);
  const user = useUserContext();

  React.useEffect(() => {
    if (!smallAreaPolygon) {
      // setDrawingManager(new google.maps.drawing.DrawingManager({drawingControl:false}));
    }

    // remove marker from map on unmount
    return () => {
      if (smallAreaPolygon) {
        smallAreaPolygon.forEach((polygon) => {
          polygon.setMap(null)
        })
      }
    };
  }, [smallAreaPolygon]);

  function setContentStr(data: any) {
    const html = '<div id=infoWindow-'+data.code+'>' +
    '<p style="margin: 0"><label>エリア名：' + data.name + '</label></p>' +
    '<p style="margin: 0"><label>方向性：' + data.attribution.direction_name + '</label></p>' +
    '<p><Button id="edit-' + data.code + '">形状変更</Button></p>' + 
    '<p><Button id="update-' + data.code + '">編集</Button></p>' + 
    '<p><Button id="delete-' + data.code + '">削除</Button></p>' + 
    '</div>';
    return html;
  }

  function resetInfowindow(infowindow: any) {
    infowindow.forEach((info: any) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        info[key]["info"].close();
      })
    })
  }

  const [infowindow, setInfowindow] = React.useState<any[]>([]);
  const [smallAreaList, setSmallAreaList] = React.useState<any[]>([]);
  const utilityCtx = useUtilityContext();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        info[key]["info"].close();
      })
    })
    infowindow?.forEach((info, index) => {
      var keys = Object.keys(info);
      keys.forEach((key) => {
        infowindow.splice(index, 1);
      })
    })
    propsOnMouseDown();
  }, [mapAction, areaCtx.bigArea]);

  /**
   * エリアポリゴンのカラーオプション設定
   * @param data 
   * @returns 
   */
  const selectPolyOption = (data:any) => {
    let color:string = '#00B0F0'
    if (data.attribution.direction_code === "2") {
      color = '#FFFF00'
    } else if (data.attribution.direction_code === "3") {
      color = '#FF0000'
    }

    // 未選択状態
    let options:any = {
      strokeColor: color,
      strokeOpacity: 0.8,
      strokeWeight: 3.5,
      fillColor: color,
      fillOpacity: 0,
    }
    if (data.code === areaCtx.smallArea.code) {
      // 選択状態
      options = {
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 4,
        fillColor: color,
        fillOpacity: 0.1,
      }
    }
    return options
  };

  React.useEffect(() => {
    // init
    smallAreaPolygon?.forEach((poly) => {
      poly.setMap(null);
    })
    if (smallAreaList) {
      smallAreaList?.forEach((data) => {
        data.labelMarker.setMap(null);
      })
      setSmallAreaList([]);
    }
    if (areaCtx.smallAreaList && areaCtx.smallAreaList.length !== 0) {
      const polygon:google.maps.Polygon[] = [];
      const tmpSmallAreaList:any[] = [];
      areaCtx.smallAreaList.forEach((data, index) => {
        const polyData = JSON.parse(data.attribution.polygon) as GeoJsonPolygon
        const poly = GeoJsonPolygon2googleMapsPolygon(polyData);
        // エリアの色変更
        poly.setOptions(selectPolyOption(data));
        poly.setMap(areaCtx.googleMap!);
        polygon.push(poly);

        // マーカーラベル
        const latlng = getPolygonCenter(polyData);
        var labelMarker = new google.maps.Marker({
          map: areaCtx.googleMap!,
          position: new google.maps.LatLng(latlng.geometry.coordinates[1], latlng.geometry.coordinates[0]),
          icon: {
              url: process.env.PUBLIC_URL + '/image/attachment_pincer.svg',
              scaledSize: new google.maps.Size(0, 0),
              labelOrigin: new google.maps.Point(0, 0)  //ラベルの基点
          },
          label: {
             text: '【' + String(index + 1) + '】', //ラベル文字
             color: '#000000',          //ラベル文字の色
             fontFamily: 'sans-serif',  //フォント 
             fontWeight: 'bold',        //フォントの太さ 
             fontSize: '16px'           //フォントのサイズ 
          }
        });
        labelMarker.setOptions({
          zIndex: 1000,
        });
        labelMarker.setMap(areaCtx.googleMap!);

        tmpSmallAreaList.push({
          'code': data.code,
          'polygon': poly,
          'smallArea': data,
          'labelMarker': labelMarker,
          'editLayer': new google.maps.Data(),
          'info': new google.maps.InfoWindow({}),
          'isEdit': false,
          'visibility': 'hidden',
        })

        poly.addListener("mousedown", function (e: google.maps.MapMouseEvent) {
          onMouseDown(e);
          onTouchStart(e, areaCtx.googleMap!);
        });

        poly.addListener("mouseup", function (e: google.maps.MapMouseEvent) {
          onTouchEnd(e, areaCtx.googleMap!);
        });
        poly.addListener("rightclick", function (e: google.maps.MapMouseEvent) {
          onRightClick(e);
        });
        poly.addListener("click", function (e: google.maps.MapMouseEvent) {
          onClick(e, data);
        })
      })

      setSmallAreaPolygon(polygon);
      setSmallAreaList(tmpSmallAreaList)
    }
  }, [areaCtx.smallAreaList]);

  React.useEffect(() => {
    smallAreaList.forEach((item) => {
      item.polygon.setOptions(selectPolyOption(item.smallArea));
    })
  }, [areaCtx.smallArea])

  const hasEdit = () => {
    let hasEdit:boolean = false;
    if (mapAction) {
      hasEdit = true;
    } else {
      smallAreaList.forEach((item) => {
        if (item.isEdit) {
          hasEdit = true;
        }
      })
    }

    return hasEdit;
  }

  const onMouseDown = (e: google.maps.MapMouseEvent) => {
    propsOnMouseDown(e);
  }

  /**
 * タブレットタッチイベント
 * @param <embed src="" type="" /> 
 * @param m 
 */
    const onTouchStart = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
    if (Object.prototype.toString.call(e.domEvent) === "[object TouchEvent]") {
      handleTouchStart(e, areaCtx.googleMap!);
    }
  }

  /**
   * タブレットタッチイベント
   * @param e 
   * @param m 
   */
  const onTouchEnd = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
    if (Object.prototype.toString.call(e.domEvent) === "[object TouchEvent]") {
      if (hasEdit()) {
        if (utilityCtx.showSnackbar) {
          utilityCtx.showSnackbar('warning','編集中です')
        }
        return
      }
  
      if (handlePolygonTouchEnd(e, areaCtx.googleMap!)) {
        propsOnRightClick(e, areaCtx.googleMap!)
      }
    }
  }
  
  const onRightClick = (e: google.maps.MapMouseEvent) => {
    propsOnRightClick(e, areaCtx.googleMap!);
  }

  const onClick = (e: google.maps.MapMouseEvent, smallArea: any) => {
    areaCtx.setInitialCheck(true)
    if (handlePolygonTouchEnd(e, areaCtx.googleMap!)) {
      return
    }
    if (hasEdit()) {
      if (utilityCtx.showSnackbar) {
        utilityCtx.showSnackbar('warning','編集中です')
      }
      return
    }

    onMouseDown(e);
    const contentStr = setContentStr(smallArea);
    smallAreaList.forEach((item, index) => {
      if (item.code === smallArea.code) {
        areaCtx.setSmallArea(smallArea)
        areaCtx.setTargetLatlng(smallArea.latlng)
        var editLayer = new google.maps.Data({
          map: areaCtx.googleMap!,
          style: {
            visible: false,
          }
        });
        item.editLayer = editLayer
        const editPolyData = JSON.parse(smallArea.attribution.polygon) as GeoJsonPolygon
        const editPoly = GeoJsonPolygon2googleMapsPolygon(editPolyData);
        if (item.info !== '') {
          infowindow.forEach((info, index) => {
            var keys = Object.keys(info);
            keys.forEach((key) => {
              if (key === smallArea.code) {
                infowindow.splice(index, 1);
              }
            })
          })
          item.info.close();
        }
        var info = new google.maps.InfoWindow({
          content: contentStr,
          position: e.latLng!,
          pixelOffset: new google.maps.Size(0, -25)
        });
        info.setZIndex(infowindow.length + 1);
        infowindow.push({[smallArea.code]: {
          "info" : info,
        }});
        item.editPoly = editPoly
        item.info = info
        info.open(editLayer.getMap());
      
        info.addListener('domready', () => {
          onClickInfo(smallArea)
        })
      }
    })
  }

  const onClickInfo = (smallArea: any) => {
    document.getElementById('infoWindow-' + smallArea.code)?.addEventListener('click', () => {
      onInfoWindow(smallArea)
    });
    document.getElementById('edit-' + smallArea.code)?.addEventListener('click', () => {
      // userLogging
      userLogging('エリア形状変更 START', {}, user);
      onEditStart(smallArea)
    })
    document.getElementById('editCancel-' + smallArea.code)?.addEventListener('click', () => {
      onEditCancel(smallArea)
    })
    document.getElementById('editFix-' + smallArea.code)?.addEventListener('click', () => {
      onEditFix(smallArea)
    })
    document.getElementById('update-' + smallArea.code)?.addEventListener('click', () => {
      onUpdate(smallArea)
    })
    document.getElementById('delete-' + smallArea.code)?.addEventListener('click', () => {
      onDelete(smallArea)
    })
  }

  const onInfoWindow = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        const targetZIndex = item.info.getZIndex();
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            const zIndex = target[key]["info"].getZIndex();
            if (zIndex > targetZIndex) {
              target[key]["info"].setZIndex(zIndex - 1);
            }
          })
        });
        item.info.setZIndex(infowindow.length);
      }
    })
  }

  const onEditStart = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        item.isEdit = true;
        item.visibility = "visible";
        handleMapActionChange(true);
        resetInfowindow(infowindow);
        item.editPoly.setEditable(true);
        item.editPoly.setOptions({
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        item.editPoly.setMap(item.editLayer.getMap());
        item.info.close();
      }
    })
  }

  const onEditCancel = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        resetInfowindow(infowindow);
        item.editPoly.setEditable(false);
        item.editPoly.setMap(null);
        item.isEdit = false;
        item.visibility = "hidden";
        handleMapActionChange(false);
      }
    })
  }

  const onEditFix = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        item.editPoly.setEditable(false);
        handleMapActionChange(false);
        item.editPoly.setMap(item.editLayer.getMap());
    
        const endPolygon = googleMapsPolygon2GeoJsonPolygon(item.editPoly);
        const latlng = getPolygonCenter(endPolygon);
    
        resetInfowindow(infowindow);
        item.visibility = "hidden";
        if (utilityCtx.showSpinner) {
          utilityCtx.showSpinner();
        }
        const params = {
          mode: "smallarea",
          endPoint: "/strategy/smallarea/v1/regist",
          query: {
            uuid: smallArea.code,
            centerlat: latlng.geometry.coordinates[1].toString(),
            centerlon: latlng.geometry.coordinates[0].toString(),
            delete_flag: '0',
            big_area_uuid: smallArea.parent_code,
            small_area_name: smallArea.name,
            direction: smallArea.attribution.direction_code,
            polygon: JSON.stringify(endPolygon).toString(),
            comment: smallArea.attribution.comment,
            comment2: smallArea.attribution.comment2,
            analysis_information: "",
            cvs_data: "",
            cvs_data_user_input: !smallArea.attribution.cvs_data_user_input ? "{}" : smallArea.attribution.cvs_data_user_input,
          }
        };
        (async () => {
          try {
            await apiRequest(params);
            item.editPoly.setMap(null);
            item.isEdit = false;
            // 登録後エリア取得
            getSmallAreaGraphQl(areaCtx, areaCtx.bigArea, "", smallArea.code);
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
            }
          } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
              utilityCtx.hideSpinner();
            }
          }
        })();
      }
    })
  }

  const onUpdate = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        resetInfowindow(infowindow);
        areaCtx.setSmallArea(smallArea);
        // userLogging
        userLogging('エリア編集 モーダルOPEN', {}, user);
        setModalOpen(true);
      }
    })
  }

  const onDelete = (smallArea: any) => {
    smallAreaList.forEach((item) => {
      if (item.code === smallArea.code) {
        resetInfowindow(infowindow);
        setConfirmDialogOpen(true)
      }
    })
  }

  const onDeleteCancel = () => {
    setConfirmDialogOpen(false)
  }

  const onDeleteExecution = () => {
    setConfirmDialogOpen(false)
    const params = {
      mode: "smallarea",
      endPoint: "/strategy/smallarea/v1/regist",
      query: {
      uuid: areaCtx.smallArea.code,
      centerlat: areaCtx.smallArea.latlng.lat.toString(),
      centerlon: areaCtx.smallArea.latlng.lng.toString(),
      delete_flag: '1',
      big_area_uuid: areaCtx.smallArea.parent_code,
      small_area_name: areaCtx.smallArea.name,
      direction: areaCtx.smallArea.attribution.direction_code,
      polygon: areaCtx.smallArea.attribution.polygon.toString(),
      comment: areaCtx.smallArea.attribution.comment,
      comment2: areaCtx.smallArea.attribution.comment2,
      analysis_information: areaCtx.smallArea.attribution.analysis_information,
      cvs_data: areaCtx.smallArea.attribution.cvs_data,
      cvs_data_user_input: !areaCtx.smallArea.attribution.cvs_data_user_input 
      ? "{}" : areaCtx.smallArea.attribution.cvs_data_user_input,
      }
    };
    // userLogging
    userLogging('エリア削除 完了', params, user);
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        // item.editPoly.setMap(null);
        // 登録後エリア取得
        getSmallAreaGraphQl(areaCtx, areaCtx.bigArea, "", "");

        smallAreaList.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            if (key === areaCtx.smallArea.code) {
              smallAreaList.splice(index, 1);
            }
          })
        });
        infowindow.forEach((target, index) => {
          var keys = Object.keys(target);
          keys.forEach((key) => {
            if (key === areaCtx.smallArea.code) {
              infowindow.splice(index, 1);
            }
          })
        });
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.polygon, "mousedown")
        
        item.polygon.addListener("mousedown", (e:google.maps.MapMouseEvent) => {onMouseDown(e)});
      })
    }
  }, [smallAreaList, onMouseDown]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.polygon, "mousedown")
        
        item.polygon.addListener('mousedown',(e: google.maps.MapMouseEvent) => onTouchStart(e, areaCtx.googleMap!))
      })
    }
  }, [smallAreaList, onTouchStart]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.polygon, "mouseup")
        
        item.polygon.addListener('mouseup',(e: google.maps.MapMouseEvent) => onTouchEnd(e, areaCtx.googleMap!))
      })
    }
  }, [smallAreaList, onTouchEnd]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.polygon, "rightclick")
        
        item.polygon.addListener("rightclick", (e:google.maps.MapMouseEvent) => {onRightClick(e)});
      })
    }
  }, [smallAreaList, onRightClick]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.polygon, "click")
        
        item.polygon.addListener("click", (e:google.maps.MapMouseEvent, smallArea: any) => {onClick(e, item.smallArea)});
      })
    }
  }, [smallAreaList, onClick]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, "click")
        
        item.polygon.addListener("domready", (smallArea: any) => {onClickInfo(item.smallArea)});
      })
    }
  }, [smallAreaList, onClickInfo]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'infoWindow-' + item.code)

        item.info.addListener("infoWindow-" + item.code, (smallArea: any) => {onInfoWindow(item.smallArea)});
      })
    }
  }, [smallAreaList, onInfoWindow]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'edit-' + item.code)

        item.info.addListener("edit-" + item.code, (smallArea: any) => {onEditStart(item.smallArea)});
      })
    }
  }, [smallAreaList, onEditStart]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'editCancel-' + item.code)

        item.info.addListener("editCancel-" + item.code, (smallArea: any) => {onEditCancel(item.smallArea)});
      })
    }
  }, [smallAreaList, onEditCancel]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'editFix-' + item.code)

        item.info.addListener("editFix-" + item.code, (smallArea: any) => {onEditFix(item.smallArea)});
      })
    }
  }, [smallAreaList, onEditFix]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'update-' + item.code)

        item.info.addListener("update-" + item.code, (smallArea: any) => {onUpdate(item.smallArea)});
      })
    }
  }, [smallAreaList, onUpdate]);

  React.useEffect(() => {
    if (areaCtx.googleMap) {
      smallAreaList?.forEach((item) => {
        google.maps.event.clearListeners(item.info, 'delete-' + item.code)

        item.info.addListener("delete-" + item.code, (smallArea: any) => {onDelete(item.smallArea)});
      })
    }
  }, [smallAreaList, onDelete]);

  return (
    <div>
      {
        smallAreaList.map((row, index) => (
          <Paper sx={{position:'absolute',left:'35%',top:'75px', visibility: row.visibility }} key={index}>
            <Stack spacing={4} direction="row">
              <Button id={"editCancel-" + row.code} variant="contained" startIcon={<ArrowBackIcon />}>キャンセル</Button>
              <Button id={"editFix-" + row.code} variant="contained" startIcon={<Done />} >保存</Button>
            </Stack>
          </Paper>
        ))
      }
      <SmallAreaUpdateModal props={{open:modalOpen,handleModal:setModalOpen,mode:"smallArea",targetSmallArea:areaCtx.smallArea}}/>  
      <ConfirmDialog props={{
        open:confirmDialogOpen,
        handleModal:setConfirmDialogOpen,
        onCancel:onDeleteCancel,
        onExecution:onDeleteExecution,
        mode:"deleteConfirm",
        body:areaCtx.smallArea.name + "を削除してよろしいですか。",
        confirmCancel:"キャンセル",
        confirmOk:"削除"}} />
    </div>
  );
};