import React from 'react';

import { CollectionsOutlined } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import { userLogging } from '../../../utility/firestore';
import { apiRequest } from "../../api/apiCall";
import { areaContext } from '../../data/areaContext';
import AreaDataManage, { getSmallAreaGraphQl } from '../../data/areaDataManage';
import { initialAreaType } from '../../data/areaInterface';

const ResultButton = () => {
  const areaCtx = React.useContext(areaContext);
  const user = useUserContext();

  const [resultDisabled, setResultDisabled] = React.useState(true);
  const utilityCtx = useUtilityContext();

  React.useEffect(() =>{
    setResultDisabled(true)
    if (!areaCtx.initialCheck) {
      return
    }
    var commentDissable = true
    var comment2Dissable = true
    var cvsDataUserInputDissable = true
    if (areaCtx.workingSmallArea.attribution.comment !== undefined) {
      if (areaCtx.workingSmallArea.attribution.comment !== areaCtx.smallArea.attribution.comment) {
        commentDissable = false
      }
    }
    if (areaCtx.workingSmallArea.attribution.comment2 !== undefined) {
      if (areaCtx.workingSmallArea.attribution.comment2 !== areaCtx.smallArea.attribution.comment2) {
        comment2Dissable = false
      }
    }
    if (areaCtx.workingSmallArea.attribution.cvs_data_user_input !== undefined) {
      let targetCvsDataUserInput = JSON.stringify({
        sej: 0,
        familymart: 0,
        lawson: 0,
        ministop: 0,
        dailyYamazaki: 0,
        others: 0,
      });
      if (areaCtx.smallArea.attribution.cvs_data_user_input.length > 2) {
        targetCvsDataUserInput = areaCtx.smallArea.attribution.cvs_data_user_input
      }
      if (areaCtx.workingSmallArea.attribution.cvs_data_user_input !== targetCvsDataUserInput) {
        cvsDataUserInputDissable = false
      }
    }
    if (!commentDissable || !comment2Dissable || !cvsDataUserInputDissable) {
      setResultDisabled(false)
    }
  },[areaCtx.areaCommentDisable])

  const handleSmallAreaRegist = () => {
    areaCtx.setAreaCommentDisable(false)
    areaCtx.setWorkingSmallArea(initialAreaType)
    setResultDisabled(true);
    const params = {
      mode: "smallarea",
      endPoint: "/strategy/smallarea/v1/regist",
      query: {
        uuid: areaCtx.workingSmallArea.code,
        centerlat: areaCtx.workingSmallArea.latlng.lat.toString(),
        centerlon: areaCtx.workingSmallArea.latlng.lng.toString(),
        delete_flag: '0',
        big_area_uuid: areaCtx.workingSmallArea.parent_code,
        small_area_name: areaCtx.workingSmallArea.name,
        direction: areaCtx.workingSmallArea.attribution.direction_code,
        polygon: areaCtx.workingSmallArea.attribution.polygon,
        comment: areaCtx.workingSmallArea.attribution.comment,
        comment2: areaCtx.workingSmallArea.attribution.comment2,
        analysis_information: areaCtx.workingSmallArea.attribution.analysis_information,
        cvs_data: areaCtx.workingSmallArea.attribution.cvs_data,
        cvs_data_user_input: areaCtx.workingSmallArea.attribution.cvs_data_user_input,
      }
    };
    // userLogging
    userLogging('エリア保存ボタン', params, user);
    if (utilityCtx.showSpinner) {
      utilityCtx.showSpinner();
    }
    (async () => {
      try {
        await apiRequest(params);
        // 登録後エリア取得
        getSmallAreaGraphQl(areaCtx, areaCtx.bigArea, "", areaCtx.smallArea.code);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      } catch (e) {
        console.log(e);
        if (utilityCtx.hideSpinner) {
          utilityCtx.hideSpinner();
        }
      }
    })();
  }

  return (
    <Paper
      sx={{
        p: 0,
        display: 'flex',
        flexDirection: 'column',
        position: "relative"
      }}
    >
      <Button
        variant="contained" 
        color="success"
        size="small"
        sx={{ 
          fontSize: "12px",
          width: "100px",
          position: "absolute",
          right: 0,
        }}
        disabled={resultDisabled}
        onClick={handleSmallAreaRegist}
        >保存</Button>
    </Paper>
  );
}

export default ResultButton;