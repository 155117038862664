import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {tokenLogin} from './utility/firebase'
import {useUserContext} from './user-provider'

interface Props  {
  url: string;
}

const Login: React.FC<Props> = ({url}) => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const user = useUserContext();

  React.useEffect(() => {
    tokenLogin(url)
    .then((val) => {
      if(val === 'unprocessed'){
        // 未処理の場合はバックドロップ消さない
        return;
      }
      if(user.setUserData){
        user.setUserData(val as string);
      }
      // 認証に成功したらバックドロップ消す
      handleClose();
    }).catch((e) => {
      // リクルートビューに遷移？
      console.log(e)
    })
  },[])

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10 }}
        open={open}
        invisible={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Login