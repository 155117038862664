/*
 * Copyright 2021 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// [START maps_react_map]
import * as React from "react";

import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import { createCustomEqual } from "fast-equals";
import { areaContext } from '../data/areaContext';
import { setZoomLevel } from '../data/areaDataManage';
import SmallAreaRegistModal from '../data/SmallAreaRegistModal';
import ContextMenu from "./ContextMenu";
import { drawingManagerInitialValue } from "./DrawingManager";
import { MajorFacility } from './MajorFacility';
import { ContextMenuProps } from './mapInterface';
import { PolygonDrawing } from './PolygonDrawing';
import { RecruitViewData } from './RecruitViewData';
import { SmallAreaPolygon } from './SmallAreaPolygon';

import { useUtilityContext } from '../../utility-provider';
import { handleTouchEnd, handleTouchStart } from "../../utility/tablet";
import { latLng2point } from "../../utility/turf";
import { latlngLiteral } from "../data/areaInterface";

const render = (status: Status) => {
  return <h1>{status}</h1>;
};



interface Props {
  targetLatLng: latlngLiteral;
}

const Map: React.VFC<Props> = (props) => {
  // [START maps_react_map_component_app_state]
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [drawingManager, setDrawingManager] = React.useState(drawingManagerInitialValue);
  const [polygonOptions, setPolygonOptions] = React.useState<google.maps.PolygonOptions>({visible:false});
  const [pointContextMenu, setPointContextMenu] = React.useState<ContextMenuProps>({props:{left:'0px',top:'0px',visibility:'hidden'}});
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [zoom, setZoom] = React.useState(14); // initial zoom
  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: 35.685338597694525,
    lng: 139.7334195133164,
  });
  const [drawPolygon, setDrawPolygon] = React.useState<google.maps.Polygon>();
  const utilityCtx = useUtilityContext();
  const areaCtx = React.useContext(areaContext);

  const onClick = (e: google.maps.MapMouseEvent) => {
    e.stop();
    // avoid directly mutating state
    // setClicks([...clicks, e.latLng!]);
    // setPointContextMenu({left:'0px',top:'0px',visibility:'hidden'})
  };

  const onRightClick = (e: google.maps.MapMouseEvent,m: google.maps.Map) => {

    if (Object.prototype.toString.call(e.domEvent) !== "[object TouchEvent]") {
      if (mapAction) {
        if (utilityCtx.showSnackbar) {
          utilityCtx.showSnackbar('warning','編集中です')
        }
        return
      }
    }

    let eventLatlng = e.latLng!
    // avoid directly mutating state
    const domEvent = e.domEvent as MouseEvent;
    let  left = domEvent.clientX;
    let  top = domEvent.clientY;

    if (Object.prototype.toString.call(e.domEvent) === "[object TouchEvent]") {
      const lat = e.latLng!.lat();
      const lng = e.latLng!.lng();
      eventLatlng = new google.maps.LatLng(lat, lng, false);
      const eventPoint = latLng2point(e.latLng!, m);
      left = eventPoint!.x + 55;
      top = eventPoint!.y + 55;

      if (pointContextMenu.props.visibility === 'visible') {
        pointContextMenu.props.visibility = 'hidden';
      }
    } else {
      if (pointContextMenu.props.visibility === 'visible') {
        pointContextMenu.props.visibility = 'hidden';
      }
    }

    const visibility = pointContextMenu.props.visibility === 'hidden' ? 'visible' : 'hidden'
    setPointContextMenu({props:{left: left+ 'px',top: top + 'px',visibility:visibility,latlng:eventLatlng,map:m,setPolygonOptions:setPolygonOptions}})
    if(drawingManager){
      const drawingManagerOptions = {
        drawingControl:!drawingManager.drawingControl,
        drawingMode:null
      }
      setDrawingManager(drawingManagerOptions);
    }else{
      setDrawingManager({drawingControl:true,drawingMode:null});
    }
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
    areaCtx.setMapZoom(m.getZoom()!);
  };

  const onDragend = (m: google.maps.Map) => {
    const line = lineString([[areaCtx.mapCenter.lng, areaCtx.mapCenter.lat], [m.getCenter()!.lng(), m.getCenter()!.lat()]])
    const diffLength = length(line, {units: 'kilometers'});
    const zoomLevel  = setZoomLevel(areaCtx)

    if (diffLength > (zoomLevel / 2)) {
      areaCtx.setMapCenter(m.getCenter()!.toJSON());
      areaCtx.setMapZoom(m.getZoom()!);  
    }
  };

  const onMouseDown = (e: any) => {
    setPointContextMenu({props:{left:'0px',top:'0px',visibility:'hidden'}})
  }

  /**
   * タブレットタッチイベント
   * @param event 
   * @param m 
   */
  const onTouchStart = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
    if (Object.prototype.toString.call(e.domEvent) === "[object TouchEvent]") {
      handleTouchStart(e,m);
    }
  }

  /**
   * タブレットタッチイベント
   * @param event 
   * @param m 
   */
  const onTouchEnd = (e: google.maps.MapMouseEvent, m: google.maps.Map) => {
    if (Object.prototype.toString.call(e.domEvent) === "[object TouchEvent]") {
      if (handleTouchEnd(e, m)) {
        onRightClick(e, m)
      }
    }
  }

  const [mapAction, setMapAction] = React.useState(false);

  const handleMapActionChange = (newValue: boolean) => {
    setMapAction(newValue);
  };
  // [END maps_react_map_component_app_state]

  const form = (
    <div
      style={{
        padding: "1rem",
        flexBasis: "250px",
        height: "100%",
        overflow: "auto",
      }}
    >
      <label htmlFor="zoom"></label>
      <input
        type="number"
        id="zoom"
        name="zoom"
        value={zoom}
        onChange={(event) => setZoom(Number(event.target.value))}
      />
      <br />
      <label htmlFor="lat">Latitude</label>
      <input
        type="number"
        id="lat"
        name="lat"
        value={center.lat}
        onChange={(event) =>
          setCenter({ ...center, lat: Number(event.target.value) })
        }
      />
      <br />
      <label htmlFor="lng">Longitude</label>
      <input
        type="number"
        id="lng"
        name="lng"
        value={center.lng}
        onChange={(event) =>
          setCenter({ ...center, lng: Number(event.target.value) })
        }
      />
      <h3>{clicks.length === 0 ? "Click on map to add markers" : "Clicks"}</h3>
      {clicks.map((latLng, i) => (
        <pre key={i}>{JSON.stringify(latLng.toJSON(), null, 2)}</pre>
      ))}
      <button onClick={() => setClicks([])}>Clear</button>
    </div>
  );

  // [START maps_react_map_component_app_return]
  return (
    <div style={{ display: "flex", height: "100%" }} onContextMenu={(e)=>{e.preventDefault()}}>
      <Wrapper apiKey={"AIzaSyBH0T_KDXD97Dg4ZA0lPi_WRZQ-c09HNnY"} libraries={['drawing','marker']} render={render} version={'quarterly'}>
        <MapBase
          center={center}
          onClick={onClick}
          onRightClick={onRightClick}
          onIdle={onIdle}
          onDragend={onDragend}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          drawPolygon={drawPolygon}
          targetLatLng={props.targetLatLng}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
        >
          {/* {clicks.map((latLng, i) => (
            <Marker key={i} position={latLng} />
          ))} */}
          {/* {drawingManager && <DrawingManager onMarkercomplete={onMarkercomplete} {...drawingManager}/>} */}
          <ContextMenu props={{...pointContextMenu.props}}/>
          {polygonOptions && <PolygonDrawing setDrawPolygon={setDrawPolygon} setPolygonOptions={setPolygonOptions} handleModal={setModalOpen} {...polygonOptions} />}
          <SmallAreaRegistModal props={{open:modalOpen,handleModal:setModalOpen,mode:"smallArea",polygon:drawPolygon}}/>
          <SmallAreaPolygon handleMapActionChange={handleMapActionChange} mapAction={mapAction} propsOnRightClick={onRightClick} propsOnMouseDown={onMouseDown} />
          <MajorFacility handleMapActionChange={handleMapActionChange} mapAction={mapAction}/>
          <RecruitViewData />
        </MapBase>
      </Wrapper>
      {/* Basic form for controlling center and zoom of map. */}
      {/* {form} */}

    </div>
  );
  // [END maps_react_map_component_app_return]
};
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onRightClick?: (e: google.maps.MapMouseEvent,map: google.maps.Map) => void;
  onIdle?: (map: google.maps.Map) => void;
  onDragend?: (map: google.maps.Map) => void;
  onMouseDown?: (e: any) => void;
  onTouchStart?: (event: google.maps.MapMouseEvent, map: google.maps.Map) => void;
  onTouchEnd?: (event: google.maps.MapMouseEvent, map: google.maps.Map) => void;
  targetLatLng: latlngLiteral;
  children?: React.ReactNode;
  drawPolygon?: google.maps.Polygon;
}

const MapBase: React.FC<MapProps> = ({
  onClick,
  onRightClick,
  onIdle,
  onDragend,
  onMouseDown,
  onTouchStart,
  onTouchEnd,
  drawPolygon,
  targetLatLng,
  children,
  style,
  ...options
}) => {
  // [START maps_react_map_component_add_map_hooks]
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const areaCtx = React.useContext(areaContext);

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        mapId:'e42bfbd48d30d1a0',
        disableDefaultUI: false,
        keyboardShortcuts: false,
        disableDoubleClickZoom: false,
        streetViewControl: false,
        zoomControl: true,
        scaleControl: true,
        clickableIcons: false,
        fullscreenControl: true,
        mapTypeControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }));
    }
  }, [ref, map]);
  // [END maps_react_map_component_add_map_hooks]

  React.useEffect(() => {
    if(map && !areaCtx.googleMap){
      areaCtx.setGoogleMap(map);
    }
  }, [map])

  React.useEffect(() => {
    if(map && targetLatLng.lat !== 0){
      // const geocoder = new google.maps.Geocoder();
      // const request = {
      //   address: targetLatLng
      // };
      // geocoder.geocode(request, (results, status) => {
      //   if(status == 'OK' && results){
      //     map.setCenter(results[0].geometry.location);
      //   }
      // });
      map.setCenter(targetLatLng)
    }
  }, [targetLatLng]);

  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]

  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      ["click", "idle", "dragend", "rightclick","markercomplete", "mousedown", "onTouchStart", "onTouchEnd"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onRightClick) {
        map.addListener("rightclick", (e:google.maps.MapMouseEvent) => {onRightClick(e,map)});
      }

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }

      if (onDragend) {
        map.addListener("dragend", () => onDragend(map));
      }

      if (onMouseDown) {
        google.maps.event.addListener(map,'mousedown',(e:any) => onMouseDown(e))
      }

      if (onTouchStart) {
        google.maps.event.addListener(map,'mousedown',(e: google.maps.MapMouseEvent) => onTouchStart(e, map))
      }

      if (onTouchEnd) {
        google.maps.event.addListener(map,'mouseup',(e: google.maps.MapMouseEvent) => onTouchEnd(e, map))
      }

    }
  }, [map, onRightClick, onClick, onIdle, onMouseDown, onDragend, onTouchStart, onTouchEnd]);
  // [END maps_react_map_component_event_hooks]

  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

// [START maps_react_map_marker_component]
const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
// [END maps_react_map_marker_component]

// [START maps_react_map_marker_component]
// const DrawingManager: React.FC<google.maps.drawing.DrawingManagerOptions> = (options) => {
//   const [drawingManager, setDrawingManager] = React.useState<google.maps.drawing.DrawingManager>();
  
//   React.useEffect(() => {
//     if (!drawingManager) {
//       setDrawingManager(new google.maps.drawing.DrawingManager({drawingControl:false}));
//     }

//     // remove marker from map on unmount
//     return () => {
//       if (drawingManager) {
//         drawingManager.setMap(null);
//       }
//     };
//   }, [drawingManager]);

//   React.useEffect(() => {
//     if (drawingManager) {
//       drawingManager.setOptions(options);

//       ["markercomplete"].forEach((eventName) =>
//         google.maps.event.clearListeners(drawingManager, eventName)
//       );
//       drawingManager.addListener("markercomplete",(e:any) => {console.log(e)})
//     }
//   }, [drawingManager, options]);

//   return null;
// };
// [END maps_react_map_marker_component]

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
// [END maps_react_map]

export default Map;