import * as React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { apiRequest } from "./components/api/apiCall";
import { areaContext, useAreaData } from './components/data/areaContext';
import AreaDataManage, { getSmallAreaGraphQl } from './components/data/areaDataManage';
import { areaSelectProps, initialAreaType } from './components/data/areaInterface';
import AreaSelect from './components/data/AreaSelect';
import SecondaryListItems from './components/drawer/SecondaryListItems';
import Map from './components/map/Map';
import { Options } from './components/map/Options';
import AreaView from './components/view/AreaView';
import CVSTable from './components/view/CVSTable';
import { useUtilityContext } from './utility-provider';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
})<AppBarProps>(({ theme }) => ({
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
  }),
);

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  minHeight: "50px !important",

}));

const mdTheme = createTheme();

function StrategyContent() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
    areaCtx.setAreaDisable(!areaCtx.addBigArea);
  };


  const areaCtx = useAreaData();
  const [smallArea, setSmallArea] = React.useState<boolean>(false);
  const [isArea, setISArea] = React.useState<string>("0");
  const utilityCtx = useUtilityContext();

  const handleToggleClick:any = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "0") {
      setSmallArea(false);
      areaCtx.setInitialCheck(false)
    } else {
      setSmallArea(true);
      areaCtx.setInitialCheck(true)
    }
  }

  React.useEffect(() =>{
    if (areaCtx.smallArea.parent_code !== '') {
      setSmallArea(true);
      areaCtx.workingSmallArea.code = areaCtx.smallArea.code
      areaCtx.workingSmallArea.latlng = areaCtx.smallArea.latlng
      areaCtx.workingSmallArea.name = areaCtx.smallArea.name
      areaCtx.workingSmallArea.parent_code = areaCtx.smallArea.parent_code
      areaCtx.workingSmallArea.attribution.polygon = areaCtx.smallArea.attribution.polygon
      areaCtx.workingSmallArea.attribution.comment = areaCtx.smallArea.attribution.comment
      areaCtx.workingSmallArea.attribution.comment2 = areaCtx.smallArea.attribution.comment2
      areaCtx.workingSmallArea.attribution.analysis_information = areaCtx.smallArea.attribution.analysis_information
      areaCtx.workingSmallArea.attribution.cvs_data = areaCtx.smallArea.attribution.cvs_data
      areaCtx.workingSmallArea.attribution.direction_code = areaCtx.smallArea.attribution.direction_code
      areaCtx.workingSmallArea.attribution.direction_name = areaCtx.smallArea.attribution.direction_name
      areaCtx.workingSmallArea.attribution.cvs_data_user_input = areaCtx.smallArea.attribution.cvs_data_user_input
    } else {
      setSmallArea(false);
    }

  },[areaCtx.smallArea])

  React.useEffect(() =>{
    if (areaCtx.initialCheck) {
      setISArea("1");
    } else {
      setISArea("0");
    }

  },[areaCtx.initialCheck])

  return (
    <ThemeProvider theme={mdTheme}>
      <areaContext.Provider value={areaCtx}>
        <Drawer variant="permanent" sx={
          {zIndex: 1250,
            "& .MuiToolbar-root": {
            minHeight: 0,
            },
          }
          } open={open} >
          <MuiToolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              minHeight: 32,
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          </MuiToolbar>
          <Divider sx={{ my: 1 }} />
          <Options props={{open: open}} />
          <Divider sx={{ my: 1 }} />
          <List component="nav" sx={{padding: 0}}>
            <SecondaryListItems props={{open: open}} />
          </List>
        </Drawer>
        <Box sx={{ display: 'flex', marginLeft: "56px"}}>
          <CssBaseline />
          {/* ヘッダー */}
          <AppBar position="absolute">
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, marginLeft: "56px"}}
              >
                街づくり戦略
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center" sx={{flexGrow: 2}}>
                  <AreaDataManage />
                  <AreaSelect props={{label:"ＤＯ",dataList:areaCtx.doList}}/>
                  <AreaSelect props={{label:"行政区",dataList:areaCtx.bigAreaList}}/>
                  <AreaSelect props={{label:"エリア",dataList:areaCtx.smallAreaList}}/>
              </Stack>
              {/* <IconButton color="inherit">
                <Badge badgeContent={4} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
            </Toolbar>
          </AppBar>
          {/* 背景部分 */}
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            {/* toolbar謎 */}
            <Toolbar />
            {/* disableGuttersでコンテナの横幅を抑制 */}
            <Container disableGutters={true} maxWidth='xl' sx={{ mt: 0, mb: 0}}>
              {/* コンポーネント間の隙間調整 */}
              <Grid container spacing={0}>
                <Grid item xs={9} sx={{height:'90vh'}}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '65vh',
                      }}
                    >
                      <Map targetLatLng={areaCtx.targetLatlng}/>
                    </Paper>
                  </Grid>
                  {/* Recent Orders */}
                  <Grid item xs={12}>
                    <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column',height:'22vh' }}>
                      <CVSTable />
                    </Paper>
                  </Grid>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={3} sx={{height:'90vh'}}>
                  <Grid item xs={12}>
                    <Paper
                      sx={{
                        p: 0,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                    <FormControl>
                      <RadioGroup
                          row
                          value={isArea}
                          onChange={handleToggleClick}
                          sx={{
                            '& .MuiTypography-root': {
                              margin: 0,
                              textAlign: 'center',
                              padding: "10px 4px",
                            }
                          }}
                        >
                          <Typography align='center' sx={{ fontSize: "10px" }}>表示：</Typography>
                          <FormControlLabel value="0" disabled={areaCtx.bigArea !== initialAreaType ? false : true} 
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiFormControlLabel-label': {fontSize: 10} }} 
                          control={<Radio />} label="行政区" />
                          <FormControlLabel value="1" disabled={areaCtx.smallArea !== initialAreaType ? false : true}
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 }, '& .MuiFormControlLabel-label': {fontSize: 10} }} 
                          control={<Radio />} label="エリア" />
                      </RadioGroup>
                    </FormControl>
                    </Paper>
                  </Grid>
                  <AreaView props={{ 
                    smallAreaView: smallArea}}/>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
          </Box>
        </Box>
      </areaContext.Provider>
    </ThemeProvider>
  );
}

export default function Strategy() {
  return <StrategyContent />;
}